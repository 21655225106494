@import '/src/variables.scss';

.title {
  color: $whiteColor;
  margin-bottom: 40px;
  font-family: L_Halvar_Mittelschrift-Bold;
}

.executiveHeader {
  padding: 120px 0 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  .info {
    color: $whiteColor;
    display: flex;
    flex-direction: column;
    width: 100%;

    .position {
      font-size: 24px;
      font-family: Loko_Lato-Regular;
      margin-bottom: 20px;
      text-transform: uppercase;

      &Mobile {
        font-family: Loko_Lato-Regular;
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
        text-transform: uppercase;
      }
    }

    .name,
    .surname {
      max-width: 590px;
      font-size: 64px;
      font-family: L_Halvar_Mittelschrift-Bold;
      text-transform: uppercase;
      line-height: 1;
      margin-bottom: 0px;

      &Mobile {
        font-family: L_Halvar_Mittelschrift-Bold;
        font-size: 48px;
        font-weight: 700;
        line-height: 48px;
        letter-spacing: 0em;
        text-align: left;
        text-transform: uppercase;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .surname {
      font-size: 96px;
    }

    .bioWrapper {
      margin-top: auto;
    }
  }

  .imageWrapper {
    max-width: 400px;
    width: 100%;

    img {
      width: 100%;
      border-radius: 10px;
    }
  }
}

.otherExecutives {
  padding: 40px 0 60px;

  .title {
    font-family: L_Halvar_Mittelschrift-Bold;
    font-size: 24px;
  }
}

@media screen and (max-width: 1320px) {
  .executiveHeader,
  .otherExecutives {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media screen and (min-width: 1000px) {
  .bioWrapper {
    width: 80%;
  }
}
