@import '/src/variables.scss';

.widgetWrapper {
  padding: 16px 16px 20px;
  border-radius: 10px;
  background-color: $whiteColor;
  align-items: center;
  vertical-align: middle;
  position: relative;
  width: 100%;
  min-width: fit-content;
  width: 350px;
  min-height: 222px;
  box-shadow: -2px 5px 25px 0px #00256d1a;

  &Today {
    border: none;
    background-color: $mainBlueColor;
    color: $whiteColor;
  }
}

.headerMatch {
  font-family: Loko_Lato-Heavy;
  font-weight: 800;
  color: $blackColor;
  margin-bottom: 7.74px;
  font-size: 19px;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;

  &Today {
    color: $blackColor;
  }
}

.headerDate {
  font-family: Loko_Lato-Regular;
  font-weight: 400;
  text-align: center;
  margin-bottom: 15.49px;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.11px;
  color: $blackColor;
}

.widget {
  &Teams {
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: repeat(3, calc(33.3% - 13.3px));
    grid-template-rows: 1fr;
    gap: 15.49px;
  }

  &Btn {
    height: 30px;
    border-radius: 4px;
    outline: none !important;
    background-color: $mainRedColor;
    border: none;
    color: $whiteColor;
    font-family: Loko_Lato-Bold;
    font-weight: 700;
    font-size: 14px;
    line-height: 16.8px;
    position: relative;
    padding-left: 5%;
    padding-right: 5%;

    &:active,
    &:focus {
      outline: none !important;
    }

    &Wrapper {
      text-align: center;
      vertical-align: middle;
    }

    &Buy,
    &Next {
      color: $whiteColor;
      font-family: Loko_Lato-Heavy;
      font-weight: 800;
      font-size: 11px;
      line-height: 13px;
      margin-left: 10px;
    }

    &Next {
      color: $darkGrey;
    }

    &Watch {
      color: $whiteColor;
      font-family: Loko_Lato-Bold;
      font-weight: 800;
      font-size: 11px;
      line-height: 13px;
      text-align: center;
    }
  }
}

.wrapperBtnLastGame {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.btnLastGame {
  width: 50%;
  left: 0;
  right: 0;
}

.btnProtocol {
  background-color: $articleContentBackground;
  color: $darkGrey;
}

.alignBtnProtocol {
  align-self: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.alignBtnWatch {
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 54.2px;
  height: 54.2px;
}

.alignBtn {
  width: 196px;
  padding-left: 15%;
  padding-right: 15%;
  align-self: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.blue {
  background-color: $mainBlueColor;
}

.detailsCurrent {
  font-family: Loko_Lato-Bold;
  font-size: 11px;
  font-weight: 700;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: center;
  color: $whiteColor;
}

.details {
  font-family: Loko_Lato-Bold;
  font-size: 11px;
  font-weight: 700;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: center;
  color: $blackColor;
}

.disabled {
  pointer-events: none;
  opacity: 0.3;
  cursor: default;
}
