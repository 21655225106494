@import '/src/variables.scss';

.newsWrapper {
  padding-left: 15%;
  padding-right: 15%;
  z-index: 1000;
}

.twoWidgetsWrapper {
  margin-top: 30px;
  // Distance to RZhD Widget
  background-color: $whiteColor;
  border-radius: 10px;
  padding: 20px;
}

@media screen and (max-width: 769px) {
  .twoWidgetsWrapper {
    padding: 0px;
  }
}
