@import '/src/variables.scss';

.Option {
  position: absolute;
  width: 100%;
  z-index: 10000;
  padding: 0 0 8px;
  cursor: pointer;
  position: relative;
  background-color: $whiteColor;
  font-family: Loko_Lato-Regular;
  font-size: 10px;
  font-weight: 400;
  line-height: 1.2;
  color: $blackColor;
  text-transform: uppercase;
  list-style-type: none;
}

.Option:first-child {
  border-top: none;
  border-radius: 0px;
}

.Option:last-child {
  border-bottom: none;
  border-radius: 0px 0px 5px 5px;
  padding-bottom: 0;
}

.Option:hover {
  color: $mainRedColor;
}
