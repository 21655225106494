@import '/src/variables.scss';

.stages {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $whiteColor;
  padding: 40px 40px 0;

  .stage {
    color: $whiteColor;
    font-family: Loko_Lato-Bold;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2.5px solid rgba($whiteColor, 0.5);
  }

  .final {
    min-width: 100px;
    height: 100px;
    border: 2.5px solid $whiteColor;
  }

  .step {
    width: 100%;
    height: 1px;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.3) 50%,
      rgba(255, 255, 255, 0) 0%
    );
    background-size: 10px 10px;
  }
}
