@import '/src/variables.scss';

.wrapper {
    padding: 0 16px;
}

.text {
    display: block;
    font-family: Loko_Lato-Regular;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0em;
    text-align: left;
    color: $greyTextColor;
}

.hover:hover {
    color: $whiteColor;
}

.infoFlex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
}

.textLangFlex {
    display: flex;
    justify-content: center;
    gap: 10px;
    user-select: none;
}

.textLang {
    font-family: Loko_Lato-Regular;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: $footerSocialTextColor;
}

.langBoxFlex {
    display: flex;
    justify-content: space-between;
    gap: 6px;
}

.mobileWidth {
    .textLangFlex {
        margin-top: 20px;
    }

    .text {
        text-align: center;
    }
}
