@import '/src/variables.scss';

.tableHeader {
  border-top: 1px solid $strokeGreyColor;
  border-bottom: 1px solid $strokeGreyColor;
  padding: 10px 0px;
}

.tableHeader th {
  font-family: Loko_Lato-Black;
  font-weight: 900;
  font-size: 16px;
  line-height: 1.2;
  padding: 12px 15px;
  height: 60px;
  text-align: center;
  min-width: 68px;
  position: sticky;
  top: -1px;
  background-color: $whiteColor;
}

.tableTr {
  width: 80px;
  border-top: 1px solid $strokeGreyColor;
  border-bottom: 1px solid $strokeGreyColor;
}

.tableTrBottom {
  border-top: 1px solid $strokeGreyColor;
  border-bottom: 1px solid $mainRedColor;
}

.tableTr td {
  font-family: Loko_Lato-Regular;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: $blackColor;
  height: 60px;
  padding: 16px;
  text-align: center;
}

.tableTr .club {
  font-family: Loko_Lato-Regular;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: $blackColor;
  height: 60px;
  padding: 16px;
  text-align: left;
  min-width: 220px;
}

.tableHeader th .club {
  text-align: left;
}

td .club,
.club {
  width: 250px;
  padding-left: 0px;
  position: sticky;
  left: 0;
  text-align: left;
  background-color: $whiteColor;
}

.name {
  display: block;
  font-family: Loko_Lato-Bold;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0em;
  text-align: left;
  color: $blackColor;
}

.position {
  display: block;
  font-family: Loko_Lato-Regular;
  font-size: 11px;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: 0em;
  text-align: left;
  color: $greyTextColor;
}

.img {
  height: 50px;
  width: 50px;
  object-fit: cover;
  background-color: $greyTextColor;
  border-radius: 50%;
}

.playerInfo {
  display: flex;
  gap: 16px;
  align-items: center;
}

.number {
  font-family: hc_Lokomotiv;
  font-size: 19px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: $blackColor;
}

.active {
  background-color: $strokeGreyColor;
}

.notActive {
  background-color: $whiteColor;
}

.statsTableWrapper {
  overflow-x: scroll;
  height: 100%;
  // max-height: calc({высота_игрока}px * {кол-во_игроков + 1(для шапки)}
  // + {высота игрока} / 2) если показать последнего игрока наполовину
  max-height: calc(82px * 7);
}
