@import '/src/variables.scss';

.pageHeader {
  color: $whiteColor;
  font-family: L_Halvar_Mittelschrift-Bold;
  font-size: 74px;
  font-style: normal;
  line-height: 1.1;
  padding: 197px 0 50px;
  margin: 0;
}

@media screen and (max-width: 1320px) {
  .pageHeader {
    padding: 197px 16px 50px;
    margin: 0;
  }
}

.pageHeaderSmall {
  font-size: 40px;
  padding: 50px 16px;
  margin: 0;
}
