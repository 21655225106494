.button {
    display: flex;
    align-items: center;
    color: #C8102E;
    font-family: Loko_Lato-Bold;
    font-size: 16px;
    font-style: normal;
    line-height: normal;

    .title {
        margin: 0 8px 0 0;
    }
}