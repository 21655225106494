@import '/src/variables.scss';

.userProfileWidget {
  border-radius: 10px;
  background: $whiteColor;
  padding: 20px 20px;
  display: flex;
  align-items: center;
  gap: 20px;

  .imageWrapper {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    min-width: 60px;

    .image {
      width: 60px;
      height: 60px;
    }
  }

  .profileData {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
    width: calc(100vw - 160px);

    .userName {
      color: $blackColor;
      font-family: L_Halvar_Mittelschrift-Regular;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      overflow: hidden;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .actionButtons {
      display: flex;
      gap: 15px;

      .action {
        color: $mainBlueColor;
        font-family: Loko_Lato-Regular;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        cursor: pointer;
      }
    }
  }
}
