.playerCardInfo {
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: end;
}

.photoWrapper {
  z-index: 1000;
  flex: 3.5;
}

.photo {
  border-radius: 10px;
  height: 425px;
  width: auto;
  object-fit: cover;
  aspect-ratio: 400 / 425;
}
@media screen and (max-width: 1200px) {
  .playerCardInfo {
    gap: 30px;
  }
}
