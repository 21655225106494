.text td,
.text th {
	white-space: inherit;
}

body>span>div[id^="stUI"] {
	z-index: 100001 !important;
}

a {
	cursor: pointer;
}

footer .footer .partners td img {
	-webkit-filter: brightness(0) invert(0);
	filter: brightness(1) invert(0);
	opacity: 1;
}

select {
	height: auto;
}

@media (max-width: 1100px) {
	body.show-menu .mobile-menu-aside {
		position: fixed;
		overflow: auto;
	}
}

.strankovani {
	text-align: center
}

.strankovani a {
	display: inline-block;
	margin: 5px
}

.strankovani .strankovaniVyrazne {
	font-weight: bold
}

.forum #forum {
	margin-top: 10px
}

.forum #forum span {
	display: inline
}

.forum #forum fieldset a {
	color: #000
}

.forum #forum legend {
	width: auto
}

.forum #forum .karma.positive {
	font-weight: bold;
	color: #398908;
}

.forum #forum .karma.negative {
	font-weight: bold;
	color: #AD080A;
}

.forum #forum #df_form {
	width: auto;
	margin: 15px auto auto
}

.forum #forum #df_form .border {
	border: 1px solid #BDCCD0
}

.forum #forum #df_form #smiles_outer {
	position: relative;
	display: none;
	left: 37px;
}

.forum #forum #df_form #smiles {
	padding: 10px 20px;
	max-width: 720px;
	margin: 0 auto;
	background: white;
	border: 1px solid #ebebeb;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.07);
	position: absolute;
	bottom: 0;
}

.forum #forum #df_form #smiles a {
	cursor: pointer;
	display: inline-block;
	margin: 3px;
	transition: none;
}

.forum #forum #df_form #smiles a:hover {
	border: 1px solid #eee;
	margin: 0;
	padding: 2px;
	border-radius: 2px;
}

.forum #forum #df_form #bbcode_buttons {
	padding: 4px 37px
}

.forum #forum #df_form #form_left {
	float: none;
	width: 100%;
	padding: 0 37px
}

.forum #forum #df_form #form_right {
	float: none;
	width: 100%;
	text-align: right;
	padding-top: 5px;
	margin-top: 14px
}

.forum #forum #df_form #form_right .avatar {
	width: 152px;
	padding-top: 0px;
	padding-right: 15px;
	text-align: center;
	font-size: 12px;
	line-height: 1;
}

.forum #forum #df_form #form_right .avatar img {
	margin-bottom: 5px;
	width: 100px;
	height: 100px;
	object-fit: contain;
}

.forum #forum #df_form #form_right table {
	width: 100%;
	margin: 0
}

.forum #forum #df_form #form_right table tr th {
	color: #000;
	background: 0 0;
	border: 0;
	padding: 0
}

.forum #forum #df_form #form_right table tr th a {
	color: #000
}

.forum #forum #df_form #form_right table tr td.right {
	padding-right: 37px
}

.forum #forum #df_form #form_right label {
	font-weight: 700;
	cursor: pointer
}

.forum #forum #forum_reaction {
	text-align: center
}

.forum #forum #coments .space {
	height: 1px
}

.forum #forum #coments .comment {
	padding: 10px 0
}

.forum #forum #coments .comment:after {
	display: table;
	clear: both
}

.forum #forum #coments .comment .comment_left {
	float: left;
	text-align: center;
	font-size: 12px;
	line-height: 1;
	margin-right: 15px;
}

.forum #forum #coments .comment .comment_left img {
	margin-bottom: 5px;
	width: 100px;
	height: 100px;
	object-fit: contain;
}

.forum #forum #coments .comment .comment_left img.border {
	border: 1px solid #e6e6e6
}

.forum #forum #coments .comment .comment_right a.mail span,
.forum .hidden {
	display: none
}

.forum #forum #coments .comment .comment_right {
	padding: 0;
	float: left;
	width: 85%
}

.forum #forum #coments .comment .comment_right .space {
	height: 0
}

.forum #forum #coments .comment .comment_right .comment_head {
	padding: 3px 10px;
	background: #F4F6F7;
	border-top: 1px solid #bdccd0;
	font-size: 16px;
	line-height: 24px
}

.forum #forum #coments .comment .comment_right .comment_head a {
	font-weight: 400
}

.forum #forum #coments .comment .comment_right .comment_head a.karma {
	display: inline-block;
	width: 18px;
	height: 18px;
	cursor: pointer;
	opacity: 0.2;
	margin: 0 3px;
	vertical-align: -3px;
}

.forum #forum #coments .comment .comment_right .comment_head a.karma:hover {
	opacity: 1;
}

.forum #forum #coments .comment .comment_right .comment_head a.karma.True {
	opacity: 1;
}

.forum #forum #coments .comment .comment_right .comment_head a.karma span {
	display: none
}

.forum #forum #coments .comment .comment_right .comment_head a.karma_plus {
	background: url("./assets/karma_plus.png") no-repeat
}

.forum #forum #coments .comment .comment_right .comment_head a.karma_minus {
	background: url("./assets/karma_minus.png") no-repeat
}

.forum #forum #coments .comment .comment_right .comment_text_raw {
	display: none
}

.forum #forum #coments .comment .comment_right .comment_text {
	line-height: 1.48;
	padding: 8px 8px 15px;
	background: #fff;
	color: #000;
	word-break: break-word;
	white-space: pre-wrap;
}

.forum #forum #coments .comment .comment_right .comment_text blockquote {
	border-left: 3px solid #BDCCD0;
	margin: 5px 2px 8px 2px;
	padding: 5px 5px 5px 10px;
	background: #F4F6F7;
	color: #626b6b;
}

.forum #forum #coments .comment .comment_right .comment_text blockquote+br {
	display: none;
}

.forum #forum #coments .comment .comment_right .comment_text>blockquote:not(.expanded) {
	height: 1.8em;
	overflow: hidden;
	position: relative;
	cursor: pointer;
}

.forum #forum #coments .comment .comment_right .comment_text>blockquote:not(.expanded):after {
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer;
	padding: 5px 50px 10px;
	background: #F4F6F7;
	font-style: italic;
}

.forum #forum #coments .comment .comment_right .comment_text>blockquote:not(.expanded):after:hover {
	text-decoration: underline;
}

.forum #forum #coments .comment .comment_right .comment_text .comment_text_reaction {
	padding: 0 0 5px;
	border-bottom: 2px solid #bdccd0;
	margin-bottom: 5px;
	font-size: 0.9em;
	line-height: 1.6em
}

.forum #forum #coments .comment .comment_right .comment_text .comment_text_reaction img {
	margin-top: 1px
}

.forum #forum .forum_box {
	background-color: #F4F6F7;
	padding: 5px;
	width: auto
}

.forum #forum .forum_box .menicko {
	margin: 0
}

.forum #forum .menicko.functions {
	font-size: 0.9em;
	border: 1px solid #efefef;
	margin-top: 25px
}

.forum #forum_subpage .forum_box {
	border: 1px solid #3e3e3e;
	padding: 5px;
	width: auto
}

.forum #forum_subpage form table.nice {
	width: 570px;
	margin: auto
}

.forum #forum_subpage form table.nice tr td.item {
	padding-right: 10px
}

.forum #forum_subpage form table.nice tr td input,
.forum #forum_subpage form table.nice tr td select {
	width: 200px;
	margin-left: 5px;
	padding-left: 5px;
	padding-right: 5px;
}

.forum #forum_subpage form table.nice tr td input.captcha {
	width: 50px
}

.forum #forum_subpage form table.nice tr td input.souhlas {
	width: auto
}

.forum #forum_subpage #forum_rules {
	overflow: auto;
	width: 350px;
	height: 150px;
	padding: 5px;
	background-color: #fff;
	color: #000;
	margin: auto;
	border: 1px solid #3e3e3e
}

.forum label {
	font-size: 16px;
	text-transform: uppercase;
	line-height: 24px;
	vertical-align: middle;
	margin-right: 5px
}

.forum .toleft {
	float: left
}

.forum .toright {
	float: right
}

.forum textarea {
	height: 150px;
	background-color: #fff;
	border: 1px solid #bdccd0;
	width: 100%;
	margin: 0 auto;
	display: block;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
	font-size: 16px;
	font-weight: normal;
}

.forum input[type=password],
.forum input[type=text] {
	background-color: #fff;
	border: 1px solid #bdccd0;
	height: 37px
}

.forum input[disabled] {
	color: #888;
	background: rgb(245, 245, 245)
}

.forum table tr {
	border-color: transparent !important
}

.forum #smiles {
	text-align: center
}

.forum input[type=submit] {
	display: inline-block
}

.forum .menicko select {
	margin-bottom: 8px;
	margin-left: 4px;
	margin-right: 4px;
	vertical-align: initial;
	min-width: 85px;
}

.forum center {
	display: none
}

.forum .strankovani_box {
	text-align: center
}

.forum .strankovani_box a {
	display: inline-block;
	margin: 0 5px
}

.forum .strankovani_box a.strankovaniVyrazne {
	font-weight: bold
}

@media (max-width:768px) {

	.forum #forum #df_form #form_right table td,
	.forum #forum #df_form #form_right table th {
		display: block;
		width: 100%
	}

	.forum #forum #df_form #form_right table th {
		display: none
	}

	.forum #forum #df_form #form_right table .right {
		text-align: center
	}

	.forum #forum #df_form #form_right table input[type=submit] {
		width: 100%;
		margin-bottom: 4px
	}

	.forum input[type=password],
	.forum input[type=text],
	textarea {
		width: 100% !important
	}

	.forum textarea {
		margin: 0 10px !important;
		width: 96% !important
	}

	.forum #forum #coments .comment .comment_right {
		width: 100%
	}

	.forum #forum #coments .comment .comment_left {
		display: none
	}
}

.invert-partners {
	background-color: #1B212D;
}

footer .footer .invert-partners td span {
	color: white;
}


.text table.schedule td {
	padding: 12px 2px 12px 10px;
}

@media (max-width: 900px) {
	.filter-wrapper {
		position: relative !important;
		float: left;
		margin: 0 0 20px 0;
	}
}

select {
	overflow: visible;
}

select {
	font: inherit;
}

select {
	text-transform: none;
}

select {
	-webkit-appearance: none;
}

select {
	-ms-touch-action: manipulation;
	touch-action: manipulation;
}

select {
	margin: 0;
	line-height: inherit;
	border-radius: 0;
}

::-moz-selection {
	background: rgba(207, 7, 44, .7);
}

::selection {
	background: rgba(207, 7, 44, .7);
}

select {
	font-size: 16px;
	line-height: 1;
}

select {
	-webkit-border-radius: 0;
	-moz-appearance: none;
	appearance: none;
	background: url('./assets/dropdown_arrow.svg') center right 15px no-repeat;
	border: 1px solid #cf072c;
	height: 38px;
	padding-left: 15px;
	padding-right: 40px;
	color: #fff;
}

select:focus {
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	-ms-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	outline: 0;
}


.filter-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	position: absolute;
	top: 0;
	right: 0;
}

.menicko-wrap {
	position: relative;
}

.selectbox-wrapper {
	margin-right: 20px;
}

#enemy-wrapper,
#datum-wrapper {
	max-width: 160px;
}

.article-text table {display:block;overflow-x:auto;}

@media (max-width: 400px) {

	#enemy-wrapper,
	#datum-wrapper {
		max-width: 130px;
	}

	select {
		font-size: 12px;
		line-height: 1;
	}
}

.article-text table {
	border-collapse: collapse;
}

.article-text table {
	width: 100%;
	margin: 10px 0;
}

.article-text table tr th,
.article-text table tr td {
	padding: 8px 14px 6px 0;
	vertical-align: top;
}

.article-text table tr td {
	border-top: 1px solid #f1f0f4;
	border-bottom: 1px solid #f1f0f4;
}

.article-text table p {
	margin: 4px 0 !important;
}

.article-text table tr:nth-child(2n) {
	background: rgba(254, 190, 16, 0.14);
}

.article-text table tr:nth-child(1) {
	background: none;
}

.article-text table {
	display: block;
	overflow-x: auto;
}

.article-text table thead,
.article-text table tbody {}

.text tr td:first-child,
.text tr th:first-child,
.article-text table tr td:first-child {
	padding-left: 17px;
}

.article-text table tr td:last-child {
	padding-right: 12px;
}

@media (max-width: 700px) {
	.article-text table tr th,
	.article-text table tr td,
	.article-text table {
		font-size: 10px !important
	}

	.article-text table tr td:first-child {
		padding-left: 0px;
	}

	.article-text table tr td:last-child {
		padding-right: 0px;
	}

	.text tr td:first-child,
	.text tr th:first-child {
		padding-left: 0px;
	}

	.article-text table tr th,
	.article-text table tr td {
		padding: 8px 6px 6px 0;
	}
}


.hp-multimedia .multimedia img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.ytvideo iframe {
	height: 200px;
	width: 100%;
}

.ytvideo {
	padding: 0;
}

.text tr th.jmeno {
	text-align: left !important;
	width: 210px;
}

.logo-widget-wrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.logo-widget {
	height: 70px;
	width: 635px;
	margin-bottom: 42px;
	-webkit-clip-path: inset(0 0 0 0);
	clip-path: inset(0 0 0 0);
	position: relative;
	overflow: hidden;
	box-sizing: border-box;
}

.logo-widget-container {
	position: absolute;
	text-align: left;
	transition: all 1s ease-out;
	left: 0px;
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	width: 100%;
}

.logo-widget-container .hp-banner {
	margin-bottom: 0px;
}

@media (max-width: 829px) {
	.hp-banner .row .banner:nth-child(1) {
		margin-bottom: 0px !important;
	}
}