.cardBio {
  display: flex;
  width: 100%;
  height: calc(100% - 100px);
  justify-content: space-between;

  &Column {
    width: 100%;
    display: flex;
    flex-direction: column;

    &Catastrophe {
      width: 60%;
      display: flex;
      flex-direction: column;
      justify-content: end;
    }
  }
}

.numbers {
  display: flex;
  justify-content: space-between;
}

.column {
  flex: 1;
}

.columns {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  gap: 12%;
}

@media screen and (max-width: 960px) {
  .cardBioColumn {
    height: auto;
  }
  .columns {
    gap: 30px;
  }
}

@media screen and (max-width: 480px) {
  .columns {
    flex-direction: column;
  }
  .column {
    width: 100%;
    margin-bottom: 30px;
  }
  .cardBioColumnCatastrophe {
    width: 100%;
  }
}
