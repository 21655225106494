@import '/src/variables.scss';

.selector {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  user-select: none;
  overflow: hidden;
}

.selectorItem {
  width: 50%;
  background-color: $strokeGreyColor;
  color: $mainBlueColor;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  img path {
    fill: $mainBlueColor;
  }

  span {
    margin-left: 10px;
    font-family: Loko_Lato-Bold;
    font-size: 16px;
    line-height: 1.2;
    text-transform: uppercase;
  }
}

.selectorItemSelected {
  @extend .selectorItem;
  background-color: $mainBlueColor;
  color: $whiteColor;
  img path {
    fill: $whiteColor;
  }
}

@media screen and (max-width: 1080px) {
  .selectorItem {
    width: 227px;
  }
}

@media screen and (max-width: 480px) {
  .selectorItem {
    width: 100%;
    span {
      font-size: 12px;
    }
  }
}
