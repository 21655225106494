@import '/src/variables.scss';

.editProfileModal {
  border-radius: 10px;
  background: $whiteColor;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .header {
    color: $blackColor;
    font-family: L_Halvar_Mittelschrift-Bold;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.158;
  }

  .inputsBlock {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-height: 500px;
    overflow-y: auto;
    padding-right: 10px;

    .inputWrapper {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .span {
        color: $darkGreyText;
        font-family: Loko_Lato-Regular;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .input {
        :global {
          .ant-input {
            padding: 10px 20px;
            align-items: center;
            border-radius: 10px;
            border: 1px solid $strokeGreyColor;

            color: #000;

            font-family: Loko_Lato-Regular;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        .incorrectEmail {
          padding-left: 10px;
          padding-top: 3px;
          color: $mainRedColor;
          font-family: Loko_Lato-Regular;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
      .passwordInput {
        display: flex;
        flex-direction: column;

        :global {
          .ant-input-affix-wrapper {
            padding: 10px 20px;
            align-items: center;
            border-radius: 10px;
            border: 1px solid $strokeGreyColor;
          }

          .ant-input {
            color: #000;

            font-family: Loko_Lato-Regular;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        .passwordsShouldMatch {
          padding-left: 10px;
          padding-top: 3px;
          color: $mainRedColor;
          font-family: Loko_Lato-Regular;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .avatarSection {
        display: flex;
        flex-direction: column;
        gap: 5px;

        .avatarInput {
          display: flex;
          gap: 10px;
          .previewSmall {
            width: 80px;
            height: 80px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            top: 0;
            left: 0;

            .uploadedImage {
              position: relative;
              top: 0;
              left: 0;
              border-radius: 5px;
              max-height: 80px;
            }

            .closeIcon {
              position: absolute;
              top: 0;
              right: 0;
              cursor: pointer;
            }
          }
        }

        .previewText {
          color: $greyTextColor;
          font-family: Loko_Lato-Regular;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }

  .actionButtons {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: flex-start;

    .actionButton {
      padding: 8px 24px;
      border-radius: 8px;
      border: 0;
      background: $strokeGreyColor;
      color: $darkGreyText;
      font-family: Loko_Lato-Bold;
      font-size: 14px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
    }

    .active {
      background: $mainRedColor;
      color: $whiteColor;
    }
  }
}
