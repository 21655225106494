@import '/src/variables.scss';

.wrapper {
    border-radius: 10px;
}

.leadersInfoColumn {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.leadersInfo {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
    gap: 30px 20px;
    grid-auto-rows: max-content;
}
