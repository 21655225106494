@import '/src/variables.scss';

.widgetWrapper {
  padding: 20px;
  border-radius: 10px;
  border: solid 2px $strokeGreyColor;
  background-color: $whiteColor;
  width: 100%;
  align-items: center;
  vertical-align: middle;
  position: relative;
  min-height: 296px;
}

.headerMatch {
  font-family: Loko_Lato-Heavy;
  font-weight: 800;
  font-size: 24px;
  text-align: center;
  line-height: 1.2;
  color: $blackColor;
  margin-bottom: 10px;

  &Today {
    color: $whiteColor;
  }
}

.headerDate {
  font-family: Loko_Lato-Regular;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  text-align: center;
  margin-bottom: 10px;
  color: $blackColor;
}

.widgetTeams {
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: repeat(3, calc(33.3% - 13.3px));
  grid-template-rows: 1fr;
  gap: 20px;
}

.logo {
  width: 70px;
  height: 70px;
}

.scoreNumber {
  display: flex;
  flex-direction: column;
  font-family: L_Halvar_Mittelschrift-Bold;
  font-size: 48px;
  color: $greyTextColor;
  line-height: 1.16;
  letter-spacing: -0.03em;
  text-align: center;
  justify-content: center;
  white-space: nowrap;
}

.stadium {
  font-family: Loko_Lato-Regular;
  font-weight: 400;
  font-size: 12px;
  color: $blackColor;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: normal;
}

.grey {
  color: $lightGrey;
}

@media screen and (max-width: 400) {
  .widgetWrapper {
    padding: 16px;
    min-height: 292px;
  }
}

@media screen and (max-width: 360px) {
  .widgetWrapper {
    padding: 16px;
    min-height: 240px;
  }
  .headerMatch {
    font-size: 19px;
    margin-bottom: 7.74px;
  }
  .headerDate {
    font-size: 11px;
    margin-bottom: 15.5px;
  }
  .logo {
    width: 54px;
    height: 54px;
    flex: 5;
    margin-bottom: 6.19px;
  }
}
