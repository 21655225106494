@import '/src/variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 20px;
  //min-width: calc(100% + 20px);
  box-sizing: border-box;
  aspect-ratio: 315/550;
}

.wrapperAbsolute {
  top: -20px;
  left: -20px;
  bottom: -20px;
  right: -20px;
  z-index: 40;
  position: absolute;
}

.padding {
  background-color: $whiteColor;
  border-radius: 10px;
  border: 1px solid $strokeGreyColor;
  box-shadow: -2px 5px 25px 0px #00256d1a;
  padding: 20px;
  height: calc(100% + 25px);
}

.imgWrapper {
  width: 100%;
  position: relative;
  background-color: $strokeGreyColor;
  border-radius: 10px 10px 0px 0px;
}

.img {
  width: 100%;
  aspect-ratio: 275/330;
  object-fit: cover;
  border-radius: 10px 10px 0px 0px;
}

.injury {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 40px;
}

.number {
  font-family: hc_Lokomotiv;
  font-size: 56px;
  font-weight: 400;
  line-height: 56px;
  letter-spacing: 0em;
  text-align: center;
  color: $mainRedColor;
}

.name {
  display: block;
  font-family: L_Halvar_Mittelschrift-Bold;
  font-size: 22px;
  font-weight: 700;
  line-height: 25.48px;
  letter-spacing: 0em;
  text-align: left;
  color: $mainRedColor;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: calc(50vw - 30px);
}

.nameWrapper {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 10px;
  padding: 0px 10px;
  margin-top: 10px;
  max-width: 230px;
}

.infoWrapper {
  padding: 0px 8px;
  display: flex;
  text-align: center;
  overflow: hidden;
  padding: 10px;
  justify-content: space-between;
  border-top: 1px solid $articleContentBackground;
}

.blockWrapper {
  overflow: hidden;
}

@media screen and (max-width: 1200px) {
  .padding {
    height: calc(100% + 40px);
  }
}

@media screen and (max-width: 768px) {
  .padding {
    height: calc(100% + 20px);
  }
}

@media screen and (max-width: 600px) {
  .padding {
    height: calc(100% + 40px);
  }
}
