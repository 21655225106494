@import '/src/variables.scss';

.team {
  &Wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &Name {
    font-family: Loko_Lato-Bold;
    font-weight: 800;
    font-size: 16px;
    text-align: center;
    line-height: 1;
    margin-bottom: 4px;
    color: $blackColor;
    flex: 2;
  }

  &City {
    font-family: Loko_Lato-Regular;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    line-height: 1;
    color: $blackColor;
    flex: 1;
  }
}

.white {
  color: white;
}

@media screen and (max-width: 480px) {
  .teamName {
    font-size: 12px;
  }
  .teamCity {
    font-size: 10px;
  }
}

@media screen and (max-width: 360px) {
  .teamName {
    font-size: 10px;
    text-overflow: ellipsis;
    width: 50px;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 3px;
  }
  .teamCity {
    font-size: 9px;
    text-overflow: ellipsis;
    width: 50px;
  }
}
