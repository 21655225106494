@import '/src/variables.scss';

.topStoryWidget {
  display: flex;
  gap: 20px;
}

.topStoryWidgetRow {
  flex-direction: row;
}

.topStoryWidgetColumn {
  flex-direction: column;
  margin-top: 20px;
}

.topStoryWidgetGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  gap: 20px;
}

.link {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  &:hover {
    .arrowIcon {
      transform: rotate(45deg);
      transition: transform 0.3s ease-in-out;
    }
  }

  .linkContainer {
    display: flex;
    gap: 8px;
    align-items: center;

    .linkText {
      font-family: Loko_Lato-Bold;
      font-size: 16px;
      line-height: 1.1875;
      color: $mainRedColor;
    }

    .arrowIcon {
      transition: transform 0.3s ease-in-out;
    }
  }
}

// @media screen and (max-width: 1320px) {
//   .topStoryWidget {
//     flex-direction: column;
//   }
// }

// @media screen and (max-width: 850px) {
//   .topStoryWidget {
//     flex-direction: column;
//   }
// }

@media screen and (max-width: 360px) {
  .link {
    .linkContainer {
      .linkText {
        font-size: 14px;
      }
    }
  }
}
