@import '/src/variables.scss';

.container {
  display: flex;
  align-items: end;
  flex-direction: column;
  flex: 7;
}

.tabletWrapper {
  flex: 7;
  background-color: $footerSocialNetworkscolor;
  padding: 17px 20px 16px 20px;
}

.mobileWrapper {
  flex: 7;
  background-color: $footerSocialNetworkscolor;
  padding: 17px 20px 16px 20px;
  width: 100%;
}

.desktopWrapper {
  flex: 7;
  background-color: $footerSocialNetworkscolor;
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.contentWrapper {
  padding-bottom: 20px;
}

.innerWrapper {
  background-color: $footerSocialNetworkscolor;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.formRow {
  flex: 1;
  display: flex;
  gap: 15px;
  min-height: 60px;
  margin-bottom: 10px;
}

.textRow {
  display: flex;
  align-items: center;
  flex: 1;
}

.text {
  font-family: Loko_Lato-Bold;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.2;
  letter-spacing: 0px;
  text-align: left;
  color: $footerSocialTextColor;
  text-transform: uppercase;
}

.content {
  margin-top: 20px;
}

.input {
  flex: 2;
  padding: 10px;
  background-color: $footerSocialNetworkscolor;
  color: $greyTextColor;
  border: 1px solid $mainRedColor;
  font-family: Loko_Lato-Regular;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0em;
  text-align: left;
  height: 60px;
  width: 100%;
  min-width: 350px;
}

.unsubscribeBtnsContainer {
  margin-top: 20px;
  display: flex;
  gap: 20px;
}

.unsubscribeBtn {
  border: none !important;
  outline: none !important;

  margin-top: 20px;
  width: 60px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;

  color: $whiteColor;
  font-family: Loko_Lato-Bold;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0em;
  text-align: left;
}

.unsubscribeBtnYes {
  background-color: $mainRedColor;
}

.unsubscribeBtnNo {
  background-color: $mainBlueColor;
}

.unsubscribeBtnConfirm {
  background-color: $mainBlueColor;
  width: 120px;
}

.notificationText {
  font-family: Loko_Lato-Regular;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: 0px;
  text-align: left;
  color: $blackColor;
}

@media screen and (max-width: 480px) {
  .input {
    min-width: 100%;
  }
}

.input:focus {
  color: $whiteColor;
  outline: none;
}

.button {
  flex: 1;
  background-color: $mainRedColor;
  border: none;
  padding: 21px 30px 20px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button:focus {
  outline: none;
}

.buttonText {
  text-transform: uppercase;
  font-family: Loko_Lato-Bold;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0em;
  text-align: left;
  color: $whiteColor;
}

.politicText {
  flex: 1;
  font-family: Loko_Lato-Regular;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: 0px;
  text-align: left;
  color: $smallFooterTextColor;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.errorText {
  color: $mainRedColor;
}

.middleWidth {
  .politicText {
    flex: unset;
  }
  .divInput {
    flex: 3;
  }
  .button {
    flex: 1;
  }
}

.smallWidth {
  .text {
    display: block;
    text-align: center;
    font-size: 12px;
  }

  .input {
    flex: unset;
  }
}
