@import '/src/variables.scss';

.transitionImg {
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  width: 100%;
  height: 360px;
}

.mainContentItem {
  width: 100%;
  height: 360px;
  border-radius: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 30px;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.1);
  transition: 1s;
  z-index: 2;
  overflow: hidden;

  &:hover {
    transform: scale(1.1);
  }
}

.likes {
  position: absolute;
  right: 0;
  bottom: 30px;
  width: 100%;
}

.mainContentItemBody {
  width: 100%;
  align-items: center;
  display: flex;
  position: relative;
  margin-top: 30px;

  .mainContentItemBodyWrapper {
    position: absolute;
    bottom: 50px;
    left: 10px;
    right: 10px;

    .content {
      width: calc(100% - 100px);
      position: absolute;
      left: 0;
      bottom: 10px;
    }

    .titleWrapper {
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      width: 100%;
      .title {
        font-family: L_Halvar_Mittelschrift-Bold;
        font-size: 35px;
        font-style: normal;
        line-height: normal;
        color: $whiteColor;
        display: block;
      }
    }

    .annotation {
      font-family: Loko_Lato-Regular;
      font-size: 18px;
      font-style: normal;
      line-height: normal;
      color: $whiteColor;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}

@media screen and (max-width: 480px) {
  .mainContentItem {
    padding: 20px;
  }
  .mainContentItemBody {
    .mainContentItemBodyWrapper {
      .titleWrapper {
        .title {
          font-size: 25px;
        }
      }
      .annotation {
        font-size: 15px;
        padding-right: 30px;
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .mainContentItem {
    padding: 10px;
  }
  .mainContentItemBody {
    .mainContentItemBodyWrapper {
      .titleWrapper {
        .title {
          font-size: 25px;
        }
      }
      .annotation {
        font-size: 14px;
        padding-right: 0px;
      }
    }
  }
}
