@import '/src/variables.scss';

.btn {
    display: block;
    width: 100%;
    padding: 16px 20px;
    background-color: $strokeGreyColor;
    outline: none !important;
    border: none;
    border-radius: 8px;
}

.text {
    font-family: Loko_Lato-Medium;
    font-size: 16px;
    line-height: 1.2;
    color: $darkGrey;
    text-transform: uppercase;
}
