@import '/src/variables.scss';

.highlightCard {
  height: 300px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: $whiteColor;
  border: 2px solid $strokeGreyColor;
  position: relative;
}

.iframeContainer {
  position: relative;
  width: 100%;
  iframe {
    width: 100%;
    height: 180px;
    border: none;
    border-radius: 10px 10px 0px 0px;
  }

  button {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: transparent;
    border: none;
    outline: none;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -10%);
  }
}

.caption {
  margin: 21px 16px;
  font-family: Loko_Lato-Bold;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
