@import '/src/variables.scss';

.topPlayer {
  &Link {
    text-decoration: none;
  }

  &Wrapper {
    width: 100%;
  }

  &Header {
    font-family: Loko_Lato-Bold;
    font-weight: 700;
    font-size: 21px;
    color: $blackColor;
    line-height: 1.2;
    display: block;
    margin-bottom: 16px;
  }

  &Name,
  &Surname {
    font-family: L_Halvar_Mittelschrift-Bold;
    font-size: 22px;
    text-transform: uppercase;
    line-height: 1.2;
  }

  &Category {
    font-family: Loko_Lato-Medium;
    font-size: 15px;
    color: $greyTextColor;
    line-height: 1.2;
    position: absolute;
    bottom: 16px;
  }

  &Number {
    margin-right: 8px;
    font-family: L_Halvar_Mittelschrift-Medium;
    font-size: 54px;
    line-height: 1;
  }
}

.cardGap {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.imgWrapper {
  width: 115px;
  height: 115px;
  box-sizing: content-box;
}

.cardPlayer {
  display: flex;
  align-items: center;
  position: relative;

  &Img {
    border-radius: 50%;
    width: 100%;
    min-width: 115px;
    min-height: 115px;
    height: 100%;
    object-fit: cover;
  }

  &Name {
    margin-left: 20px;
  }
}

.number {
  position: relative;
  border-radius: 50%;
  border: 5px solid $lidersBorder;
}

.number::after {
  content: attr(data-number);
  position: absolute;
  bottom: -10%;
  right: -10%;
  width: 48px;
  height: 48px;
  background-color: $whiteColor;
  font-family: hc_Lokomotiv;
  font-weight: 400;
  font-size: 36px;
  text-align: center;
  vertical-align: middle;
  color: $mainBlueColor;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dopValue {
  &Number {
    font-family: L_Halvar_Mittelschrift-Medium;
    font-weight: 500;
    font-size: 36px;
    margin-right: 8px;
    line-height: 1;
  }

  &Text {
    font-family: L_Halvar_Mittelschrift-Bold;
    font-weight: 700;
    font-size: 14px;
    line-height: 1.2;
    color: $blackColor;
  }
}

.statisticContainer {
  display: flex;
  height: 100vh;
  align-items: flex-end;
}

.card {
  &Wrapper {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    max-height: 250px;
    min-height: 250px;
    padding: 15px 17.5px;
    border-radius: 10px;
    background-color: $strokeGreyColor;
    color: $mainRedColor;
    display: flex;
    flex-direction: column;
    border: 4px solid $lidersBorder;
  }
}

.cardBombardier {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  max-height: 250px;
  min-height: 250px;
  padding: 8px 17.5px;
  border-radius: 10px;
  background-color: $strokeGreyColor;
  color: $mainRedColor;
  display: flex;
  flex-direction: column;
  border: 4px solid $lidersBorder;
}

.topText {
  font-family: L_Halvar_Mittelschrift-Medium;
  color: $blackColor;
  font-size: 22px;
  line-height: 1.14;
}

.whiteText {
  color: $whiteColor;
}

.redColor {
  background-color: $mainRedColor;
}

.opacity {
  opacity: 0;
  font-family: L_Halvar_Mittelschrift-Medium;
  font-weight: 500;
  font-size: 25px;
}

@media screen and (max-width: 360px) {
  .topPlayerHeader {
    font-size: 16px;
  }
}
