@import '/src/variables.scss';

.wrapper {
  width: 100%;
  height: 100%;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: center;
  background-color: $darkBlue2;
}

.numberWrapper {
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
}

.number {
  font-family: L_Halvar_Mittelschrift-Bold;
  font-size: 250px;
  line-height: 1.158;
  color: $whiteColor;
}

.textWrapper {
  max-width: 442px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.headerText {
  font-family: Loko_Lato-Bold;
  font-size: 35px;
  line-height: 1.2;
  color: $whiteColor;
  text-align: center;
}

.link {
  width: 100%;
}

.text {
  font-family: Loko_Lato-Regular;
  font-size: 18px;
  line-height: 1.2;
  color: $whiteColor;
  text-align: center;
}

.button {
  width: 100%;
  height: 50px;
  border-radius: 8px;
  padding: 0 40px;
  margin-bottom: 80px;
  background-color: $mainRedColor;
  outline: none !important;
  border: none;
  cursor: pointer;

  font-family: Loko_Lato-Bold;
  font-size: 18px;
  line-height: 1.2;
  color: $whiteColor;
}

@media screen and (max-width: 700px) {
  .number {
    font-size: 125px;
  }
  .img {
    width: 40%;
  }
  .numberWrapper {
    gap: 10px;
  }
}
