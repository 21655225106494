@import '/src/variables.scss';

.wrapper {
  max-width: 1288px;
  margin: 0 auto;
  font-family: Loko_Lato-Regular;

  .contentWrapper {
    background-color: $whiteColor;
    border-radius: 10px 10px 0 0;
    padding: 30px 20px 30px;

    .content {
      flex-basis: calc((100% - 20px) * 2 / 3);
      display: flex;
      flex-direction: column;
      gap: 20px;

      .text {
        max-height: none;
        overflow: hidden;

        &.collapsed {
          max-height: 1000px;
        }
      }

      .showMoreButton {
        border: none;
        margin-top: 30px;
        background-color: $strokeGreyColor;
        color: $darkGreyText;
        width: 100%;
        height: 43px;
        position: relative;

        &::after {
          position: absolute;
          content: '';
          bottom: 73px;
          left: 0;
          width: 100%;
          height: 123px;
          background: linear-gradient(transparent, $whiteColor);
        }
      }
    }

    .sideMenu {
      flex-basis: calc((100% - 20px) / 3);
    }
  }
}

.name {
  font-family: Loko_Lato-Bold;
  font-size: 18px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
  color: $blackColor;
}

@media screen and (max-width: 1320px) {
  .pageHeaderWrapper {
    padding: 0px 16px;
  }
}
