@import '/src//variables.scss';

.score {
  &Wrapper {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &Hover {
      text-align: center;
      margin-bottom: 15.49px;
    }
  }

  &Number {
    display: inline;
    font-family: L_Halvar_Mittelschrift-Bold;
    font-size: 48px;
    color: $mainRedColor;
    line-height: 1.16;
    letter-spacing: -0.03em;
    text-align: center;
  }

  &NumberHover {
    font-family: L_Halvar_Mittelschrift-Bold;
    font-weight: 700;
    color: $greyTextColor;
    font-size: 40px;
    line-height: 1;
    letter-spacing: 0em;
    text-align: center;
  }

  &White {
    color: $whiteColor;
  }

  &Text {
    white-space: nowrap;
  }

  &NumberWrapper {
    white-space: nowrap;
  }
}

.scoreNumberPast {
  color: $mainRedColor;
}

.stadium {
  font-family: Loko_Lato-Regular;
  font-weight: 400;
  font-size: 12px;
  color: $blackColor;
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
  margin: 0 auto;
}

.stadiumWhite {
  font-family: Loko_Lato-Regular;
  font-weight: 400;
  font-size: 12px;
  color: $whiteColor;
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.details {
  font-family: Loko_Lato-Bold;
  font-size: 10px;
  font-weight: 700;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: center;
  color: $blackColor;
}

.detailsCurrent {
  color: $whiteColor;
}

.continuation {
  font-family: L_Halvar_Mittelschrift-Medium;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
}

.black {
  color: $blackColor;
}

.red {
  color: $mainRedColor;
}

.blue {
  color: $mainBlueColor;
}

.white {
  color: $whiteColor;
}

.grey {
  color: $lightGrey;
}

@media screen and (max-width: 360px) {
  .stadium,
  .stadiumWhite {
    font-size: 9px;
  }
  .scoreNumber {
    font-size: 34px;
  }
}
