@import '/src/variables.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Loko_Lato-Black;
  font-weight: 400;
  font-size: 14px;
  line-height: 16.8px;
  text-align: center;
  color: $blackColor;
  width: 90%;
}
.logo {
  height: 46px;
  margin-right: 10px;
}
