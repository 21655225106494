@import '/src/variables.scss';

.tabBar a {
  font-family: Loko_Lato-Regular;
  font-weight: 400;
  font-size: 18px;
  color: $notActiveTab;
  line-height: 1.2;
  margin-right: 60px;
  position: relative;
  display: inline-block;
  padding-bottom: 12px;
  margin-bottom: 30px;
}

.span {
  color: $notActiveTab;
}

.tabBar a:after {
  content: '';
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: $mainRedColor;
  opacity: 0;
  transition: opacity 0.2s;
}

.tabBar a.active {
  color: $mainRedColor !important;
  font-family: Loko_Lato-Bold;
  font-weight: 800;
  text-decoration: none;
  display: inline-block;
}

.tabBar a.active:after {
  opacity: 1;
  color: $mainRedColor !important;
}

.articleText {
  position: relative;
}

.text {
  display: none;
}

.textShow {
  display: block;
}

.articlesGrid {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.mediaTabs {
  display: flex;
  gap: 7.74px;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  gap: 40px 20px;
  padding-bottom: 64px;
}

@media screen and (max-width: 1200px) {
  .wrapper {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    grid-template-columns: 1fr 1fr;
  }

  .tabBar {
    display: flex;
    gap: 20px;
    overflow: auto;
  }

  .tabBar a {
    margin-right: 0px;
    font-family: Loko_Lato-Regular;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: $notActiveTab;
  }
}
