@import '/src/variables.scss';

.mainWrapper {
  background-color: $mainBlueColor;
}

.contentWrapper {
  max-width: 1288px;
  margin: 0 auto;
  position: relative;
  background-color: $articleContentBackground;
}

.headerWrapper {
  padding: 20px 20px 0;

  .breadcrumbsWrapper {
    padding-bottom: 30px;
  }

  .title {
    color: $blackColor;
    font-family: L_Halvar_Mittelschrift-Bold;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.158;
  }
}

.wrapper {
  display: flex;
  padding: 0 20px 20px 20px;
  border-radius: 10px 10px 0 0;
  gap: 20px;
  background-color: $articleContentBackground;

  .content {
    flex-basis: calc((100% - 20px) * 2 / 3);
    max-width: calc((100% - 20px) * 2 / 3);

    border-radius: 10px;
    background: $whiteColor;
    padding: 20px;
  }

  .sideMenu {
    flex-basis: calc((100% - 20px) * 1 / 3);
    max-width: calc((100% - 20px) * 1 / 3);

    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

.scrollToTopWrapper {
  padding: 10px 0 20px;
}

.columnWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 20px 20px;

  .postSectionWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 10px;
    background: $whiteColor;
    padding: 20px;
    padding-top: 30px;
  }
}
