.slider {
  :global {
    .slick-list {
      position: relative;
      width: 100%;
    }

    .slick-arrow {
      position: absolute;
      z-index: 10;
      top: 50%;
      transform: translateY(-50%);
      filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.8));
    }

    .slick-next {
      right: 0;
    }

    .slick-slide {
      margin-right: 5px;
    }
  }
}

.marginRight {
  :global {
    .slick-slide {
      margin-right: 0;
    }
  }
}
