.photoWrapper {
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.photo {
  height: auto;
  min-height: 240px;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  aspect-ratio: 373 / 240;
  overflow: hidden;
  border-radius: 10px;
  transition: 1s;

  position: relative;
  display: block;
  width: 100%;
  height: auto;
  overflow: hidden;
  aspect-ratio: 3/2;
  z-index: 2;

  &:hover {
    transform: scale(1.1);
  }
}
