@import '/src/variables.scss';

.row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 75px;
}

.partnersBox {
  position: relative;
  width: 100%;
}

.partners {
  width: 100%;
  text-align: center;
}

.partnersWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px 5px;
}

.partnerItem {
  padding: 16px;
  align-self: flex-end;
}

.partnerItemAlign {
  padding: 16px;
  align-self: center;
}

.partnerItemGrid {
  padding: 16px;
  display: grid;
  grid-template-rows: 90% 10%;
  grid-template-columns: 1fr;
  align-items: center;
  gap: 8px;
}

.partnersSpan {
  display: block;
  font-family: Loko_Lato-Regular;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: $whiteColor;
}

.header {
  font-family: 'Loko_Lato-Bold';
  font-size: 18px;
  color: $footerSocialTextColor;
  text-align: center;
  line-height: 1.2;
  margin-bottom: 20px;
}

.collapsibleHeaders {
  padding: 35px 16px 20px;
}

.smallWidthRow {
  padding-bottom: 10px;

  .row {
    margin: 0;
    border-bottom: none;

    .header {
      margin-bottom: 0;
    }

    .text {
      display: block;
      font-size: 14px;
    }
  }

  .collapsible {
    border: none;

    .collapsiblePanel {
      border-radius: 0;
      border-bottom: 1px solid $footerBorderColor;

      .text {
        text-align: left;
        max-width: 328px;
        width: 100%;
      }
    }

    :global {
      .ant-collapse-item .ant-collapse-header {
        align-items: center;
        padding: 0;
        border-radius: 0;
        margin-bottom: 10px;
      }

      .ant-collapse-content {
        border-top: 1px solid $footerBorderColor;
        background-color: transparent;

        .ant-collapse-content-box {
          padding: 10px 0;
        }
      }
    }
  }
}

.partnersImg {
  max-height: 70px;
  width: auto;
}
