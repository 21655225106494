@import '/src/variables.scss';

.logoNavigation {
  background-color: $mainRedColor;
  height: 90px;
}

.logoWrapper {
  height: 90px;
  display: flex;
  align-items: center;
}

.logo {
  width: 71px;
  height: 71.05px;
}

.logoText {
  width: 213px;
  height: 28px;
  margin-left: 30px;
}

.nav {
  height: 90px;
  background-color: $whiteColor;
  width: 310px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 30px;
}

.navItem {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navInnerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  height: 90px;
}

.line {
  border-left: 1px solid $mainRedColor;
  height: 10px;
}

.navItemText {
  margin-left: 10px;
  color: $mainRedColor;
  font-family: L_Halvar_Mittelschrift-Medium;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.16;
}

.dropdownMenuContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
}

.dropdownItem {
  display: block;
  width: 100%;
  clear: both;
  font-family: Loko_Lato-Regular;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: $whiteColor;
  text-align: inherit;
  white-space: nowrap;
  background: none;
  border: 0;
}

.dropdownItem:focus,
.dropdownItem:hover {
  color: $mainRedColor;
  text-decoration: none;
}

.navWrapper {
  display: flex;
}

.socials {
  width: 155px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.socialsText {
  font-family: L_Halvar_Mittelschrift-Medium;
  font-size: 16px;
  line-height: 1.2;
  letter-spacing: 0em;
  text-align: left;
  color: $whiteColor;
}

.socialsDropdown {
  display: block;
  width: 149px;
  height: 166px;
  border-radius: 0px 0px 5px 5px;
  background-color: $darkRedColor;
  position: absolute;
  top: 90px;
  z-index: 100;
  box-shadow: 2px 8px 20px 0px #00000040;
}

.dropdownMenuContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  padding: 20px;
}

.dropdownItem:focus,
.dropdownItem:hover {
  color: $whiteColor;
  text-decoration: none;
}

.dropdownItemContainer {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
}

.ticketsDropdown {
  display: block;
  border-radius: 0px 0px 5px 5px;
  background-color: $whiteColor;
  position: absolute;
  top: 90px;
  left: 0;
  z-index: 100;
  box-shadow: 2px 8px 20px 0px #00000040;
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  padding: 20px;
}

.dropdownItemText {
  font-family: Loko_Lato-Regular;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: $mainRedColor;
}

.dropdownItemTicketText {
  font-family: Loko_Lato-Medium;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.16;
  letter-spacing: 0em;
  text-align: left;
  color: $blackColor;

  &:hover {
    color: $mainRedColor;
  }
}
