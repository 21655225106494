@import '/src/variables.scss';

.wrapper {
  width: 100%;
  display: flex;
  height: 200px;
  gap: 20px;
  border-radius: 10px;
  border: 1px solid $strokeGreyColor;
  cursor: pointer;
  box-sizing: border-box;

  .image {
    width: 150px;
    aspect-ratio: 150/200;
    object-fit: cover;
    border-radius: 10px 0px 0px 10px;
  }

  .information {
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .fullnameWrapper {
      .fullname {
        font-family: L_Halvar_Mittelschrift-Bold;
        font-size: 22px;
        line-height: 1.16;
        letter-spacing: 0em;
        text-align: left;
        color: $blackColor;
        margin-bottom: 10px;
        text-transform: uppercase;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .position {
      font-family: Loko_Lato-Regular;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.2;
      letter-spacing: 0em;
      text-align: left;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $darkGrey;
    }

    .linkWrapper {
      display: flex;
      gap: 8px;
      align-items: center;

      .linkText {
        font-family: Loko_Lato-Bold;
        font-size: 16px;
        color: $mainRedColor;
        line-height: 1.2;
      }

      .arrowIcon {
        transition: transform 0.3s ease-in-out;
      }
    }
  }
  &:hover {
    .arrowIcon {
      transform: rotate(45deg);
      transition: transform 0.3s ease-in-out;
    }
  }
}

@media screen and (max-width: 416px) {
  .wrapper {
    gap: 10px;
    .image {
      width: 120px;
    }
    .information {
      padding-top: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      .fullnameWrapper {
        .fullname {
          font-size: 16px;
        }
      }
      .position {
        font-size: 12px;
      }
      .linkWrapper {
        gap: 4px;

        .linkText {
          font-size: 12px;
        }
      }
    }
  }
}
