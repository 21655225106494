@import '/src/variables.scss';
.table {
  width: 100%;
  max-width: 100%;
  background-color: $whiteColor;
  border-radius: 10px;

  .tableBody {
    border: 1px solid $strokeGreyColor;
    width: 100%;

    th {
      text-align: center;
      align-items: center;
      padding: 15px 5px;
      vertical-align: middle;
    }

    td {
      text-align: center;
      align-items: center;
      padding: 10px 15px;
      vertical-align: middle;
      background-color: $whiteColor;
      border-radius: 10px;
    }

    .status {
      text-align: left;
      padding-left: 15px;
    }

    .lastItem {
      padding-right: 15px;
    }
  }

  .tableHeader {
    border-top: 1px solid $whiteColor;
    border-bottom: 1px solid $strokeGreyColor;
    padding: 10px 0px;
    height: 60px;

    color: $greyTextColor;
    text-align: center;
    font-family: Loko_Lato-Black;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    width: 100%;
  }

  .tableRow {
    color: var(--black, #000);
    text-align: center;
    font-family: Loko_Lato-Bold;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    .team {
      display: flex;
      gap: 10px;
      align-items: center;

      .score {
        color: $mainRedColor;
        font-family: L_Halvar_Mittelschrift-Bold;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%; /* 18px */
      }

      .logo {
        width: 50px;
        height: auto;
      }

      .nameAndCity {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 3px;
        align-items: flex-start;

        .name {
          color: $blackColor;
          font-family: Loko_Lato-Bold;
          font-size: 16px;
          font-style: normal;
          font-weight: 800;
          line-height: 100%;
          text-align: left;
        }

        .city {
          color: $blackColor;
          font-family: Loko_Lato-Regular;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
        }
      }
    }
  }
}

.goals {
  white-space: nowrap;
}

@media screen and (max-width: 768px) {
  .table {
    display: block;
    overflow: scroll;
    overflow-y: hidden;
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    border-spacing: 10px;
  }

  .firstTh {
    position: sticky;
    left: 0;
    min-width: 200px;
  }
}
