@import '/src/variables.scss';

.tooltip {
  position: absolute;
  display: flex;
  width: 280px;
  max-height: 376px;
  padding: 10px 10px 20px 10px;
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  transform: translate(-50%, -100%);
  border-radius: 5px;
  pointer-events: none;
  z-index: 9999;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
  }
}

.tooltipImgContainer {
  width: 260px;
  height: 150px;
  pointer-events: all;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }

  .slider {
    :global {
      width: 100%;
      height: 100%;
      .slick-arrow {
        width: 30px;
        height: 30px;
        position: absolute;
        z-index: 10;
        top: 50%;
        transform: translateY(-50%);
        filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.8));
      }

      .slick-list,
      .slick-track {
        height: inherit;
      }

      .slick-slide > div {
        height: 100%;

        img {
          height: 100%;
          object-fit: cover;
        }
      }

      .slick-next {
        right: 0;
      }
    }
  }
}

.captionContainer {
  display: flex;
  margin-top: 10px;
  padding: 2px 10px;
  border-radius: 5px;
  background-color: #16223a;
  font-family: 'Loko_Lato-Bold', sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: white;
  height: 20px;

  .caption {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  p {
    margin: 0;
    word-wrap: break-word;
  }

  &:nth-child(2) {
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

.infoContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 7.74px;
}

.titleContainer {
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-family: Loko_Lato-Bold;
  font-size: 18px;
  font-weight: 800;
  line-height: 1.2;
  letter-spacing: 0em;

  .title {
    font-size: 18px;
    word-wrap: break-word;
    line-height: 1.2;
    margin: 0;
  }
}

.descriptionContainer {
  margin-top: 7.74px;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-family: Loko_Lato-Regular;
  font-size: 16px;
  line-height: 1.2;
  letter-spacing: 0.01em;
  text-align: center;
  color: $darkGrey;

  .description {
    margin: 0;
    font-size: 16px;
    line-height: 1.2;
    word-wrap: break-word;
  }
}

.fullscreenSlide {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 11000;

  img {
    width: 80%;
    height: 80%;
    object-fit: contain;
  }
}
