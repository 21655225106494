@import '/src/variables.scss';

.row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.colS8 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
  -webkit-flex: 0 0 66.66667%;
  -ms-flex: 0 0 66.66667%;
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.colS4 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
  -webkit-flex: 0 0 33.33333%;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.wrapper {
  max-width: 1288px;
  margin: 0 auto;
  font-family: Loko_Lato-Regular;

  .breadcrumbsMargin {
    margin-bottom: 200px;
  }

  .title {
    color: $whiteColor;
    padding-top: 197px;
    padding-bottom: 50px;
    font-family: L_Halvar_Mittelschrift-Bold;
    font-size: 64px;
    line-height: 1.158;
  }

  .content {
    background-color: $articleContentBackground;
    border-radius: 10px 10px 0 0;
    padding: 20px;
  }

  .newsBlock {
    display: flex;
    padding: 20px;
    margin-bottom: 20px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 10px;
    background-color: $whiteColor;

    .header {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .articleCategory {
        color: $greyTextColor;
        font-family: L_Halvar_Mittelschrift-Bold;
        text-transform: uppercase;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
      }
      .articleDate {
        font-family: Loko_Lato-Regular;
        font-size: 12px;
        font-style: normal;
        line-height: normal;
        color: $greyTextColor;
      }
    }

    .newsBlockTitle {
      font-family: L_Halvar_Mittelschrift-Bold;
      font-size: 35px;
      font-style: normal;
      line-height: 1.17;
      letter-spacing: 0em;
      text-align: left;
      color: $blackColor;
    }

    .newsBlockContent {
      margin-bottom: 10px;
      max-width: 100%;
      word-wrap: break-word;
    }

    .newsBlockContent p {
      font-family: Loko_Lato-Regular;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.7;
      color: $blackColor;

      :global(.marker-pink) {
        background-color: pink;
      }
      :global(.marker-green) {
        background-color: lightgreen;
      }
      :global(.marker-blue) {
        background-color: lightskyblue;
      }
      :global(.pen-red) {
        color: red;
        background-color: white;
      }
      :global(.pen-green) {
        color: green;
        background-color: white;
      }
    }

    .newsBlockContent h1 {
      font-family: Loko_Lato-Regular;
      font-size: 30px;
      font-weight: 400;
      line-height: 1.7;
      color: $blackColor;
    }

    .newsBlockContentContainer {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-bottom: 20px;
      border-bottom: 1px solid $strokeGreyColor;
    }

    .button {
      display: flex;
      align-self: flex-start;
      margin-top: 10px;
      margin-bottom: 20px;
      padding: 5px 24px;
      justify-content: center;
      align-items: center;
      background-color: $mainRedColor;
      color: $whiteColor;
      border: none;
      border-radius: 8px;
    }

    .emptyUrlMessage {
      margin-top: 10px;
      align-self: flex-end;
      color: $greyTextColor;
    }

    .newsBlockContentFooter {
      margin-top: 20px;
      padding-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .author {
        font-family: Loko_Lato-Regular;
        margin: 0;
        color: $darkGreyText;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
      }
    }
    .shareContainer {
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;
      width: 100%;
      padding-bottom: 20px;
      border-bottom: 1px solid $strokeGreyColor;

      .social {
        color: $greyTextColor;
        font-family: Loko_Lato-Regular;
        font-size: 12px;
        font-style: normal;
        line-height: normal;
      }

      .copyLink {
        display: flex;
        align-items: center;
        color: $greyTextColor;
        cursor: pointer;
        p {
          font-family: Loko_Lato-Regular;
          font-size: 12px;
          font-style: normal;
          margin: 0 0 0 8px;
        }
      }
    }
    .footer {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      gap: 20px;

      .linkButtonWrapper {
        min-width: 125px;
        display: flex;
        align-items: center;
      }

      .tags {
        display: flex;
        align-items: center;
        gap: 24px;

        .tagSectionTitle {
          color: $greyTextColor;
          font-family: Loko_Lato-Regular;
          font-size: 12px;
          font-style: normal;
          line-height: normal;
        }

        .tagsWrapper {
          display: flex;
          gap: 8px;
          flex-wrap: wrap;

          .tag {
            display: flex;
            padding: 6px 10px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 10px;
            border: 1px solid $strokeGreyColor;
            color: $greyTextColor;
            font-family: L_Halvar_Mittelschrift-Bold;
            font-size: 10px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        }
      }
    }
  }

  .otherBooklets {
    margin-top: 50px;

    .otherBookletsTitle {
      font-family: L_Halvar_Mittelschrift-Bold;
      font-size: 35px;
      font-style: normal;
      text-transform: uppercase;
    }
  }

  .bookletSlider {
    :global {
      .slick-arrow {
        position: absolute;
        z-index: 10;
        top: 50%;
        transform: translateY(-50%);
        filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.8));
      }

      .slick-next {
        right: 0;
      }

      .slick-slide {
        width: 280px !important;
        margin-right: 5px;
      }
      
      .slick-prev.slick-disabled,
      .slick-next.slick-disabled {
        display: none !important;
      }
    }
  }
}

@media screen and (max-width: 1320px) {
  .row {
    flex-direction: column;
  }
  .colS8,
  .colS4 {
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.sliderWrapper {
  width: calc(100vw - 40px);
}

.sliderBookletMobile {
  display: flex;
  align-items: center;
  justify-content: center;
  :global {
    .slick-arrow {
      position: absolute;
      z-index: 10;
      top: 46%;
      transform: translateY(-50%);
      filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.8));
      cursor: pointer;
    }

    .slick-prev {
      left: 0;
    }

    .slick-next {
      right: 0;
    }

    .slick-slide > div {
      height: 100%;

      img {
        height: 100%;
        object-fit: cover;
      }
    }

    .slick-prev.slick-disabled,
    .slick-next.slick-disabled {
      display: none !important;
    }
  }
}
