@import "/src/variables.scss";

.wrapper {
    border-radius: 10px;
    margin-top: 30px;
}

.content {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.contentColumn {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex-wrap: wrap;
}