@import '/src/variables.scss';

.button {
  width: 100%;
  max-width: 369px;
  border-radius: 8px;
  background: $mainRedColor;
  border: 0;
  padding: 10px 40px;
  height: 50px;

  color: $whiteColor;
  font-family: Loko_Lato-Bold;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.button:disabled {
  opacity: 0.3;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;

  .inputWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .span {
      color: $darkGreyText;
      font-family: Loko_Lato-Regular;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .nameInput {
      :global {
        .ant-input {
          padding: 10px 20px;
          align-items: center;
          border-radius: 10px;
          border: 1px solid $strokeGreyColor;

          color: #000;

          font-family: Loko_Lato-Regular;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .incorrectEmail {
        padding-left: 10px;
        padding-top: 3px;
        color: $mainRedColor;
        font-family: Loko_Lato-Regular;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .passwordInput {
      display: flex;
      flex-direction: column;

      :global {
        .ant-input-affix-wrapper {
          padding: 10px 20px;
          align-items: center;
          border-radius: 10px;
          border: 1px solid $strokeGreyColor;
        }

        .ant-input {
          color: #000;

          font-family: Loko_Lato-Regular;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .passwordsShouldMatch {
        padding-left: 10px;
        padding-top: 3px;
        color: $mainRedColor;
        font-family: Loko_Lato-Regular;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .forgetPassword {
        color: $mainBlueColor;
        font-family: Loko_Lato-Regular;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .checkboxes {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-top: 10px;

      .checkboxWrapper {
        display: flex;
        gap: 20px;
        align-items: center;

        .label {
          color: $blackColor;
          font-family: Loko_Lato-Regular;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 0;

          .link {
            color: $mainRedColor;
            font-family: Loko_Lato-Regular;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-decoration-line: underline;
          }
        }

        input[type='checkbox'] {
          min-width: 20px;
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          width: 20px;
          height: 20px;
          border-radius: 5px;
          border: 1px solid $strokeGreyColor;
          position: relative;
          cursor: pointer;
        }

        input[type='checkbox']:checked {
          background-color: #f00; /* Цвет фона при выборе */
        }

        input[type='checkbox']:checked::before {
          content: url('../../../../../assets/icons/checkbox.svg');
          display: block;
          position: absolute;
          top: 65%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #fff;
          font-size: 16px;
        }
      }
    }
  }
}
