@import '/src/variables.scss';

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  align-items: baseline;

  &Text {
    font-family: L_Halvar_Mittelschrift-Bold;
    font-size: 35px;
    color: $blackColor;
    line-height: 1.2;
  }

  .link {
    &:hover {
      .arrowIcon {
        transform: rotate(45deg);
        transition: transform 0.3s ease-in-out;
      }
    }
  }

  &Btn {
    display: flex;
    gap: 8px;
    align-items: center;

    &Text {
      font-family: Loko_Lato-Bold;
      font-size: 16px;
      color: $mainRedColor;
      line-height: 1.2;
    }

    .arrowIcon {
      transition: transform 0.3s ease-in-out;
    }
  }

  .alignCenter {
    display: flex;
    gap: 8px;
  }
}

@media screen and (max-width: 360px) {
  .headerText {
    font-size: 24px;
  }
  .headerBtnText {
    font-size: 14px;
  }
}
