@import '../../../../variables.scss';

figure td {
  border: 1px solid #999999;
}

.text {
  max-height: none;
  overflow: hidden;
  margin-bottom: 30px;

  &.collapsed {
    max-height: 800px;
  }
}

.li {
  color: $mainRedColor;
  list-style-type: none;
  display: list-item;
}

.li::before {
  content: url('../../../../assets/ellipse.svg');
  margin-right: 20px;
}

.li span {
  color: $blackColor;
}

.ellipse {
  font-family: Loko_Lato-Bold;
  font-weight: 800;
  font-size: 20px;
  line-height: 34px;
}

.ellipseSpan {
  color: $blackColor;
  font-family: Loko_Lato-Bold;
  font-size: 20px;
  line-height: 34px;
}

.line {
  border-left: 1px solid $greyTextColor;
  padding-left: 20px;
  font-family: Loko_Lato-Regular;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.7;
  color: $blackColor;
  margin-left: 5px;

  :global(.marker-pink) {
    background-color: pink;
  }
  :global(.marker-green) {
    background-color: lightgreen;
  }
  :global(.marker-blue) {
    background-color: lightskyblue;
  }
  :global(.pen-red) {
    color: red;
    background-color: white;
  }
  :global(.pen-green) {
    color: green;
    background-color: white;
  }
}
