@import '/src/variables.scss';

.infoBox {
    display: flex;
    flex-direction: column;
    gap: 3px;
    align-items: start;
}

.infoKey {
    font-family: Loko_Lato-Medium;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0em;
    text-align: left;
    color: $blackColor;
}

.infoValue {
    font-family: L_Halvar_Mittelschrift-Medium;
    color: $blackColor;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.158;
    letter-spacing: 0em;
    text-align: left;
}

.flagMargin {
    margin-left: 4px
}
