@import '/src/variables.scss';

.shareContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid $strokeGreyColor;
  border-top: 1px solid $strokeGreyColor;

  .social {
    display: flex;
    justify-content: flex-start;
    gap: 26px;
    align-items: center;

    .socialText {
      color: $greyTextColor;
      font-family: Loko_Lato-Regular;
      font-size: 12px;
      font-style: normal;
      line-height: normal;
    }

    .socialIcons {
      display: flex;
      gap: 20px;
      align-items: center;

      .shareButton {
        display: flex;
        align-items: center;
      }
    }
  }

  .copyLink {
    display: flex;
    align-items: center;
    color: $greyTextColor;
    cursor: pointer;

    .copyImg {
      width: 14px !important;
    }

    p {
      font-family: Loko_Lato-Regular;
      font-size: 12px;
      font-style: normal;
      margin: 0 0 0 8px;
    }
  }
}

@media screen and (max-width: 360px) {
  .shareContainer {
    .social {
      gap: 10px;
      .socialIcons {
        gap: 4px;
      }
    }
  }
}
