@import '/src/variables.scss';

.wrapper {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  color: $whiteColor;

  .image {
    min-width: 70px;
    width: 70px;
    height: 70px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 16px;
    border: 5px solid $darkBorderColor;
  }

  .info {
    max-width: 270px;

    .fullname {
      padding-bottom: 10px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      margin-bottom: 6px;
      text-transform: uppercase;
      font-family: L_Halvar_Mittelschrift-Bold;
      font-size: 22px;
      line-height: 1.14;
      letter-spacing: 0em;
      text-align: left;
    }

    .position {
      font-size: 16px;
      font-family: Loko_Lato-Regular;
      margin-bottom: 0px;
      font-weight: 400;
      line-height: 1.2;
      letter-spacing: 0em;
      text-align: left;
    }
  }
}
