@import '/src/variables.scss';

.calendar {
  background-color: $mainRedColor;
  position: relative;
  padding-top: 22px;
  padding-left: 20px;
  padding-right: 20px;
}

.calendarHeader {
  position: relative;
  display: flex;
  align-items: baseline;
  vertical-align: middle;
  margin-bottom: 35px;

  &Text {
    display: block;
    font-family: L_Halvar_Mittelschrift-Bold;
    font-size: 35px;
    line-height: 1.158;
    color: $whiteColor;
    margin-right: 70px;
    padding-left: 20px;
  }
}

@media screen and (max-width: 360px) {
  .calendarHeader {
    &Text {
      font-size: 24px;
    }
  }
}

.calendarHeaderMobile {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  position: relative;
}

.linkMobile {
  display: block;
}

.headerBtnMobile {
  display: flex;
  align-items: center;
  vertical-align: middle;
  flex-wrap: nowrap;
}

.row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.select {
  width: fit-content;
  background-color: $mainRedColor;
  padding: 10px 0;
}

.select:focus {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  outline: 0;
}

.select > div {
  color: $whiteColor;
  line-height: normal;
  font-family: Loko_Lato-Bold;
  font-weight: 700;
  font-size: 16px;
  text-transform: capitalize;
}

.select ul {
  padding: 10px;

  li {
    font-family: Loko_Lato-Regular;
    font-style: normal;
    font-size: 10px;
    font-weight: 400;
    text-transform: none;
    line-height: normal;
    color: black;
    padding: 0 0 8px 0;

    &:last-child {
      padding-bottom: 0;
    }
  }
}

.headerBtn {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;

  position: absolute;
  right: 0;
  top: 0;
  margin-top: 12px;
  margin-right: 16px;

  &Text {
    font-family: Loko_Lato-Bold;
    font-weight: 700;
    font-size: 16px;
    color: $whiteColor;
    margin-right: 8px;
    line-height: 1.2;
  }
}

.slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slickInitialized {
  display: block;
}

.legenda {
  font-family: Loko_Lato-Medium;
  font-weight: 500;
  font-size: 14px;
  color: $whiteColor;
  display: inline;
  line-height: 1.2;
  display: flex;
  align-items: center;

  &Away {
    background-color: $mainBlueColor;
  }

  &Wrapper {
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 20px;
    gap: 16px;
  }

  &Color {
    display: inline-block;
    margin-right: 8px;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    border: solid 2.5px $whiteColor;
  }
}

.buttonRight {
  position: absolute;
  right: 0;
  z-index: 10000;
  background: linear-gradient(
    270deg,
    #c8102e 58.1%,
    rgba(200, 16, 46, 0) 94.58%
  );
  border: none;
  opacity: 1;
  outline: none !important;
  width: 70px;
  height: 150px;
}

.buttonLeft {
  position: absolute;
  left: 0;
  z-index: 10000;
  left: 0;
  background: linear-gradient(
    90deg,
    #c8102e 58.1%,
    rgba(200, 16, 46, 0) 94.58%
  );
  border: none;
  opacity: 1;
  outline: none !important;
  width: 70px;
  height: 150px;
}

.slickTrack {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  opacity: 1;
  transform: translate3d(-786px, 0px, 0px);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition: 0.8s;
}

.carouselItem {
  width: auto !important;
}

.container {
  margin: 0 !important;
}

.calendarSlick {
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 30px;
  :global(.slick-track) {
    display: flex;
  }
}

.calendarSlick {
  :global(.slick-prev.slick-disabled, .slick-next.slick-disabled) {
    display: none !important;
  }
}

.arrowIcon {
  transition: transform 0.3s ease-in-out;
}

.link {
  &:hover {
    .arrowIcon {
      transform: rotate(45deg);
      transition: transform 0.3s ease-in-out;
    }
  }
}

.arrowRight {
  position: absolute;
  right: 0;
  top: calc(50% - 20px);
}

.arrowLeft {
  position: absolute;
  left: 0;
  top: calc(50% - 20px);
}

@media screen and (max-width: 768px) {
  .headerBtn {
    padding-right: 30px;
  }
  .calendarHeaderText {
    margin-right: 0px;
    padding-left: 0px;
  }
  .select {
    padding-left: 0px;
  }
}

@media screen and (max-width: 780px) {
  .legendaWrapper {
    margin-top: 5px;
    margin-bottom: 10px;
    margin-left: 10px;
  }
}

@media screen and (max-width: 480px) {
  .calendarSlick {
    margin-top: 11px;
    .buttonRight,
    .buttonLeft {
      height: 120px;
      width: 60px;
    }
  }
  .legendaWrapper {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 20px;
  }
}

@media screen and (max-width: 360px) {
  .headerBtnText {
    font-size: 14px;
  }
  .select {
    font-family: Loko_Lato-Bold;
    font-size: 14px;
    font-weight: 700;
  }
  .legenda {
    font-size: 12px;
  }
}
