@import '/src/variables.scss';

.sideMenuWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .partnerSection {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .imageWrapper {
      overflow: hidden;
      cursor: pointer;
      text-align: center;
      width: 100%;
      height: 50%;

      img {
        border-radius: 10px;
        object-fit: contain;
        width: 100%;
      }
    }
  }
}
