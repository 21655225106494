@import '/src/variables.scss';

.relative {
  display: inline-flex;
}

.text {
  white-space: nowrap;
}

.block {
  background: $triangleColor;
  position: relative;
}

.block::after {
  content: '';
  position: absolute;
  left: -10px;
  top: 11px;
  border: 3px solid transparent;
  border-top: 3.5px solid $triangleColor;
}

.block::before {
  content: '';
  position: absolute;
  left: -10px;
  bottom: 11px;
  border: 3px solid transparent;
  border-bottom: 3.5px solid $triangleColor;
}

.blockActive {
  background: $blackColor;
  position: relative;
}

.blockActive::after {
  content: '';
  position: absolute;
  left: -10px;
  top: 11px;
  border: 3px solid transparent;
  border-top: 3.5px solid $blackColor;
}

.blockActive::before {
  content: '';
  position: absolute;
  left: -10px;
  bottom: 11px;
  border: 3px solid transparent;
  border-bottom: 3.5px solid $triangleColor;
}

.blockActiveArrowUp {
  background: $blackColor;
  position: relative;
}

.blockActiveArrowUp::after {
  content: '';
  position: absolute;
  left: -10px;
  top: 11px;
  border: 3px solid transparent;
  border-top: 3.5px solid $triangleColor;
}

.blockActiveArrowUp::before {
  content: '';
  position: absolute;
  left: -10px;
  bottom: 11px;
  border: 3px solid transparent;
  border-bottom: 3.5px solid $blackColor;
}

.text {
  color: $greyTextColor;
}

.textActive {
  color: $mainBlueColor;
  background-color: $strokeGreyColor;
}

.firstTh {
  position: sticky;
  left: 0;
  background-color: $whiteColor;
  z-index: 1;
}

.highlight,
.grey {
  background-color: $strokeGreyColor !important;
}
