@import '/src/variables.scss';

.accordionHeadWrapper {
  height: 90px;
  width: 100%;
  background-color: $darkBlue2;
  padding: 0 40px;
  border-radius: 5px;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  .accordionHead {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    .textColumn {
      color: $whiteColor;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .title {
        font-family: L_Halvar_Mittelschrift-Bold;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .subtitle {
        font-family: Loko_Lato-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .icon {
      width: 32px;
      height: 32px;
    }
  }
}

@media screen and (max-width: 480px) {
  .accordionHeadWrapper {
    padding: 0 20px;
    .accordionHead {
      .textColumn {
        gap: 6px;
        .title {
          font-size: 18px;
        }
      }
    }
  }
}

@media screen and (max-width: 280px) {
  .accordionHeadWrapper {
    .accordionHead {
      .textColumn {
        .title {
          font-size: 16px;
          margin: 0;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
  }
}
