.imageInput {
  display: none;
}

.inputLabel {
  width: 80px;
  height: 80px;
  background: #111111;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.disabled {
  opacity: 0.3;
}
