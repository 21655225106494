@import '/src/variables.scss';

.wrapper {
  .title {
    color: var(--black, #000);
    font-family: L_Halvar_Mittelschrift-Bold;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 20px;
    @media screen and (max-width: 360px) {
      font-size: 24px;
    }
  }
}
