@import '/src/variables.scss';

.navbar {
  height: 55px;
  background-color: $darkRedColor;
  position: relative;
}

.scrolled .nav {
  height: 55px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100000;
}

.header {
  width: 100%;
  height: 55px;
  background-color: $mainRedColor;
}

.divider {
  background-color: $whiteColor;
  height: 55px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .dividerLine {
    border-left: 1px solid $mainRedColor;
    height: 10px;
  }
}
