@import '/src/variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;

  .actionButton {
    padding: 8px 24px;
    border-radius: 8px;
    border: 0;
    background: $strokeGreyColor;
    color: $darkGreyText;
    font-family: Loko_Lato-Bold;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }

  .title {
    color: $blackColor;
    font-family: L_Halvar_Mittelschrift-Bold;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
  }

  .listWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .postList {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
}
