@import '/src/variables.scss';
.articleItem {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
  border-bottom: 1px solid $articleContentBackground;

  .imageContainer {
    margin-right: 20px;
    width: 320px;
    height: 180px;
    display: inline-block;
    overflow: hidden;
    border-radius: 10px;

    .img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      object-fit: cover;
      display: block;
      transition: 1s;
      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    margin: 0 auto 20px;
    min-width: 180px;
  }

  .category {
    color: $greyTextColor;
    font-family: L_Halvar_Mittelschrift-Bold;
    font-size: 12px;
    font-style: normal;
    text-transform: uppercase;
    line-height: normal;
  }

  .title {
    font-family: Loko_Lato-Bold;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #000;
  }

  .annotation {
    font-family: Loko_Lato-Regular;
    font-size: 15px;
    font-style: normal;
    line-height: normal;
    color: #000;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .footer {
    display: flex;
    align-items: center;

    .date {
      color: $greyTextColor;
      margin-right: 20px;
      font-family: Loko_Lato-Regular;
      font-size: 12px;
      font-style: normal;
      line-height: normal;
    }
  }
}

@media screen and (max-width: 560px) {
  .articleItem .text {
    display: block;
    margin-top: 20px;
  }
}

@media screen and (max-width: 480px) {
  .articleItem .imageContainer {
    margin: 0 auto 20px;
  }
}
