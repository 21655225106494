@import '/src/variables.scss';

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  row-gap: 30px;
  column-gap: 20px;
  padding-bottom: 30px;

  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media screen and (max-width: 760px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 360px) {
    grid-template-columns: 1fr;
  }
}

.wrapperMobile {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 20px;
  padding-bottom: 30px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
}
