@import '/src/variables.scss';

:global {
  .todo-list li {
    list-style-type: none;
  }
  .todo-list__label__description {
    margin-left: 5px;
  }
}

.wrapper {
  max-width: 1288px;
  margin: 0 auto;
  font-family: Loko_Lato-Regular;
  color: $blackColor;

  .subtitle {
    color: $whiteColor;
    font-family: Loko_Lato-Regular;
    line-height: 1.4;
    padding: 0 0 50px;
    font-size: 16px;
    margin: 0;
  }

  .contentWrapper {
    background-color: $whiteColor;
    border-radius: 10px 10px 0 0;
    padding: 30px 20px;
    display: flex;
    gap: 20px;

    .content {
      flex-basis: calc((100% - 20px) * 2 / 3);
      max-width: calc((100% - 20px) * 2 / 3);

      img {
        border-radius: 8px;
        width: 100%;
      }
      canvas {
        border-radius: 8px;
      }
      a {
        color: $mainBlueColor;
        font-size: 16px;
        line-height: 1.7;
        text-decoration-line: underline;
      }

      .text {
        max-height: none;
        overflow: hidden;
        font-size: 16px;
        line-height: 1.7;

        :global(.marker-pink) {
          background-color: pink;
        }
        :global(.marker-green) {
          background-color: lightgreen;
        }
        :global(.marker-blue) {
          background-color: lightskyblue;
        }
        :global(.pen-red) {
          color: red;
          background-color: white;
        }
        :global(.pen-green) {
          color: green;
          background-color: white;
        }

        &.collapsed {
          max-height: 1000px;
        }
      }

      .showMoreButton {
        border: none;
        border-radius: 8px;
        margin-top: 30px;
        background-color: $strokeGreyColor;
        color: $darkGreyText;
        width: 100%;
        height: 43px;
        position: relative;
        font-family: Loko_Lato-Medium;
        font-size: 16px;
        line-height: 1.2;

        &::after {
          position: absolute;
          content: '';
          bottom: 73px;
          left: 0;
          width: 100%;
          height: 123px;
          background: linear-gradient(transparent, $whiteColor);
        }
      }

      .shareWrapper {
        margin-top: 30px;
        padding: 0 20px;
      }
    }

    .hideAd {
      flex-basis: 100%;
      max-width: 100%;
    }

    .sideMenu {
      flex-basis: calc((100% - 20px) / 3);
      max-width: calc((100% - 20px) / 3);
    }
  }
}

.achievementsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.historyWrapper {
  padding-bottom: 40px;
  max-width: 1288px;
}

.title {
  font-family: L_Halvar_Mittelschrift-Bold;
  font-size: 64px;
  font-weight: 700;
  line-height: 74px;
  letter-spacing: 0em;
  text-align: left;
  color: $whiteColor;
}

.upperCase {
  font-family: L_Halvar_Mittelschrift-Bold;
  font-size: 35px;
  font-weight: 700;
  line-height: 1.16;
  letter-spacing: 0em;
  text-align: left;
  color: $whiteColor;
  padding-top: 40px;
  text-transform: uppercase;
  padding-bottom: 40px;
  margin-bottom: 0;
}

@media screen and (max-width: 1320px) {
  .achievementsWrapper {
    display: block;
  }

  .upperCase {
    padding: 40px 16px;
  }

  .wrapper {
    .contentWrapper {
      flex-direction: column;

      .content,
      .sideMenu {
        max-width: 100%;
      }
    }
  }

  .title {
    padding: 40px 20px;
  }

  .black {
    color: $blackColor;
    text-align: center;
  }

  .headerWrapper {
    padding: 0 16px;
  }
}

@media screen and (max-width: 480px) {
  .wrapper {
    .contentWrapper {
      padding: 30px 16px;
      .content {
        .shareWrapper {
          padding: 0;
        }
      }
    }
  }
}
