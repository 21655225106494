.slider {
  margin-bottom: 30px;

  :global {
    .slick-arrow {
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      cursor: pointer;
      z-index: 10;
      width: 48px;
      height: 48px;
      filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2));
    }

    .slick-slide > div {
      height: 100%;

      img {
        object-fit: cover;
        cursor: pointer;
      }
    }

    .slick-next {
      right: 0;
    }

    .slick-prev {
      left: 0;
    }
  }
}
