@import '/src/variables.scss';

.container {
  height: 100%;
}

.dayWrapper {
  border-radius: 8px;
  border: 1px solid $lightGrey;
  cursor: pointer;
  height: 100%;
  padding-bottom: 5px;

  &Home {
    background: linear-gradient(-62deg, #fff 0% 85%, $mainRedColor 85% 100%);
    color: $mainRedColor;
  }

  &Away {
    background: linear-gradient(-62deg, #fff 0% 85%, $mainBlueColor 85% 100%);
    color: $mainBlueColor;
  }
}

.dateText {
  font-family: L_Halvar_Mittelschrift-Bold;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
  color: $greyTextColor;
  padding-top: 10%;
  line-height: 115%;

  &Wide {
    font-size: 24px;
  }

  &Compact {
    font-size: 16px;
  }

  &Red {
    color: $mainRedColor;
  }

  &Blue {
    color: $mainBlueColor;
  }

  &NotCurrent {
    color: $strokeGreyColor;
  }
}

.dayNameText {
  font-family: Loko_Lato-Regular;
  letter-spacing: 0em;
  text-align: left;
  color: $greyTextColor;
  padding-top: 10%;
  line-height: 115%;
  font-weight: 400;

  &Wide {
    font-size: 14px;
  }

  &Compact {
    font-size: 12px;
  }

  &Red {
    color: $mainRedColor;
  }

  &Blue {
    color: $mainBlueColor;
  }

  &NotCurrent {
    color: $strokeGreyColor;
  }
}

.scoreText {
  line-height: 115%;
  font-family: L_Halvar_Mittelschrift-Bold;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.15em;
  text-align: center;
  color: $greyTextColor;

  &Wide {
    padding-top: 10px;
    font-size: 16px;
  }

  &Compact {
    font-size: 12px;
  }

  &Red {
    color: $mainRedColor;
  }

  &Blue {
    color: $mainBlueColor;
  }
}

.teamLogo {
  object-fit: contain;

  &Wide {
    width: 36px;
    height: 36px;
  }

  &Compact {
    width: 24px;
    height: 24px;
  }
}

.modal {
  :global {
    .ant-modal-content {
      padding: 0;
    }
  }
}

@media screen and (max-width: 900px) {
  .smallMobileCategory {
    font-size: 12px;
    text-align: center;
  }
}
