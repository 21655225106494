@import "/src/variables.scss";

.th {
  padding: 0;

  .thBackground {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 30px;
    margin: 5px;
    border-radius: 5px;
    text-align: center;
  }

  .thBackgroundDark {
    background: $darkBlue2;
    color: $whiteColor;
  }

  .thBackgroundLight {
    background: $whiteColor;
    color: $mainBlueColor;
  }
}
