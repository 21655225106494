@import '/src/variables.scss';

.gameDateContainer {
  display: block;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 40px;
  right: 40px;
  padding-top: 20px;
}

.gameLogoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: 70px;
}

.containerButton {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: 20px;
}

.widgetBtn {
  height: 34.11px;
  border-radius: 5px;
  outline: none !important;
  background-color: $mainRedColor;
  border: none;
  color: #fff;
  font-family: Loko_Lato-Heavy;
  font-weight: 800;
  font-size: 14px;
  line-height: 16.8px;
  position: relative;
}

.alignBtn {
  width: 196px;
  height: 35px;
  padding-left: 10%;
  padding-right: 10%;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.widgetBtnBuy {
  color: #fff;
  font-family: Loko_Lato-Heavy;
  font-weight: 800;
  font-size: 10.84px;
  line-height: 13.01px;
  margin-left: 10px;
}
.gameDateText {
  font-family: Loko_Lato-Black;
  font-weight: 900;
  font-size: 14px;
  text-align: center;
  line-height: 16.8px;
  color: $blackColor;

  &Today {
    color: $whiteColor;
  }
}

.gameDateTimeText {
  font-family: Loko_Lato-Black;
  font-weight: 800;
  font-size: 14px;
  text-align: center;
  line-height: 16.8px;
  color: $blackColor;

  &Today {
    color: $whiteColor;
  }
}

.logo {
  margin-left: 3px;
  margin-right: 3px;
  width: 35px;
  height: 35px;
}
