@import '/src/variables.scss';

.mainNewsMobileWrapper {
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  background-color: white;

  .mainNewsFirstLine {
    color: $darkGrey;
    font-family: Loko_Lato-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .mainNewsSecondLine {
    color: $blackColor;
    font-family: L_Halvar_Mittelschrift-Bold;
    font-size: 35px;
    font-style: normal;
    line-height: 110%; /* 38.5px */

    margin-top: 20px;
    margin-bottom: 30px;
  }

  .linkText {
    width: 100%;
    height: 50px;
    border-radius: 5px 0px 0px 5px;

    border-top: 2.5px solid $strokeGreyColor;
    border-bottom: 2.5px solid $strokeGreyColor;
    border-left: 2.5px solid $strokeGreyColor;
    font-family: Loko_Lato-Bold;

    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    display: flex;
    align-items: center;
    justify-content: center;
    color: $mainRedColor;
  }

  .linkArrow {
    height: 50px;
    width: 50px;
    background-color: $whiteColor;
    border-radius: 0px 5px 5px 0px;
    border: 2.5px solid $strokeGreyColor;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 360px) {
  .mainNewsMobileWrapper {
    margin-bottom: 10px;
    .mainNewsSecondLine {
      font-size: 32px;
      overflow: hidden;
    }
  }
}
