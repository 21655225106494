@import '/src/variables.scss';

.ticketsWrapper {
  position: relative;
  user-select: none;

  font-family: L_Halvar_Mittelschrift-Medium;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  .ticketsButton {
    background-color: $whiteColor;

    .ticketsButtonText {
      color: $mainRedColor;
    }
  }

  .ticketsButtonDrawer {
    background-color: $mainRedColor;

    .ticketsButtonTextDrawer {
      color: $whiteColor;
    }
  }

  .ticketsDropdown {
    position: absolute;
    left: 0;
    z-index: 100;
    box-shadow: 2px 8px 20px 0px #00000040;
    padding: 20px;

    .ticketsDropdownItemText {
      font-family: Loko_Lato-Medium;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
    }
  }

  .ticketsDropdownHeader {
    border-radius: 0px 0px 5px 5px;
    background-color: $whiteColor;
    top: 55px;

    .ticketsDropdownItemTextHeader {
      color: $blackColor;
    }
  }

  .ticketsDropdownDrawer {
    border-radius: 5px 5px 0px 0px;
    background-color: $mainRedColor;
    bottom: 73px;

    .ticketsDropdownItemTextDrawer {
      color: $whiteColor;
    }
  }
}
