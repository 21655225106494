@import '/src/variables.scss';

.headerItem {
  border-bottom: 1px solid $strokeGreyColor;
  padding-bottom: 10px;
  user-select: none;

  .headerText {
    font-family: Loko_Lato-Bold;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }

  .headerTextSocials {
    color: $mainRedColor;
    font-family: Loko_Lato-Bold;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .headerTextOpen {
    color: $mainRedColor;
  }

  .headerTextClosed {
    color: $blackColor;
  }
}

.itemText {
  font-family: Loko_Lato-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: $blackColor;

  &:active,
  &:hover,
  &:focus {
    color: $mainRedColor;
  }
}
