@import '/src/variables.scss';

.button {
  width: 100%;
  max-width: 369px;
  border-radius: 8px;
  background: $mainRedColor;
  border: 0;
  padding: 10px 40px;
  height: 50px;

  color: $whiteColor;
  font-family: Loko_Lato-Bold;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;

  .inputWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .span {
      color: $darkGreyText;
      font-family: Loko_Lato-Regular;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .nameInput {
      :global {
        .ant-input {
          padding: 10px 20px;
          align-items: center;
          border-radius: 10px;
          border: 1px solid $strokeGreyColor;

          color: #000;

          font-family: Loko_Lato-Regular;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
    .passwordInput {
      display: flex;
      flex-direction: column;
      gap: 10px;

      :global {
        .ant-input-affix-wrapper {
          padding: 10px 20px;
          align-items: center;
          border-radius: 10px;
          border: 1px solid $strokeGreyColor;
        }

        .ant-input {
          color: #000;

          font-family: Loko_Lato-Regular;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .forgetPassword {
        color: $mainBlueColor;
        font-family: Loko_Lato-Regular;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        cursor: pointer;
      }
    }
  }
}
