@import '/src/variables.scss';

.notFound {
  margin-bottom: 30px;
  color: $whiteColor;
  font-family: L_Halvar_Mittelschrift-Bold;
  font-size: 25px;
  font-style: normal;
  line-height: 1.1;
}
