.dataWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0 11px;
}

.descriptionBlockWrapper {
  padding: 30px 0;
}

.descriptionText {
  color: white;

  p {
    margin-block-start: 0;
    margin-block-end: 0;
  }
}

.lokoTableHeadCell {
  font-family: L_Halvar_Mittelschrift-Bold;
  font-size: 18px;
  line-height: 1.158;
}
