@font-face {
    font-family: L_Halvar_Mittelschrift-Bold;
    src: url('./assets/fonts/L_Halvar_Mittelschrift-Bold.ttf');
    font-weight: 700;
}

@font-face {
    font-family: Loko_Lato-Regular;
    src: url('./assets/fonts/Loko_Lato-Regular.ttf');
    font-weight: 400;
}

@font-face {
    font-family: L_Halvar_Mittelschrift-Medium;
    src: url('./assets/fonts/L_Halvar_Mittelschrift-Medium.ttf');
    font-weight: 500;
}

@font-face {
    font-family: L_Halvar_Mittelschrift-Regular;
    src: url('./assets/fonts/L_Halvar_Mittelschrift-Regular.ttf');
    font-weight: 400;
}

@font-face {
    font-family: Loko_Lato-Bold;
    src: url('./assets/fonts/Loko_Lato-Bold.ttf');
    font-weight: 700;
}

@font-face {
    font-family: Loko_Lato-Regular;
    src: url('./assets/fonts/Loko_Lato-Regular.ttf');
    font-weight: 400;
}

@font-face {
    font-family: Loko_Lato-Light;
    src: url('./assets/fonts/Loko_Lato-Light.ttf');
    font-weight: 300;
}

@font-face {
    font-family: Loko_Lato-Medium;
    src: url('./assets/fonts/Loko_Lato-Medium.ttf');
    font-weight: 500;
}

@font-face {
    font-family: hc_Lokomotiv;
    src: url('./assets/fonts/2018_hc_Lokomotiv.otf');
}

@font-face{
    font-family:"Loko_Lato-Semibold";
    src:url("./assets/fonts/Loko_Lato-Semibold.ttf");
    font-weight:600;
}

@font-face{
    font-family:"Loko_Lato-Regular";
    src:url("./assets/fonts/Loko_Lato-Regular.ttf");
    font-weight:400;
}

@font-face{
    font-family:"Loko_Lato-Heavy";
    src:url("./assets/fonts/Loko_Lato-Heavy.ttf");
    font-weight:900;
}

@font-face{
    font-family:"Loko_Lato-Black";
    src:url("./assets/fonts/Loko_Lato-Black.ttf");
    font-weight:900;
}

.custom-partners-image {
    height: 100px;
}

.custom-topstory {
    height: 460px;
}

.custom-translate3d {
    transform: translate3d(0px, 4px, 0px);
}

.custom-translate3d-article-img {
    transform: translate3d(0px, 4px, 0px);
}

.custom-top-partners-row {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    overflow: auto;
}

.custom-top-partners a img {
    display: block;
    border: none;
}

.custom-top-partners-row .custom-top-partners {
    margin-left: 20px;
    margin-right: 20px;
    padding-bottom: 30px;
}

.custom-pg0 {
    padding: 0;
}

.custom-mg0 {
    margin: 0;
}

.custom-logo-widget-container {
    left: 0px;
}

.nav-link-without-background {
    background-image: none;
}

.change-language {
    border: 1px solid #464646;
}

.custom-iframe-facebook {
    border: none;
    overflow: hidden;
    height: 63px;
    width: 52px;
}

.custom-iframe-facebook-share {
    border: none;
    overflow: hidden;
    height: 24px;
    width: 130px;
}

.custom-iframe-twitter {
    width: 62px;
    height: 65px;
    margin-left: -2px;
}

.custom-iframe-twitter-share {
    width: 100px;
    height: 20px;
}

.custom-display-block {
    display: block;
}

.custom-container {
    position: relative;
}

.calendar-slick .custom-slick-day {
    width: calc((100vw - 108px - 108px)/10);
}

.standings tbody tr th a:not([href]):not([tabindex]),
.roster tbody tr th a:not([href]):not([tabindex]) {
    text-decoration: none;
    color: #cf072c;
}

.standings tbody tr th a:not([href]):not([tabindex]):hover,
.roster tbody tr th a:not([href]):not([tabindex]):hover {
    text-decoration: underline;
    color: #cf072c;
}

.registration {
    padding: 0 16px;
}

.registration input[type=checkbox] {
    margin-right: 8px;
}

.registration label {
    display: block;
    width: 100%;
}

.agreement a {
    color: #cf072c;
}

.custom-categories-block {
    margin-bottom: 25px;
}

.custom-login-block {
    margin-bottom: 75px;
}

.custom_comment_right {
    margin-bottom: 25px;
}

.text .box.photogallery div.photo {
    width: 33.33333%;
    height: 197px;
    float: left;
    background: #ededed no-repeat center 25%;
    background-size: cover;
    position: relative;
    overflow: hidden
}

@media screen and (max-width:1229px) {
    .text .box.photogallery div.photo {
        width: 25%
    }
}

@media screen and (max-width:1180px) {
    .text .box.photogallery div.photo {
        width: 33.33333%
    }
}

@media screen and (max-width:780px) {
    .text .box.photogallery div.photo {
        width: 50%
    }
}

@media screen and (max-width:550px) {
    .text .box.photogallery div.photo {
        width: 100%
    }
}

.text .box.photogallery div.photo.video {
    background-position: center center;
    background-size: 120% auto
}

.text .box.photogallery div.photo h2 {
    z-index: 2;
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    padding: 50px 10px 10px;
    text-transform: uppercase;
    font-size: 13px;
    color: white;
    background: linear-gradient(to bottom, rgba(9, 22, 45, 0) 11%, rgba(9, 22, 45, 0.83) 71%, #081328 96%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5209162d', endColorstr='#081328', GradientType=0);
}

.text .box.photogallery div.photo span {
    z-index: 3;
    position: relative;
    display: block;
    height: 100%;
    background: url("./assets/expand.png") no-repeat center center;
    background: rgba(207, 7, 44, 0.85) url("./assets/expand.png") no-repeat center center;
    opacity: 0;
    transition: opacity 0.2s ease-in-out
}

.text .box.photogallery div.photo span h2 {
    background: none
}

.text .box.photogallery div.photo:hover span {
    opacity: 1
}

.pos-center {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
}

.loader {
    border-top: 10px solid #cf072ca1;
    border-right: 10px solid #cf072ca1;
    border-bottom: 10px solid #f3f3f3;
    border-left: 10px solid #f3f3f3;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.custom-calendar {
    background-color: #C8102E;
}

.custom-home {
    width: 150px;
    height: 150px;
    border-radius: 8px;
    border: none;
    box-sizing: border-box;
    border-top: solid 3px #fff;
    border-left: solid 3px #fff;
    background: linear-gradient(-62deg, #fff 0% 74.8%, #C8102E 75% 100%);
    color: #C8102E;
}

.custom-away {
    width: 150px;
    height: 150px;
    border-radius: 8px;
    border-top: solid 3px #fff;
    border-left: solid 3px #fff;
    background: linear-gradient(-62deg, #fff 0% 74.8%, #00256D 75% 100%);
    color: #00256D;
}

.custom-logo {
    width: 40px;
    height: 40px;
}

.custom-container {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
}

.custom-btn {
    width: 10px;
}

.info_wrapper {
    padding-left: 15%;
    padding-right: 15%;
    position: relative;
    z-index: 2;
    background-color: #00256D;

    &-inner {
        background-color: #F1F1F2;
        border-radius: 10px 10px 0px 0px;

        &_padding {
            padding: 30px 20px;
        }
    }
}

.custom-option {
    color: #000;
}

.custom-select_option {
    background-color: #C8102E;
    color: #fff;

    &-item {
        background-color: #C8102E;
        color: #fff;
        display: block;
    }
}

.custom-select {
    display: inline-block;
    color: #000;
    width: 200px;
    background-color: #ededed;
    cursor: pointer;
}

.select_custom {
    display: inline;
}

.calendar_header {
    position: relative;
    display: flex;
    align-items: center;
    vertical-align: middle;
    margin-bottom: 30px;
}

.previos {
    border-left: none;
}

.disabledElement {
    opacity: 0.3;
    cursor: default;
}

.s_khl_menu_n_container .s_khl_menu_n_leagues .s_khl_menu_khl_ic {
    background: url(https://www.khl.ru/local/templates/khl-redesign/img/logo/2023/logo_KHL_site_RU.svg) center no-repeat !important;
    background-size: contain !important;
}
