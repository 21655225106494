@import '/src/variables.scss';

.hamburgerWrapper {
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $darkRedColor;
  user-select: none;
}
