@import '/src/variables.scss';

:global {
  video {
    width: inherit;
  }
}

.tabBar {
  display: flex;
  gap: 40px;
  padding: 40px 20px 0px 360px;
  position: relative;
  z-index: 2;

  @media screen and (max-width: 1200px) {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
}

.tabBar p {
  font-family: Loko_Lato-Regular;
  font-weight: 400;
  font-size: 16px;
  color: $whiteColor;
  line-height: 1.2;
  position: relative;
  display: inline-block;
  padding-bottom: 12px;
  margin-bottom: 10px;
  cursor: pointer;

  @media screen and (max-width: 1200px) {
    text-align: center;
  }
}

.tabBar p.active,
.tabBar p:active,
.tabBar p:focus,
.tabBar p:hover {
  color: $whiteColor !important;
  font-family: Loko_Lato-Bold;
  font-weight: 800;
  text-decoration: none;
}

.tabBar p.active:after,
.tabBar p:active:after,
.tabBar p:focus:after,
.tabBar p:hover:after {
  opacity: 1;
  color: $whiteColor !important;
}

.contentWrapper {
  position: relative;
  min-height: 45vh;

  .title {
    font-family: L_Halvar_Mittelschrift-Bold;
    font-size: 52px;
    font-style: normal;
    line-height: normal;
    text-transform: uppercase;
    color: $whiteColor;
    z-index: 2;
    position: relative;

    @media screen and (max-width: 1550px) {
      font-size: 40px;
    }
    @media screen and (max-width: 1200px) {
      text-align: center;
      padding-top: 30px;
    }
  }
}

.arena {
  position: absolute;
  z-index: 1;
}

.overlayArena {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(5, 33, 72, 0) 0%,
    rgba(4, 32, 70, 0.78) 100%
  );
  z-index: 2;
}

.pointerEventsNone {
  pointer-events: none;
}

.article {
  position: relative;
  z-index: 5;
  width: 100%;
  height: 100%;
  display: block;
  padding-bottom: 10px;

  .text {
    margin-bottom: 30px;
    font-family: Loko_Lato-Regular;
    font-weight: 400;
  }

  .textDecreased {
    display: '-webkit-box';
    -webkit-box-orient: 'vertical';
    -webkit-line-clamp: 5;
    overflow: 'hidden';
  }

  .container {
    margin-top: 60px;
    padding-left: 15%;
  }

  .mobileTitle {
    font-size: 40px;
  }

  .mobileContainer {
    padding: 0 20px;
    margin-bottom: 30px;
  }

  .arenaContainer {
    width: 100%;
    position: relative;
    z-index: 20;
    margin: 0;
    height: 100%;
    min-height: 110vh;

    @media screen and (max-width: 1024px) {
      padding-left: 0;
      display: flex;
      justify-content: center;
    }
  }

  .icon {
    position: absolute;
    height: 45px;
    z-index: 30;
  }

  .arenaTodayContainer {
    display: flex;
    margin-top: 0;
    margin-bottom: 120px;
  }

  .contentContainer {
    text-align: justify;
    padding-top: 200px;
    margin-right: 60px;

    .content {
      font-family: Loko_Lato-Regular;
      font-weight: 400;
      font-size: 22px;
      font-style: normal;
      color: $whiteColor;
      line-height: 1.7;
      margin-bottom: 30px;
      @media screen and (max-width: 1550px) {
        font-size: 18px;
      }
      @media screen and (max-width: 1024px) {
        font-size: 14px;
      }
    }

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  .arenaContentContainer {
    padding-top: 40px;
    position: absolute;
    text-align: left;
    top: 0;

    .content {
      margin-top: 33px;
      margin-bottom: 20px;
    }

    @media screen and (max-width: 1550px) {
      padding: 0 20px 0 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      top: 0;

      .content {
        margin-top: 0;
        text-align: justify;
        align-self: center;
      }
    }

    @media screen and (max-width: 1024px) {
      padding: 0 20px;
      margin: 0;
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;

      .content {
        margin-top: 0;
        text-align: justify;
        align-self: center;
      }
    }

    @media screen and (max-width: 768px) {
      width: calc(95% - 30px);
      padding: 0;
    }
  }

  .carouselContainer {
    width: 60%;

    .arenaTodayGalleryTwoSlides {
      :global {
        .slick-current {
          width: 500px !important;
          @media screen and (min-width: 1240px) and (max-width: 1560px) {
            width: 350px !important;
          }
        }

        .slick-arrow {
          right: 45%;
          transform: translateX(-45%);
        }

        .slick-prev {
          right: 50%;
          transform: translateX(-50%);
        }
      }
    }

    .arenaTodayGallery {
      :global {
        .slick-list {
          position: relative;
          height: 100% !important;
        }

        .slick-arrow {
          position: absolute;
          z-index: 10;
          bottom: 0;
          right: 35%;
          transform: translateX(-35%);
          filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.8));

          @media screen and (min-width: 1600px) and (max-width: 1800px) {
            right: 30%;
            transform: translateX(-30%);
          }

          @media screen and (min-width: 1560px) and (max-width: 1600px) {
            right: 25%;
            transform: translateX(-25%);
          }

          @media screen and (min-width: 1500px) and (max-width: 1560px) {
            right: 35%;
            transform: translateX(-35%);
          }

          @media screen and (min-width: 1240px) and (max-width: 1400px) {
            right: 24%;
            transform: translateX(-24%);
          }

          @media screen and (min-width: 1200px) and (max-width: 1240px) {
            right: 20%;
            transform: translateX(-20%);
          }
        }

        .slick-prev {
          right: 40%;
          transform: translateX(-40%);

          @media screen and (min-width: 1600px) and (max-width: 1800px) {
            right: 38%;
            transform: translateX(-38%);
          }

          @media screen and (min-width: 1560px) and (max-width: 1600px) {
            right: 30%;
            transform: translateX(-30%);
          }

          @media screen and (min-width: 1500px) and (max-width: 1560px) {
            right: 40%;
            transform: translateX(-40%);
          }

          @media screen and (min-width: 1240px) and (max-width: 1400px) {
            right: 32%;
            transform: translateX(-32%);
          }

          @media screen and (min-width: 1200px) and (max-width: 1240px) {
            right: 28%;
            transform: translateX(-28%);
          }
        }

        .slick-current {
          height: 800px !important;
          padding-right: 20px;
        }

        .slick-slide {
          display: flex;
          flex-direction: column;
          height: 700px;
          div {
            height: 100%;
            z-index: 2;

            img,
            video {
              border-radius: 10px;
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }

  .arenaTodayContainerMobile {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    .contentContainer {
      margin-bottom: 30px;
      margin-right: 0;
      padding-top: 0;

      .title {
        text-align: center;
      }
    }

    .carouselContainer {
      width: 90%;
    }

    .mobileCarousel {
      display: flex;
      position: relative;
      width: 100%;
      height: 100%;

      :global {
        .slick-arrow {
          top: 50%;
          position: absolute;
          transform: translateY(-50%);
          z-index: 999;
        }

        .slick-list {
          width: 100%;
        }

        .slick-next {
          right: 0;
        }

        .slick-slide {
          display: flex;
          justify-content: center;
          div {
            width: 100%;
            height: 100%;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}

.content {
  white-space: pre-wrap;
}

.bio {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.svgIsBackground {
  position: absolute;
}

@media screen and (max-width: 1600px) {
  .article {
    .container {
      padding-left: 10%;
    }
  }
}

@media screen and (max-width: 1320px) {
  .arena {
    position: absolute;
    width: 100%;
    z-index: 1;
    object-fit: cover;
  }
}

@media screen and (max-width: 1200px) {
  .tabBar,
  .article {
    padding-left: 16px;
    padding-right: 16px;
    .container {
      padding-left: 5%;
    }
  }
}

@media screen and (max-width: 768px) {
  .article {
    .container {
      padding-left: 0;
      min-height: 60vh;
    }
  }
}

.outer {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}

.videoItem {
  video {
    width: 100%;
    max-height: calc(100vh - 102px);
  }
  width: 100%;
  max-height: calc(100vh - 102px);
}
