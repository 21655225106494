@import '/src/variables.scss';

.wrapper {
  position: relative;
  z-index: 3;
  overflow: hidden;

  .background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: (-1);
    background-color: $mainBlueColor;
    width: 100%;
    height: 100%;

    .grid {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;

      .img {
        grid-area: 1/1/2/2;
        width: 100%;
        height: 99%;
        object-fit: cover;
      }

      .gradient {
        grid-area: 1/1/2/2;
      }
    }
  }
}
