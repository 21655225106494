.center {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}

.loader {
  border-top: 10px solid #cf072ca1;
  border-right: 10px solid #cf072ca1;
  border-bottom: 10px solid #f3f3f3;
  border-left: 10px solid #f3f3f3;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(360deg);
  }
}
