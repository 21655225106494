@import '/src/variables.scss';

.wrapper {
  flex: 4;
  display: flex;
  flex-direction: column;
}

.shopRow {
  flex: 1;
  display: flex;
  gap: 15px;
  min-height: 60px;
  margin-bottom: 15px;
}

.shopColumn {
  flex: 1;
  width: 100%;
  background-color: $mainBlueColor;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ticketsColumn {
  flex: 1;
  width: 100%;
  background-color: $mainRedColor;
  display: flex;
  align-items: center;
  justify-content: center;
}

.academyRow {
  display: flex;
  align-items: center;
  flex: 1;
  min-height: 60px;
  background-color: $footerSocialNetworkscolor;
}

.academy {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  font-family: Loko_Lato-Bold;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0em;
  text-align: left;
  color: $whiteColor;
  text-transform: uppercase;
  margin-left: 10px;
}
