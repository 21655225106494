@import '/src/variables.scss';

.widgetWrapper {
  padding: 20px;
  border-radius: 10px;
  border: solid 2px $strokeGreyColor;
  background-color: $whiteColor;
  width: 100%;
  align-items: center;
  vertical-align: middle;
  position: relative;
  min-height: 296px;

  &Today {
    border: none;
    background-color: $mainBlueColor;
    color: $whiteColor;
  }
}

.headerMatch {
  font-family: Loko_Lato-Heavy;
  font-weight: 800;
  font-size: 24px;
  text-align: center;
  line-height: 1.2;
  color: $blackColor;
  margin-bottom: 10px;

  &Today {
    color: $whiteColor;
  }
}

.headerDate {
  font-family: Loko_Lato-Regular;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  text-align: center;
  margin-bottom: 10px;
  color: $blackColor;
}

.headerDateCurrent {
  font-family: Loko_Lato-Regular;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  text-align: center;
  margin-bottom: 12px;
  color: $whiteColor;
}

.widget {
  &Teams {
    margin-bottom: 5px;
    display: grid;
    grid-template-columns: repeat(3, calc(33.3% - 13.3px));
    grid-template-rows: 1fr;
    gap: 20px;
  }

  &Btn {
    height: 34.11px;
    border-radius: 5px;
    outline: none !important;
    background-color: $mainRedColor;
    border: none;
    color: $whiteColor;
    font-family: Loko_Lato-Heavy;
    font-weight: 800;
    font-size: 14px;
    line-height: 1.2;
    position: relative;

    &:active,
    &:focus {
      outline: none !important;
    }

    &Wrapper {
      text-align: center;
      vertical-align: middle;
    }

    &Buy,
    &Next {
      color: $whiteColor;
      font-family: Loko_Lato-Heavy;
      font-weight: 800;
      font-size: 14px;
      line-height: 1.2;
      margin-left: 10px;
    }

    &Next {
      color: $darkGrey;
    }

    &Watch {
      color: $whiteColor;
      font-family: Loko_Lato-Heavy;
      font-weight: 800;
      font-size: 14px;
      line-height: 1.2;
      text-align: center;
    }
  }
}

.wrapperBtnLastGame {
  display: flex;
  justify-content: center;
  gap: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.btnLastGame {
  width: 50%;
  left: 0;
  right: 0;
}

.btnProtocol {
  background-color: $articleContentBackground;
  color: $darkGrey;
}

.alignBtnProtocol {
  align-self: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.alignBtnWatch {
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 70px;
  height: 70px;
  flex: 5;
}

.alignBtn {
  width: 196px;
  height: 35px;
  padding-left: 10%;
  padding-right: 10%;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: 20px;
}

.btnText {
  white-space: nowrap;
}

.coefficientsContainer {
  &Teams {
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 20px;
  }

  &Btn {
    height: 34.11px;
    border-radius: 5px;
    outline: none !important;
    background-color: $mainRedColor;
    border: none;
    color: $whiteColor;
    font-family: Loko_Lato-Heavy;
    font-weight: 800;
    font-size: 14px;
    line-height: 1.2;
    position: relative;

    &:active,
    &:focus {
      outline: none !important;
    }

    &Wrapper {
      text-align: center;
      vertical-align: middle;
    }

    &Buy,
    &Next {
      color: $whiteColor;
      font-family: Loko_Lato-Heavy;
      font-weight: 800;
      font-size: 14px;
      line-height: 1.2;
      margin-left: 10px;
    }

    &Next {
      color: $darkGrey;
    }

    &Watch {
      color: $whiteColor;
      font-family: Loko_Lato-Heavy;
      font-weight: 800;
      font-size: 14px;
      line-height: 1.2;
      text-align: center;
    }
  }
}

.wrapperBtnLastGame {
  display: flex;
  justify-content: center;
  gap: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.btnLastGame {
  width: 50%;
  left: 0;
  right: 0;
}

.alignBtnProtocol {
  align-self: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.alignBtnWatch {
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 70px;
  height: 70px;
  margin-bottom: 8px;
}

.alignBtn {
  width: 196px;
  height: 35px;
  padding-left: 0;
  padding-right: 0;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: 20px;
}

.btnText {
  white-space: nowrap;
}

.coefficientsContainer {
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 20px;
  justify-content: center;
}

.coefficientBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $mainRedColor;
  color: $whiteColor;
  padding: 2px;
  border-radius: 25px;
  font-size: 12px;
  gap: 5px;
  p {
    padding: 0;
    margin: 0;
  }
}

.coefficientTitle {
  opacity: 0.5;
  font-style: italic;
}

.disabled {
  pointer-events: none;
  opacity: 0.3;
  cursor: default;
}

@media screen and (max-width: 1000px) {
  .coefficientsContainerBtn,
  .widgetBtn,
  .alignBtn {
    height: 30px;
  }
}

@media screen and (max-width: 860px) {
  .widgetBtnWatch,
  .widgetBtnNext {
    font-size: 13px;
  }
}

@media screen and (max-width: 400) {
  .widgetWrapper {
    padding: 16px;
    min-height: 292px;
  }
}

@media screen and (max-width: 360px) {
  .widgetWrapper {
    padding: 16px;
    min-height: 240px;
  }
  .headerMatch {
    font-size: 19px;
    margin-bottom: 7.74px;
  }
  .headerDate,
  .headerDateCurrent {
    font-size: 11px;
    margin-bottom: 15.5px;
  }
  .logo {
    width: 54px;
    height: 54px;
    flex: 5;
    margin-bottom: 6.19px;
  }
  .alignBtnProtocol {
    width: auto;
    min-width: 50%;
  }
  .widgetBtnWatch,
  .widgetBtnNext {
    font-size: 11px;
  }
  .wrapperBtnLastGame {
    gap: 15px;
    padding-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;
  }
}
