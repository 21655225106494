@import '/src/variables.scss';

.mediaTabs {
  display: flex;
  gap: 7.74px;
}

.mediaTab {
  width: 180px;
  height: 40px;
  border-radius: 5px;
  border: none;
  outline: none !important;
  padding: 6px 19px;
  background-color: $strokeGreyColor;
  font-family: Loko_Lato-Regular;
  font-weight: 400;
  font-size: 16px;
  line-height: 19.2px;
  color: $notActiveTab;
  cursor: pointer;

  &Active {
    background-color: $mainRedColor;
    color: $whiteColor;
    font-family: Loko_Lato-Bold;
  }
}
