@import '/src/variables.scss';

.wrapper {
  background-color: $footerSocialNetworkscolor;
  margin-bottom: 17px;
  padding: 15px 16px;
}

.text {
  font-family: Loko_Lato-SemiBold;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2;
  color: $footerSocialTextColor;
  text-transform: uppercase;
}

.socialIcons {
  display: flex;
  justify-content: space-between;
  width: 248.16px;
  align-items: center;

  a {
    display: flex;
  }
}
