@import '/src/variables.scss';

.innerWrapper {
  padding: 0px 20px;
  :global(.image-gallery-content .image-gallery-slide .image-gallery-image) {
    max-height: calc(100vh - 100px) !important;
  }
}

.headerWrapper {
  display: flex;
  align-items: center;
  padding-top: 197px;
  color: $whiteColor;

  .back {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding-bottom: 50px;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }

    .backText {
      margin-right: 50px;
      font-size: 16px;
    }
  }

  h1 {
    padding-top: 0;
  }
}

@media screen and (max-width: 1320px) {
  .headerWrapper {
    flex-wrap: wrap;
    padding-top: 50px;
    padding-left: 16px;
  }
}
