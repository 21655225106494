@import '/src/variables.scss';

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20px;
  background-color: $whiteColor;
  outline: none !important;
  border: none;
}

.text {
  font-family: Loko_Lato-Regular;
  font-weight: 400;
  color: $darkGrey;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0em;
  text-align: left;
}
