@import '/src/variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 40px;

  .actionButton {
    padding: 8px 24px;
    border-radius: 8px;
    border: 0;
    background: $mainRedColor;
    color: $whiteColor;
    font-family: Loko_Lato-Bold;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }

  .actionButton:disabled {
    opacity: 0.3;
  }

  .title {
    color: $blackColor;
    font-family: L_Halvar_Mittelschrift-Bold;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .inputsBlock {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .inputWrapper {
      display: flex;
      flex-direction: column;
      gap: 5px;
      max-width: 500px;

      .span {
        color: $darkGreyText;
        font-family: Loko_Lato-Regular;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .passwordInput {
        display: flex;
        flex-direction: column;

        :global {
          .ant-input-affix-wrapper {
            padding: 10px 20px;
            align-items: center;
            border-radius: 10px;
            border: 1px solid $strokeGreyColor;
          }

          .ant-input {
            color: #000;

            font-family: Loko_Lato-Regular;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        .passwordsShouldMatch {
          padding-left: 10px;
          padding-top: 3px;
          color: $mainRedColor;
          font-family: Loko_Lato-Regular;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}
