@import '/src/variables.scss';

.tabBarMobile {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;
  margin-bottom: 20px;
}

.tabBar a {
  font-family: Loko_Lato-Regular;
  font-weight: 400;
  font-size: 18px;
  color: $darkGreyText !important;
  line-height: 21.6px;
  margin-right: 60px;
  position: relative;
  display: inline-block;
  padding-bottom: 12px;
  display: inline-block;
}

.tabBar a:after {
  content: '';
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: $mainRedColor;
  opacity: 0;
  transition: opacity 0.2s;
}

.tabBar a.active,
.tabBar a:active,
.tabBar a:focus,
.tabBar a:hover {
  color: $mainRedColor !important;
  font-family: Loko_Lato-Bold;
  font-weight: 800;
  text-decoration: none;
  display: inline-block;
}

.tabBar a.active:after,
.tabBar a:active:after,
.tabBar a:focus:after,
.tabBar a:hover:after {
  opacity: 1;
  color: $mainRedColor !important;
}

.tabBarMobile a {
  margin: 0;
}

.articleText {
  position: relative;
}

.text {
  display: none;
}

.textShow {
  display: block;
}

.mediaTabs {
  display: flex;
  gap: 7.74px;
}

.dateWrapper {
  padding-top: 20px;
  margin-left: 10px;

  .date {
    color: #000;
    font-family: Loko_Lato-Bold;
    font-size: 18px;
    font-style: normal;
  }
}

.liveBroadcastFeedContainer {
  margin-top: 20px;
  h4 {
    margin-bottom: 20px;
  }
}

.broadcastContainer {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  background-color: $whiteColor;

  .iframeWrapper {
    width: 100%;
    height: 500px;

    iframe {
      border: none;
      border-radius: 10px 10px 0px 0px;
    }
  }

  .broadCastInfoWrapper {
    margin-top: 30px;
    padding-left: 20px;
    padding-right: 20px;

    p {
      font-family: L_Halvar_Mittelschrift-Bold;
      font-size: 35px;
      font-style: normal;
      line-height: normal;
    }
  }
}
