@import '/src/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 191px;
}

.headerText {
  font-family: Loko_Lato-Bold;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0em;
  text-align: left;
  color: $whiteColor;
  display: block;
  margin-bottom: 20px;
}

.linkText {
  font-family: Loko_Lato-Regular;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0em;
  text-align: left;
  color: $greyTextColor;
}

.linkText:hover {
  color: $whiteColor;
}

.collapsibleSection {
  .collapsible {
    border: none;

    .collapsiblePanel {
      border-radius: 0;
      border-bottom: 1px solid $footerBorderColor;
      margin: 0 40px;

      .headerText {
        font-size: 14px;
        margin-bottom: 0;
      }
    }

    :global {
      .ant-collapse-item .ant-collapse-header {
        align-items: center;
        padding: 0;
        border-radius: 0;
        margin-bottom: 10px;
      }

      .ant-collapse-content {
        background-color: transparent;
        border-top: 1px solid $footerBorderColor;

        .ant-collapse-content-box {
          padding: 10px 0;
        }
      }
    }
  }
}
