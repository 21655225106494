@import '/src/variables.scss';

.wrapper {
  width: 100%;
  max-width: calc(50vw - 30px);
  display: flex;
  flex-direction: column;
  padding: 0px 0px 20px 0px;
}

.imgWrapper {
  width: 100%;
  position: relative;
  background-color: $strokeGreyColor;
  border-radius: 10px 10px 0px 0px;
}

.img {
  width: 100%;
  aspect-ratio: 275/330;
  object-fit: cover;
  border-radius: 10px 10px 0px 0px;
}

.injury {
  margin-top: 5px;
  margin-left: 5px;
  width: 40px;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.number {
  font-family: hc_Lokomotiv;
  font-size: 56px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0em;
  text-align: center;
  color: $greyTextColor;
}

.name {
  display: block;
  font-family: L_Halvar_Mittelschrift-Bold;
  font-size: 22px;
  line-height: 1.158;
  letter-spacing: 0em;
  text-align: left;
  color: $blackColor;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.nameWrapper {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px 10px;
  border-bottom: 1px solid $articleContentBackground;
  max-width: 230px;
}

.infoWrapper {
  padding: 0px 8px;
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.blockWrapper {
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .img {
    aspect-ratio: 1/1;
  }
}

@media screen and (max-width: 450px) {
  .img {
    aspect-ratio: 275/330;
  }
}

@media screen and (max-width: 360px) {
  .nameWrapper {
    gap: 10px;
  }
  .number {
    font-size: 30px;
  }
  .name {
    font-size: 16px;
  }
  .injury {
    width: 18px;
  }
}

@media screen and (max-width: 280px) {
  .injury {
    width: 15px;
    top: 5px;
    right: 5px;
  }
}
