@import '/src/variables.scss';

.errorWidget {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  .errorWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 300px;
    padding: 20px 20px 0px 20px;
    gap: 20px;
    border-radius: 5px;

    .error {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;
      gap: 10px;

      .errorTitle {
        font-family: L_Halvar_Mittelschrift-Bold;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: center;

        &LightBG {
          color: $mainBlueColor;
        }

        &DarkBG {
          color: $whiteColor;
        }
      }

      .errorSubtitle {
        color: $blackColor;
        font-family: Loko_Lato-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;

        &LightBG {
          color: $blackColor;
        }

        &DarkBG {
          color: $whiteColor;
        }
      }
    }
  }
}

.errorCompact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 2px;

  .errorTitle {
    font-family: L_Halvar_Mittelschrift-Bold;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;

    &LightBG {
      color: $mainBlueColor;
    }

    &DarkBG {
      color: $whiteColor;
    }
  }

  .errorSubtitle {
    color: $blackColor;
    font-family: Loko_Lato-Regular;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;

    &LightBG {
      color: $blackColor;
    }

    &DarkBG {
      color: $whiteColor;
    }
  }
}
