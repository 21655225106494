@import '/src/variables.scss';

.wrapper {
  max-width: 1288px;
  margin: 0 auto;
  font-family: Loko_Lato-Regular;

  .appeals {
    color: $whiteColor;
    .appealsTitle {
      font-family: Loko_Lato-Bold;
      font-size: 16px;
      font-style: normal;
      line-height: 170%;
    }
    .appealsText {
      font-family: Loko_Lato-Regular;
      font-size: 16px;
      font-style: normal;
    }
  }

  .content {
    padding: 20px 0;
  }
}

.ScrollToTopButtonWrapper {
  margin-top: 30px;
}

.colSm12 {
  position: relative;
  min-height: 1px;
  width: 100%;
  -webkit-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.colMd4 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
  -webkit-flex: 0 0 33.33333%;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

@media screen and (max-width: 1320px) {
  .wrapper {
    .appeals {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  .pageHeaderWrapper {
    padding: 0 16px;
  }
}

@media screen and (max-width: 1100px) {
  .colMd4 {
    width: 100%;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .wrapper {
    .appeals {
      max-height: 330px;
      height: 220px;
      padding: 5px 15px 10px 15px;
    }
  }
}
