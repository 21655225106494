@import '/src/variables.scss';

.bannerWrapper {
  height: 110px;
}

.scoreBanner {
  display: flex;
  gap: 10px;
  position: sticky;
  width: auto;
  z-index: 2;
  top: 67px;
  background-color: #fff;
  margin: 0 20px;
  height: 110px;
  box-shadow: 2px 8px 20px 0px #00000040;

  .leftBlock {
    display: flex;
    line-height: 0;
    overflow: hidden;

    .block {
      line-height: 0;
    }

    .line {
      margin-left: -60px;
      line-height: 0;
    }
  }

  .rightBlock {
    display: flex;
    line-height: 0;
    overflow: hidden;

    .block {
      line-height: 0;
    }

    .line {
      margin-right: -60px;
      line-height: 0;
    }
  }

  .content {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    flex: 1;

    .team {
      display: flex;
      gap: 10px;
      min-width: calc(100% / 3 - 40px);

      .textWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 4px;

        .city {
          font-size: 14px;
          line-height: 14px;
          font-weight: 500;
          font-family: Loko_Lato-Regular;
          color: $greyTextColor;
        }

        .teamName {
          font-size: 26px;
          line-height: 26px;
          font-weight: 500;
          font-family: Loko_Lato-Bold;
        }

        .shots {
          padding-top: 10px;
          font-size: 18px;
          line-height: 14px;
          font-weight: 500;
          font-family: Loko_Lato-Regular;
          color: $greyTextColor;
        }
      }

      .imageWrapper {
        padding: 10px;
        display: flex;
        align-items: center;

        .image {
          width: 75px;
          min-width: 75px;
          height: auto;
        }
      }
    }

    .score {
      display: flex;
      width: 20%;
      max-width: 330px;
      justify-content: space-between;
      gap: 10px;
      align-items: center;
      font-size: 60px;
      font-weight: 900;
      font-family: L_Halvar_Mittelschrift-Medium;
      text-align: center;

      .continuation {
        font-size: 15px;
        font-weight: 500;
        font-family: Loko_Lato-Bold;
        color: $blackColor;
      }

      .final {
        font-size: 24px;
        font-weight: 500;
        font-family: Loko_Lato-Bold;
        color: $greyTextColor;
      }
    }
  }
}

.finalWrapper {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.stuck {
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  position: fixed;
  left: 0;
}

.alignAtEnd {
  align-items: flex-end;
}

.justifyAtEnd {
  justify-content: flex-end;
}

@media screen and (max-width: 1200px) {
  .scoreBanner {
    top: 55px;

    .leftBlock {
      .block {
        margin-left: -90px;
      }
    }

    .rightBlock {
      .block {
        margin-right: -90px;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .scoreBanner {
    top: 55px;

    .leftBlock {
      .block {
        margin-left: -130px;
      }
    }

    .rightBlock {
      .block {
        margin-right: -130px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .scoreBanner {
    .content {
      .score {
        width: 50%;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .scoreBanner {
    padding: 0;
    margin: 0;

    .content {
      .team {
        .imageWrapper {
          padding: 10px 5px;
          .image {
            width: 65px;
            min-width: 65px;
            height: auto;
          }
        }
      }
      .score {
        width: 40%;
        font-size: 40px;
        .final {
          font-size: 18px;
        }
      }
    }
  }
}
