@import '/src/variables.scss';

.partners {
  margin: 30px 0;
  display: flex;
  flex-direction: column;
}

.row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin: 0;
  gap: 20px;
}

@media screen and (max-width: 860px) {
  .row {
    gap: 20px;
  }
}
