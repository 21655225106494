@import '/src/variables.scss';

.tile {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 66px;
  border-radius: 5px;
  background-color: $playoffTileBlueColor;
  position: relative;
  opacity: 0.5;
  color: $whiteColor;
  font-family: L_Halvar_Mittelschrift-Bold;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  .logo {
    width: 50px;
    height: 50px;
  }

  .left {
    margin-left: 15px;
  }

  .right {
    margin-right: 15px;
  }
}

.winner {
  background-color: $mainRedColor;
  opacity: 1;
}

.final {
  background-color: $whiteColor;
  opacity: 1;
}
