@import '/src/variables.scss';

.name {
  font-family: Loko_Lato-Bold;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 1.7;
  text-align: left;
  color: $blackColor;
  margin-bottom: 0;
}

.flexWrapperGapHidden {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 40px;
}

.flexWrapperHidden {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 20px 30px 20px;
  border-bottom: 1px solid $strokeGreyColor;
  box-sizing: border-box;
}

.description {
  align-self: center;

  p:last-child {
    margin-bottom: 0;
  }
}

.flexWrapper {
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 20px 20px 30px;
  border-bottom: 1px solid $strokeGreyColor;
  box-sizing: border-box;
}

.flexWrapperGap {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  gap: 40px;

  .name {
    margin-bottom: 30px;
  }
}

.imageWrapper {
  max-width: 240px;
}

.arrowWrapper {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
