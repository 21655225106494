@import '/src/variables.scss';

.tabBar {
  padding: 0px 20px;
  white-space: nowrap;
  overflow-x: auto;
  width: 100%;
}

.tabBar a {
  font-family: Loko_Lato-Regular;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.2;
  margin-right: 60px;
  position: relative;
  display: inline-block;
  padding-bottom: 12px;
  margin-bottom: 30px;
}

.span {
  color: $notActiveTab;
}

.tabBar a:after {
  content: '';
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: $mainRedColor;
  opacity: 0;
  transition: opacity 0.2s;
}

.tabBar a.active {
  color: $mainRedColor !important;
  font-family: Loko_Lato-Bold;
  font-weight: 800;
  text-decoration: none;
}

.tabBar a.active:after {
  opacity: 1;
  color: $mainRedColor !important;
}

@media screen and (max-width: 768px) {
  .tabBar a {
    font-size: 14px;
  }
}

main .article-text {
  position: relative;
}

main .article-text .socials {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 64px;
  text-align: center;
  width: 140px;
}

main .article-text .socials #vk_like_vertical {
  margin: 0 auto 30px;
}

@media (max-width: 1180px) {
  main .article-text .socials {
    display: none;
  }
}

main .article-text .text {
  padding: 64px 60px 68px 170px;
}

.text {
  display: none;
}

.textShow {
  display: block;
}

.articleText {
  position: relative;
  padding: 0px 20px 30px 20px;
  display: block;
  overflow-x: auto;
}

.articleText table {
  display: block;
  overflow-x: auto;
}

@media (max-width: 700px) {
  .articleText table tr td:first-child {
    padding-left: 0px;
  }

  .articleText table tr td:last-child {
    padding-right: 0px;
  }

  .text tr td:first-child,
  .text tr th:first-child {
    padding-left: 0px;
  }

  .articleText table tr th,
  .articleText table tr td {
    padding: 8px 6px 6px 0;
  }
}
