@import "/src/variables.scss";

$tableRowBlue: #002e71;

.tr {
  .td {
    padding: 0;

    .cellLeft {
      border-radius: 5px 0 0 5px;
      margin: 5px 0 5px 5px;
    }

    .cellRight {
      border-radius: 0 5px 5px 0;
      margin: 5px 5px 5px 0;
    }

    .cellRegular {
      border-radius: 0;
      margin: 5px 0 5px 0;
    }

    .cell {
      height: 60px;
      background: $tableRowBlue;
      color: $whiteColor;
      display: flex;
      flex-direction: column;
      justify-content: center;

      font-family: Loko_Lato-Regular;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .cellText {
      padding-left: 40px;
    }

    .cellNumber {
      align-items: center;
    }
  }
}
