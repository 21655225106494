@import "/src/variables.scss";

.calendar {
  padding: 20px 20px 40px 20px;

  &Wrapper {
    padding: 30px 20px 20px 20px;
    border-radius: 10px;
    background-color: $whiteColor;
  }
}

.breadcrumbs {
  margin-left: 20px;
}

.header {
  font-family: L_Halvar_Mittelschrift-Bold;
  font-size: 35px;
  font-weight: 700;
  line-height: 1.158;
  letter-spacing: 0em;
  text-align: left;
  color: $blackColor;
}

.selectWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 40px;
  margin-bottom: 20px;
}

.relative {
  position: relative;
}

.select {
  width: auto;
  height: 40px;
  padding: 0px 40px 0px 20px;
  border-radius: 8px;
  border: none;
  background: url(../../../assets/arrow_filter.svg) $strokeGreyColor center
    right 15px no-repeat;
  font-family: Loko_Lato-Medium;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: $darkGrey;
}

.monthSelect {
  width: 150px;
}

.legenda {
  font-family: Loko_Lato-Medium;
  font-weight: 500;
  font-size: 14px;
  color: $whiteColor;
  display: inline;
  margin-right: 10px;
  line-height: 16.8px;
  display: flex;
  align-items: center;

  &Wrapper {
    display: flex;
    align-items: center;
    margin-top: 30px;
  }

  &Color {
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    background-color: $mainRedColor;
  }

  &Away {
    background-color: $mainBlueColor;
  }

  &Text {
    display: block;
    color: $blackColor;
  }
}

@media screen and (max-width: 768px) {
  .calendar {
    padding: 20px 0 40px;
  }
}
