@import "/src/variables.scss";

.drawerLinks {
  padding: 30px 0;

  a {
    leading-trim: both;
    text-edge: cap;
    font-family: L_Halvar_Mittelschrift-Regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
