@import '/src/variables.scss';

.LokoTableHeadCell {
  font-family: L_Halvar_Mittelschrift-Bold;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.17;
  letter-spacing: 0em;
  color: $whiteColor;
}
