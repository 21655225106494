@import '/src/variables.scss';

.bioBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px $borderBioBoxColor;
  padding: 16px 0;
  height: 52px;

  &:first-child {
    border-top: solid 1px $borderBioBoxColor;
  }

  &Heading {
    font-family: Loko_Lato-Semibold;
    font-size: 16px;
    line-height: 1.2;
    color: $whiteColor;
    white-space: nowrap;

    &Small {
      font-family: Loko_Lato-Semibold;
      font-size: 12px;
      font-weight: 600;
      line-height: 14px;
      letter-spacing: 0em;
      text-align: left;
      color: $whiteColor;
      white-space: nowrap;
    }
  }

  &Data {
    font-family: Loko_Lato-Black;
    font-weight: 900;
    font-size: 16px;
    line-height: 1.2;
    color: $whiteColor;
    white-space: nowrap;

    &Small {
      font-family: Loko_Lato-Bold;
      font-size: 12px;
      font-weight: 900;
      line-height: 14px;
      letter-spacing: 0em;
      text-align: left;
      color: $whiteColor;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.bioBoxDataMargin {
  margin-left: 10px;
}

@media screen and (max-width: 480px) {
  .bioBox {
    height: 45px;
  }
}
