html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

progress {
  vertical-align: baseline;
}

template,
[hidden] {
  display: none;
}

a {
  background-color: transparent;
}

a:active,
a:hover {
  outline-width: 0;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: inherit;
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

mark {
  //background-color: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

button,
select,
textarea {
  font: inherit;
}

optgroup {
  font-weight: bold;
}

button,
input,
select {
  overflow: visible;
}

button,
input,
select,
textarea {
  margin: 0;
}

button,
select {
  text-transform: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  cursor: pointer;
}

[disabled] {
  cursor: default;
}

button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

button:-moz-focusring,
input:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

textarea {
  overflow: auto;
}

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
}

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

@-ms-viewport {
  width: device-width;
}

html {
  font-size: 16px;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

body {
  font-family:
    Loko_Lato-Regular,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    sans-serif;
  font-size: 1rem;
  line-height: normal;
  color: #000;
  background-color: #fff;
}

[tabindex='-1']:focus {
  outline: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  cursor: help;
  border-bottom: 1px dotted #818a91;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

a {
  color: #c8102e;
  text-decoration: none;
}

a:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: none;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
}

[role='button'] {
  cursor: pointer;
}

a,
area,
button,
[role='button'],
input,
label,
select,
summary,
textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

table {
  border-collapse: collapse;
  background-color: transparent;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #818a91;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: left;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
textarea {
  margin: 0;
  line-height: inherit;
  border-radius: 0;
}

input[type='radio']:disabled,
input[type='checkbox']:disabled {
  cursor: not-allowed;
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
}

input[type='search'] {
  -webkit-appearance: none;
}

output {
  display: inline-block;
}

[hidden] {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

h1,
.h1 {
  font-size: 2.5rem;
}

h2,
.h2 {
  font-size: 2rem;
}

h3,
.h3 {
  font-size: 1.75rem;
}

h4,
.h4 {
  font-size: 1.5rem;
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: normal;
}

mark,
.mark {
  padding: 0.2em;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 5px;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  border-left: 0.25rem solid #eceeef;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #818a91;
}

.blockquote-footer::before {
  content: '\2014 \00A0';
}

.blockquote-reverse {
  padding-right: 1rem;
  padding-left: 0;
  text-align: right;
  border-right: 0.25rem solid #eceeef;
  border-left: 0;
}

.blockquote-reverse .blockquote-footer::before {
  content: '';
}

.blockquote-reverse .blockquote-footer::after {
  content: '\00A0 \2014';
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 830px) {
  .container {
    max-width: 576px;
  }
}

@media (min-width: 900px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 940px;
  }
}

@media (min-width: 1020px) {
  .container {
    max-width: 1020px;
  }
}

.container-fluid {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
}

.col-xs {
  position: relative;
  -webkit-flex-basis: 0;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs-1 {
  -webkit-flex: 0 0 8.33333%;
  -ms-flex: 0 0 8.33333%;
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-xs-2 {
  -webkit-flex: 0 0 16.66667%;
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-xs-3 {
  -webkit-flex: 0 0 25%;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-xs-4 {
  -webkit-flex: 0 0 33.33333%;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-xs-5 {
  -webkit-flex: 0 0 41.66667%;
  -ms-flex: 0 0 41.66667%;
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-xs-6 {
  -webkit-flex: 0 0 50%;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-xs-7 {
  -webkit-flex: 0 0 58.33333%;
  -ms-flex: 0 0 58.33333%;
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-xs-8 {
  -webkit-flex: 0 0 66.66667%;
  -ms-flex: 0 0 66.66667%;
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-xs-9 {
  -webkit-flex: 0 0 75%;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-xs-10 {
  -webkit-flex: 0 0 83.33333%;
  -ms-flex: 0 0 83.33333%;
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-xs-11 {
  -webkit-flex: 0 0 91.66667%;
  -ms-flex: 0 0 91.66667%;
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-xs-12 {
  -webkit-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.pull-xs-0 {
  right: auto;
}

.pull-xs-1 {
  right: 8.33333%;
}

.pull-xs-2 {
  right: 16.66667%;
}

.pull-xs-3 {
  right: 25%;
}

.pull-xs-4 {
  right: 33.33333%;
}

.pull-xs-5 {
  right: 41.66667%;
}

.pull-xs-6 {
  right: 50%;
}

.pull-xs-7 {
  right: 58.33333%;
}

.pull-xs-8 {
  right: 66.66667%;
}

.pull-xs-9 {
  right: 75%;
}

.pull-xs-10 {
  right: 83.33333%;
}

.pull-xs-11 {
  right: 91.66667%;
}

.pull-xs-12 {
  right: 100%;
}

.push-xs-0 {
  left: auto;
}

.push-xs-1 {
  left: 8.33333%;
}

.push-xs-2 {
  left: 16.66667%;
}

.push-xs-3 {
  left: 25%;
}

.push-xs-4 {
  left: 33.33333%;
}

.push-xs-5 {
  left: 41.66667%;
}

.push-xs-6 {
  left: 50%;
}

.push-xs-7 {
  left: 58.33333%;
}

.push-xs-8 {
  left: 66.66667%;
}

.push-xs-9 {
  left: 75%;
}

.push-xs-10 {
  left: 83.33333%;
}

.push-xs-11 {
  left: 91.66667%;
}

.push-xs-12 {
  left: 100%;
}

.offset-xs-1 {
  margin-left: 8.33333%;
}

.offset-xs-2 {
  margin-left: 16.66667%;
}

.offset-xs-3 {
  margin-left: 25%;
}

.offset-xs-4 {
  margin-left: 33.33333%;
}

.offset-xs-5 {
  margin-left: 41.66667%;
}

.offset-xs-6 {
  margin-left: 50%;
}

.offset-xs-7 {
  margin-left: 58.33333%;
}

.offset-xs-8 {
  margin-left: 66.66667%;
}

.offset-xs-9 {
  margin-left: 75%;
}

.offset-xs-10 {
  margin-left: 83.33333%;
}

.offset-xs-11 {
  margin-left: 91.66667%;
}

@media (min-width: 830px) {
  .col-sm {
    position: relative;
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }

  .col-sm-1 {
    -webkit-flex: 0 0 8.33333%;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-sm-2 {
    -webkit-flex: 0 0 16.66667%;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-sm-3 {
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    -webkit-flex: 0 0 33.33333%;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-sm-5 {
    -webkit-flex: 0 0 41.66667%;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-sm-6 {
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    -webkit-flex: 0 0 58.33333%;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-sm-8 {
    -webkit-flex: 0 0 66.66667%;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-sm-9 {
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    -webkit-flex: 0 0 83.33333%;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-sm-11 {
    -webkit-flex: 0 0 91.66667%;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-sm-12 {
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .pull-sm-0 {
    right: auto;
  }

  .pull-sm-1 {
    right: 8.33333%;
  }

  .pull-sm-2 {
    right: 16.66667%;
  }

  .pull-sm-3 {
    right: 25%;
  }

  .pull-sm-4 {
    right: 33.33333%;
  }

  .pull-sm-5 {
    right: 41.66667%;
  }

  .pull-sm-6 {
    right: 50%;
  }

  .pull-sm-7 {
    right: 58.33333%;
  }

  .pull-sm-8 {
    right: 66.66667%;
  }

  .pull-sm-9 {
    right: 75%;
  }

  .pull-sm-10 {
    right: 83.33333%;
  }

  .pull-sm-11 {
    right: 91.66667%;
  }

  .pull-sm-12 {
    right: 100%;
  }

  .push-sm-0 {
    left: auto;
  }

  .push-sm-1 {
    left: 8.33333%;
  }

  .push-sm-2 {
    left: 16.66667%;
  }

  .push-sm-3 {
    left: 25%;
  }

  .push-sm-4 {
    left: 33.33333%;
  }

  .push-sm-5 {
    left: 41.66667%;
  }

  .push-sm-6 {
    left: 50%;
  }

  .push-sm-7 {
    left: 58.33333%;
  }

  .push-sm-8 {
    left: 66.66667%;
  }

  .push-sm-9 {
    left: 75%;
  }

  .push-sm-10 {
    left: 83.33333%;
  }

  .push-sm-11 {
    left: 91.66667%;
  }

  .push-sm-12 {
    left: 100%;
  }

  .offset-sm-0 {
    margin-left: 0%;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.66667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333%;
  }

  .offset-sm-5 {
    margin-left: 41.66667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333%;
  }

  .offset-sm-8 {
    margin-left: 66.66667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333%;
  }

  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 900px) {
  .col-md {
    position: relative;
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }

  .col-md-1 {
    -webkit-flex: 0 0 8.33333%;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-md-2 {
    -webkit-flex: 0 0 16.66667%;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-md-3 {
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    -webkit-flex: 0 0 33.33333%;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-md-5 {
    -webkit-flex: 0 0 41.66667%;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-md-6 {
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    -webkit-flex: 0 0 58.33333%;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-md-8 {
    -webkit-flex: 0 0 66.66667%;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-md-9 {
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    -webkit-flex: 0 0 83.33333%;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-md-11 {
    -webkit-flex: 0 0 91.66667%;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-md-12 {
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .pull-md-0 {
    right: auto;
  }

  .pull-md-1 {
    right: 8.33333%;
  }

  .pull-md-2 {
    right: 16.66667%;
  }

  .pull-md-3 {
    right: 25%;
  }

  .pull-md-4 {
    right: 33.33333%;
  }

  .pull-md-5 {
    right: 41.66667%;
  }

  .pull-md-6 {
    right: 50%;
  }

  .pull-md-7 {
    right: 58.33333%;
  }

  .pull-md-8 {
    right: 66.66667%;
  }

  .pull-md-9 {
    right: 75%;
  }

  .pull-md-10 {
    right: 83.33333%;
  }

  .pull-md-11 {
    right: 91.66667%;
  }

  .pull-md-12 {
    right: 100%;
  }

  .push-md-0 {
    left: auto;
  }

  .push-md-1 {
    left: 8.33333%;
  }

  .push-md-2 {
    left: 16.66667%;
  }

  .push-md-3 {
    left: 25%;
  }

  .push-md-4 {
    left: 33.33333%;
  }

  .push-md-5 {
    left: 41.66667%;
  }

  .push-md-6 {
    left: 50%;
  }

  .push-md-7 {
    left: 58.33333%;
  }

  .push-md-8 {
    left: 66.66667%;
  }

  .push-md-9 {
    left: 75%;
  }

  .push-md-10 {
    left: 83.33333%;
  }

  .push-md-11 {
    left: 91.66667%;
  }

  .push-md-12 {
    left: 100%;
  }

  .offset-md-0 {
    margin-left: 0%;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.66667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333%;
  }

  .offset-md-5 {
    margin-left: 41.66667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333%;
  }

  .offset-md-8 {
    margin-left: 66.66667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333%;
  }

  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    position: relative;
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }

  .col-lg-1 {
    -webkit-flex: 0 0 8.33333%;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-lg-2 {
    -webkit-flex: 0 0 16.66667%;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-lg-3 {
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    -webkit-flex: 0 0 33.33333%;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-lg-5 {
    -webkit-flex: 0 0 41.66667%;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-lg-6 {
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    -webkit-flex: 0 0 58.33333%;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-lg-8 {
    -webkit-flex: 0 0 66.66667%;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-lg-9 {
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    -webkit-flex: 0 0 83.33333%;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-lg-11 {
    -webkit-flex: 0 0 91.66667%;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-lg-12 {
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .pull-lg-0 {
    right: auto;
  }

  .pull-lg-1 {
    right: 8.33333%;
  }

  .pull-lg-2 {
    right: 16.66667%;
  }

  .pull-lg-3 {
    right: 25%;
  }

  .pull-lg-4 {
    right: 33.33333%;
  }

  .pull-lg-5 {
    right: 41.66667%;
  }

  .pull-lg-6 {
    right: 50%;
  }

  .pull-lg-7 {
    right: 58.33333%;
  }

  .pull-lg-8 {
    right: 66.66667%;
  }

  .pull-lg-9 {
    right: 75%;
  }

  .pull-lg-10 {
    right: 83.33333%;
  }

  .pull-lg-11 {
    right: 91.66667%;
  }

  .pull-lg-12 {
    right: 100%;
  }

  .push-lg-0 {
    left: auto;
  }

  .push-lg-1 {
    left: 8.33333%;
  }

  .push-lg-2 {
    left: 16.66667%;
  }

  .push-lg-3 {
    left: 25%;
  }

  .push-lg-4 {
    left: 33.33333%;
  }

  .push-lg-5 {
    left: 41.66667%;
  }

  .push-lg-6 {
    left: 50%;
  }

  .push-lg-7 {
    left: 58.33333%;
  }

  .push-lg-8 {
    left: 66.66667%;
  }

  .push-lg-9 {
    left: 75%;
  }

  .push-lg-10 {
    left: 83.33333%;
  }

  .push-lg-11 {
    left: 91.66667%;
  }

  .push-lg-12 {
    left: 100%;
  }

  .offset-lg-0 {
    margin-left: 0%;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.66667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333%;
  }

  .offset-lg-5 {
    margin-left: 41.66667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333%;
  }

  .offset-lg-8 {
    margin-left: 66.66667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333%;
  }

  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1020px) {
  .col-xl {
    position: relative;
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }

  .col-xl-1 {
    -webkit-flex: 0 0 8.33333%;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-xl-2 {
    -webkit-flex: 0 0 16.66667%;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xl-3 {
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    -webkit-flex: 0 0 33.33333%;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-xl-5 {
    -webkit-flex: 0 0 41.66667%;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-xl-6 {
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    -webkit-flex: 0 0 58.33333%;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-xl-8 {
    -webkit-flex: 0 0 66.66667%;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-xl-9 {
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    -webkit-flex: 0 0 83.33333%;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-xl-11 {
    -webkit-flex: 0 0 91.66667%;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-xl-12 {
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .pull-xl-0 {
    right: auto;
  }

  .pull-xl-1 {
    right: 8.33333%;
  }

  .pull-xl-2 {
    right: 16.66667%;
  }

  .pull-xl-3 {
    right: 25%;
  }

  .pull-xl-4 {
    right: 33.33333%;
  }

  .pull-xl-5 {
    right: 41.66667%;
  }

  .pull-xl-6 {
    right: 50%;
  }

  .pull-xl-7 {
    right: 58.33333%;
  }

  .pull-xl-8 {
    right: 66.66667%;
  }

  .pull-xl-9 {
    right: 75%;
  }

  .pull-xl-10 {
    right: 83.33333%;
  }

  .pull-xl-11 {
    right: 91.66667%;
  }

  .pull-xl-12 {
    right: 100%;
  }

  .push-xl-0 {
    left: auto;
  }

  .push-xl-1 {
    left: 8.33333%;
  }

  .push-xl-2 {
    left: 16.66667%;
  }

  .push-xl-3 {
    left: 25%;
  }

  .push-xl-4 {
    left: 33.33333%;
  }

  .push-xl-5 {
    left: 41.66667%;
  }

  .push-xl-6 {
    left: 50%;
  }

  .push-xl-7 {
    left: 58.33333%;
  }

  .push-xl-8 {
    left: 66.66667%;
  }

  .push-xl-9 {
    left: 75%;
  }

  .push-xl-10 {
    left: 83.33333%;
  }

  .push-xl-11 {
    left: 91.66667%;
  }

  .push-xl-12 {
    left: 100%;
  }

  .offset-xl-0 {
    margin-left: 0%;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.66667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333%;
  }

  .offset-xl-5 {
    margin-left: 41.66667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333%;
  }

  .offset-xl-8 {
    margin-left: 66.66667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333%;
  }

  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.table {
  max-width: 100%;
  margin-bottom: 1rem;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #eceeef;
}

.table tbody + tbody {
  border-top: 2px solid #eceeef;
}

.table .table {
  background-color: #fff;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #eceeef;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #eceeef;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #dff0d8;
}

.table-hover .table-success:hover {
  background-color: #d0e9c6;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #d0e9c6;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #d9edf7;
}

.table-hover .table-info:hover {
  background-color: #c4e3f3;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #c4e3f3;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fcf8e3;
}

.table-hover .table-warning:hover {
  background-color: #faf2cc;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #faf2cc;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f2dede;
}

.table-hover .table-danger:hover {
  background-color: #ebcccc;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #ebcccc;
}

.thead-inverse th {
  color: #fff;
  background-color: #373a3c;
}

.thead-default th {
  color: #55595c;
  background-color: #eceeef;
}

.table-inverse {
  color: #eceeef;
  background-color: #373a3c;
}

.table-inverse th,
.table-inverse td,
.table-inverse thead th {
  border-color: #55595c;
}

.table-inverse.table-bordered {
  border: 0;
}

.table-responsive {
  display: block;
  width: 100%;
  min-height: 0.01%;
  overflow-x: auto;
}

.table-reflow thead {
  float: left;
}

.table-reflow tbody {
  display: block;
  white-space: nowrap;
}

.table-reflow th,
.table-reflow td {
  border-top: 1px solid #eceeef;
  border-left: 1px solid #eceeef;
}

.table-reflow th:last-child,
.table-reflow td:last-child {
  border-right: 1px solid #eceeef;
}

.table-reflow thead:last-child tr:last-child th,
.table-reflow thead:last-child tr:last-child td,
.table-reflow tbody:last-child tr:last-child th,
.table-reflow tbody:last-child tr:last-child td,
.table-reflow tfoot:last-child tr:last-child th,
.table-reflow tfoot:last-child tr:last-child td {
  border-bottom: 1px solid #eceeef;
}

.table-reflow tr {
  float: left;
}

.table-reflow tr th,
.table-reflow tr td {
  display: block !important;
  border: 1px solid #eceeef;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.25;
  color: #55595c;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #55595c;
  background-color: #fff;
  border-color: #66afe9;
  outline: none;
}

.form-control::-webkit-input-placeholder {
  color: #999;
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: #999;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #999;
  opacity: 1;
}

.form-control::placeholder {
  color: #999;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #eceeef;
  opacity: 1;
}

.form-control:disabled {
  cursor: not-allowed;
}

select.form-control:not([size]):not([multiple]) {
  height: 2.5rem;
}

select.form-control:focus::-ms-value {
  color: #55595c;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
}

.col-form-label {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
}

.col-form-label-lg {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 0.875rem;
}

.col-form-legend {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
  font-size: 1rem;
}

.form-control-static {
  min-height: 2.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
}

.form-control-static.form-control-sm,
.form-control-static.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

select.form-control-sm:not([size]):not([multiple]) {
  height: 1.8125rem;
}

.form-control-lg {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

select.form-control-lg:not([size]):not([multiple]) {
  height: 3.16667rem;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-check {
  position: relative;
  display: block;
  margin-bottom: 0.75rem;
}

.form-check + .form-check {
  margin-top: -0.25rem;
}

.form-check.disabled .form-check-label {
  color: #818a91;
  cursor: not-allowed;
}

.form-check-label {
  padding-left: 1.25rem;
  margin-bottom: 0;
  cursor: pointer;
}

.form-check-input {
  position: absolute;
  margin-top: 0.25rem;
  margin-left: -1.25rem;
}

.form-check-input:only-child {
  position: static;
}

.form-check-inline {
  position: relative;
  display: inline-block;
  padding-left: 1.25rem;
  margin-bottom: 0;
  vertical-align: middle;
  cursor: pointer;
}

.form-check-inline + .form-check-inline {
  margin-left: 0.75rem;
}

.form-check-inline.disabled {
  cursor: not-allowed;
}

.form-control-feedback {
  margin-top: 0.25rem;
}

.form-control-success,
.form-control-warning,
.form-control-danger {
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right 0.625rem;
  background-size: 1.25rem 1.25rem;
}

.has-success .form-control-feedback,
.has-success .form-control-label,
.has-success .form-check-label,
.has-success .form-check-inline,
.has-success .custom-control {
  color: #5cb85c;
}

.has-success .form-control {
  border-color: #5cb85c;
}

.has-success .input-group-addon {
  color: #5cb85c;
  border-color: #5cb85c;
  background-color: #eaf6ea;
}

.has-success .form-control-feedback {
  color: #5cb85c;
}

.has-success .form-control-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%235cb85c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
}

.has-warning .form-control-feedback,
.has-warning .form-control-label,
.has-warning .form-check-label,
.has-warning .form-check-inline,
.has-warning .custom-control {
  color: #f0ad4e;
}

.has-warning .form-control {
  border-color: #f0ad4e;
}

.has-warning .input-group-addon {
  color: #f0ad4e;
  border-color: #f0ad4e;
  background-color: white;
}

.has-warning .form-control-feedback {
  color: #f0ad4e;
}

.has-warning .form-control-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23f0ad4e' d='M4.4 5.324h-.8v-2.46h.8zm0 1.42h-.8V5.89h.8zM3.76.63L.04 7.075c-.115.2.016.425.26.426h7.397c.242 0 .372-.226.258-.426C6.726 4.924 5.47 2.79 4.253.63c-.113-.174-.39-.174-.494 0z'/%3E%3C/svg%3E");
}

.has-danger .form-control-feedback,
.has-danger .form-control-label,
.has-danger .form-check-label,
.has-danger .form-check-inline,
.has-danger .custom-control {
  color: #d9534f;
}

.has-danger .form-control {
  border-color: #d9534f;
}

.has-danger .input-group-addon {
  color: #d9534f;
  border-color: #d9534f;
  background-color: #fdf7f7;
}

.has-danger .form-control-feedback {
  color: #d9534f;
}

.has-danger .form-control-danger {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23d9534f' viewBox='-2 -2 7 7'%3E%3Cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3E%3Ccircle r='.5'/%3E%3Ccircle cx='3' r='.5'/%3E%3Ccircle cy='3' r='.5'/%3E%3Ccircle cx='3' cy='3' r='.5'/%3E%3C/svg%3E");
}

@media (min-width: 830px) {
  .form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
  }

  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .form-inline .form-control-static {
    display: inline-block;
  }

  .form-inline .input-group {
    display: inline-table;
    vertical-align: middle;
  }

  .form-inline .input-group .input-group-addon,
  .form-inline .input-group .input-group-btn,
  .form-inline .input-group .form-control {
    width: auto;
  }

  .form-inline .input-group > .form-control {
    width: 100%;
  }

  .form-inline .form-control-label {
    margin-bottom: 0;
    vertical-align: middle;
  }

  .form-inline .form-check {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle;
  }

  .form-inline .form-check-label {
    padding-left: 0;
  }

  .form-inline .form-check-input {
    position: relative;
    margin-left: 0;
  }

  .form-inline .has-feedback .form-control-feedback {
    top: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: normal;
  line-height: 1.25;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 0.25rem;
}

.btn:focus,
.btn.focus,
.btn:active:focus,
.btn:active.focus,
.btn.active:focus,
.btn.active.focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.btn:focus,
.btn:hover {
  text-decoration: none;
}

.btn.focus {
  text-decoration: none;
}

.btn:active,
.btn.active {
  background-image: none;
  outline: 0;
}

.btn.disabled,
.btn:disabled {
  cursor: not-allowed;
  opacity: 0.65;
}

a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #0275d8;
  border-color: #0275d8;
}

.btn-primary:hover {
  color: #fff;
  background-color: #025aa5;
  border-color: #01549b;
}

.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: #025aa5;
  border-color: #01549b;
}

.btn-primary:active,
.btn-primary.active,
.open > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #025aa5;
  border-color: #01549b;
  background-image: none;
}

.btn-primary:active:hover,
.btn-primary:active:focus,
.btn-primary:active.focus,
.btn-primary.active:hover,
.btn-primary.active:focus,
.btn-primary.active.focus,
.open > .btn-primary.dropdown-toggle:hover,
.open > .btn-primary.dropdown-toggle:focus,
.open > .btn-primary.dropdown-toggle.focus {
  color: #fff;
  background-color: #014682;
  border-color: #01315a;
}

.btn-primary.disabled:focus,
.btn-primary.disabled.focus,
.btn-primary:disabled:focus,
.btn-primary:disabled.focus {
  background-color: #0275d8;
  border-color: #0275d8;
}

.btn-primary.disabled:hover,
.btn-primary:disabled:hover {
  background-color: #0275d8;
  border-color: #0275d8;
}

.btn-secondary {
  color: #373a3c;
  background-color: #fff;
  border-color: #ccc;
}

.btn-secondary:hover {
  color: #373a3c;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.btn-secondary:focus,
.btn-secondary.focus {
  color: #373a3c;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.btn-secondary:active,
.btn-secondary.active,
.open > .btn-secondary.dropdown-toggle {
  color: #373a3c;
  background-color: #e6e6e6;
  border-color: #adadad;
  background-image: none;
}

.btn-secondary:active:hover,
.btn-secondary:active:focus,
.btn-secondary:active.focus,
.btn-secondary.active:hover,
.btn-secondary.active:focus,
.btn-secondary.active.focus,
.open > .btn-secondary.dropdown-toggle:hover,
.open > .btn-secondary.dropdown-toggle:focus,
.open > .btn-secondary.dropdown-toggle.focus {
  color: #373a3c;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}

.btn-secondary.disabled:focus,
.btn-secondary.disabled.focus,
.btn-secondary:disabled:focus,
.btn-secondary:disabled.focus {
  background-color: #fff;
  border-color: #ccc;
}

.btn-secondary.disabled:hover,
.btn-secondary:disabled:hover {
  background-color: #fff;
  border-color: #ccc;
}

.btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #5bc0de;
}

.btn-info:hover {
  color: #fff;
  background-color: #31b0d5;
  border-color: #2aabd2;
}

.btn-info:focus,
.btn-info.focus {
  color: #fff;
  background-color: #31b0d5;
  border-color: #2aabd2;
}

.btn-info:active,
.btn-info.active,
.open > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #31b0d5;
  border-color: #2aabd2;
  background-image: none;
}

.btn-info:active:hover,
.btn-info:active:focus,
.btn-info:active.focus,
.btn-info.active:hover,
.btn-info.active:focus,
.btn-info.active.focus,
.open > .btn-info.dropdown-toggle:hover,
.open > .btn-info.dropdown-toggle:focus,
.open > .btn-info.dropdown-toggle.focus {
  color: #fff;
  background-color: #269abc;
  border-color: #1f7e9a;
}

.btn-info.disabled:focus,
.btn-info.disabled.focus,
.btn-info:disabled:focus,
.btn-info:disabled.focus {
  background-color: #5bc0de;
  border-color: #5bc0de;
}

.btn-info.disabled:hover,
.btn-info:disabled:hover {
  background-color: #5bc0de;
  border-color: #5bc0de;
}

.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c;
}

.btn-success:hover {
  color: #fff;
  background-color: #449d44;
  border-color: #419641;
}

.btn-success:focus,
.btn-success.focus {
  color: #fff;
  background-color: #449d44;
  border-color: #419641;
}

.btn-success:active,
.btn-success.active,
.open > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #449d44;
  border-color: #419641;
  background-image: none;
}

.btn-success:active:hover,
.btn-success:active:focus,
.btn-success:active.focus,
.btn-success.active:hover,
.btn-success.active:focus,
.btn-success.active.focus,
.open > .btn-success.dropdown-toggle:hover,
.open > .btn-success.dropdown-toggle:focus,
.open > .btn-success.dropdown-toggle.focus {
  color: #fff;
  background-color: #398439;
  border-color: #2d672d;
}

.btn-success.disabled:focus,
.btn-success.disabled.focus,
.btn-success:disabled:focus,
.btn-success:disabled.focus {
  background-color: #5cb85c;
  border-color: #5cb85c;
}

.btn-success.disabled:hover,
.btn-success:disabled:hover {
  background-color: #5cb85c;
  border-color: #5cb85c;
}

.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}

.btn-warning:hover {
  color: #fff;
  background-color: #ec971f;
  border-color: #eb9316;
}

.btn-warning:focus,
.btn-warning.focus {
  color: #fff;
  background-color: #ec971f;
  border-color: #eb9316;
}

.btn-warning:active,
.btn-warning.active,
.open > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #ec971f;
  border-color: #eb9316;
  background-image: none;
}

.btn-warning:active:hover,
.btn-warning:active:focus,
.btn-warning:active.focus,
.btn-warning.active:hover,
.btn-warning.active:focus,
.btn-warning.active.focus,
.open > .btn-warning.dropdown-toggle:hover,
.open > .btn-warning.dropdown-toggle:focus,
.open > .btn-warning.dropdown-toggle.focus {
  color: #fff;
  background-color: #d58512;
  border-color: #b06d0f;
}

.btn-warning.disabled:focus,
.btn-warning.disabled.focus,
.btn-warning:disabled:focus,
.btn-warning:disabled.focus {
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}

.btn-warning.disabled:hover,
.btn-warning:disabled:hover {
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}

.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c9302c;
  border-color: #c12e2a;
}

.btn-danger:focus,
.btn-danger.focus {
  color: #fff;
  background-color: #c9302c;
  border-color: #c12e2a;
}

.btn-danger:active,
.btn-danger.active,
.open > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #c9302c;
  border-color: #c12e2a;
  background-image: none;
}

.btn-danger:active:hover,
.btn-danger:active:focus,
.btn-danger:active.focus,
.btn-danger.active:hover,
.btn-danger.active:focus,
.btn-danger.active.focus,
.open > .btn-danger.dropdown-toggle:hover,
.open > .btn-danger.dropdown-toggle:focus,
.open > .btn-danger.dropdown-toggle.focus {
  color: #fff;
  background-color: #ac2925;
  border-color: #8b211e;
}

.btn-danger.disabled:focus,
.btn-danger.disabled.focus,
.btn-danger:disabled:focus,
.btn-danger:disabled.focus {
  background-color: #d9534f;
  border-color: #d9534f;
}

.btn-danger.disabled:hover,
.btn-danger:disabled:hover {
  background-color: #d9534f;
  border-color: #d9534f;
}

.btn-outline-primary {
  color: #0275d8;
  background-image: none;
  background-color: transparent;
  border-color: #0275d8;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #0275d8;
  border-color: #0275d8;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  color: #fff;
  background-color: #0275d8;
  border-color: #0275d8;
}

.btn-outline-primary:active,
.btn-outline-primary.active,
.open > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #0275d8;
  border-color: #0275d8;
}

.btn-outline-primary:active:hover,
.btn-outline-primary:active:focus,
.btn-outline-primary:active.focus,
.btn-outline-primary.active:hover,
.btn-outline-primary.active:focus,
.btn-outline-primary.active.focus,
.open > .btn-outline-primary.dropdown-toggle:hover,
.open > .btn-outline-primary.dropdown-toggle:focus,
.open > .btn-outline-primary.dropdown-toggle.focus {
  color: #fff;
  background-color: #014682;
  border-color: #01315a;
}

.btn-outline-primary.disabled:focus,
.btn-outline-primary.disabled.focus,
.btn-outline-primary:disabled:focus,
.btn-outline-primary:disabled.focus {
  border-color: #43a7fd;
}

.btn-outline-primary.disabled:hover,
.btn-outline-primary:disabled:hover {
  border-color: #43a7fd;
}

.btn-outline-secondary {
  color: #ccc;
  background-image: none;
  background-color: transparent;
  border-color: #ccc;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #ccc;
  border-color: #ccc;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  color: #fff;
  background-color: #ccc;
  border-color: #ccc;
}

.btn-outline-secondary:active,
.btn-outline-secondary.active,
.open > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #ccc;
  border-color: #ccc;
}

.btn-outline-secondary:active:hover,
.btn-outline-secondary:active:focus,
.btn-outline-secondary:active.focus,
.btn-outline-secondary.active:hover,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.active.focus,
.open > .btn-outline-secondary.dropdown-toggle:hover,
.open > .btn-outline-secondary.dropdown-toggle:focus,
.open > .btn-outline-secondary.dropdown-toggle.focus {
  color: #fff;
  background-color: #a1a1a1;
  border-color: #8c8c8c;
}

.btn-outline-secondary.disabled:focus,
.btn-outline-secondary.disabled.focus,
.btn-outline-secondary:disabled:focus,
.btn-outline-secondary:disabled.focus {
  border-color: white;
}

.btn-outline-secondary.disabled:hover,
.btn-outline-secondary:disabled:hover {
  border-color: white;
}

.btn-outline-info {
  color: #5bc0de;
  background-image: none;
  background-color: transparent;
  border-color: #5bc0de;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #5bc0de;
  border-color: #5bc0de;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
  color: #fff;
  background-color: #5bc0de;
  border-color: #5bc0de;
}

.btn-outline-info:active,
.btn-outline-info.active,
.open > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #5bc0de;
  border-color: #5bc0de;
}

.btn-outline-info:active:hover,
.btn-outline-info:active:focus,
.btn-outline-info:active.focus,
.btn-outline-info.active:hover,
.btn-outline-info.active:focus,
.btn-outline-info.active.focus,
.open > .btn-outline-info.dropdown-toggle:hover,
.open > .btn-outline-info.dropdown-toggle:focus,
.open > .btn-outline-info.dropdown-toggle.focus {
  color: #fff;
  background-color: #269abc;
  border-color: #1f7e9a;
}

.btn-outline-info.disabled:focus,
.btn-outline-info.disabled.focus,
.btn-outline-info:disabled:focus,
.btn-outline-info:disabled.focus {
  border-color: #b0e1ef;
}

.btn-outline-info.disabled:hover,
.btn-outline-info:disabled:hover {
  border-color: #b0e1ef;
}

.btn-outline-success {
  color: #5cb85c;
  background-image: none;
  background-color: transparent;
  border-color: #5cb85c;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c;
}

.btn-outline-success:active,
.btn-outline-success.active,
.open > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c;
}

.btn-outline-success:active:hover,
.btn-outline-success:active:focus,
.btn-outline-success:active.focus,
.btn-outline-success.active:hover,
.btn-outline-success.active:focus,
.btn-outline-success.active.focus,
.open > .btn-outline-success.dropdown-toggle:hover,
.open > .btn-outline-success.dropdown-toggle:focus,
.open > .btn-outline-success.dropdown-toggle.focus {
  color: #fff;
  background-color: #398439;
  border-color: #2d672d;
}

.btn-outline-success.disabled:focus,
.btn-outline-success.disabled.focus,
.btn-outline-success:disabled:focus,
.btn-outline-success:disabled.focus {
  border-color: #a3d7a3;
}

.btn-outline-success.disabled:hover,
.btn-outline-success:disabled:hover {
  border-color: #a3d7a3;
}

.btn-outline-warning {
  color: #f0ad4e;
  background-image: none;
  background-color: transparent;
  border-color: #f0ad4e;
}

.btn-outline-warning:hover {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}

.btn-outline-warning:active,
.btn-outline-warning.active,
.open > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}

.btn-outline-warning:active:hover,
.btn-outline-warning:active:focus,
.btn-outline-warning:active.focus,
.btn-outline-warning.active:hover,
.btn-outline-warning.active:focus,
.btn-outline-warning.active.focus,
.open > .btn-outline-warning.dropdown-toggle:hover,
.open > .btn-outline-warning.dropdown-toggle:focus,
.open > .btn-outline-warning.dropdown-toggle.focus {
  color: #fff;
  background-color: #d58512;
  border-color: #b06d0f;
}

.btn-outline-warning.disabled:focus,
.btn-outline-warning.disabled.focus,
.btn-outline-warning:disabled:focus,
.btn-outline-warning:disabled.focus {
  border-color: #f8d9ac;
}

.btn-outline-warning.disabled:hover,
.btn-outline-warning:disabled:hover {
  border-color: #f8d9ac;
}

.btn-outline-danger {
  color: #d9534f;
  background-image: none;
  background-color: transparent;
  border-color: #d9534f;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f;
}

.btn-outline-danger:active,
.btn-outline-danger.active,
.open > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f;
}

.btn-outline-danger:active:hover,
.btn-outline-danger:active:focus,
.btn-outline-danger:active.focus,
.btn-outline-danger.active:hover,
.btn-outline-danger.active:focus,
.btn-outline-danger.active.focus,
.open > .btn-outline-danger.dropdown-toggle:hover,
.open > .btn-outline-danger.dropdown-toggle:focus,
.open > .btn-outline-danger.dropdown-toggle.focus {
  color: #fff;
  background-color: #ac2925;
  border-color: #8b211e;
}

.btn-outline-danger.disabled:focus,
.btn-outline-danger.disabled.focus,
.btn-outline-danger:disabled:focus,
.btn-outline-danger:disabled.focus {
  border-color: #eba5a3;
}

.btn-outline-danger.disabled:hover,
.btn-outline-danger:disabled:hover {
  border-color: #eba5a3;
}

.btn-link {
  font-weight: normal;
  color: #0275d8;
  border-radius: 0;
}

.btn-link,
.btn-link:active,
.btn-link.active,
.btn-link:disabled {
  background-color: transparent;
}

.btn-link,
.btn-link:focus,
.btn-link:active {
  border-color: transparent;
}

.btn-link:hover {
  border-color: transparent;
}

.btn-link:focus,
.btn-link:hover {
  color: #014c8c;
  text-decoration: underline;
  background-color: transparent;
}

.btn-link:disabled:focus,
.btn-link:disabled:hover {
  color: #818a91;
  text-decoration: none;
}

.btn-lg {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type='submit'].btn-block,
input[type='reset'].btn-block,
input[type='button'].btn-block {
  width: 100%;
}

.fade {
  opacity: 0;
  transition: opacity 0.15s linear;
}

.fade.in {
  opacity: 1;
}

.collapse {
  display: none;
}

.collapse.in {
  display: block;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition-timing-function: ease;
  transition-duration: 0.35s;
  transition-property: height;
}

.dropup,
.dropdown {
  position: relative;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.3em;
  vertical-align: middle;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:focus {
  outline: 0;
}

.dropup .dropdown-toggle::after {
  border-top: 0;
  border-bottom: 0.3em solid;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  padding: 20px;
  font-family: Loko_Lato-Regular;
  font-size: 16px;
  line-height: 19.2px;
  color: #000;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0px 0px 10px 10px;
  box-shadow: 1px 4px 15px 0px #0000004d;
}

.dropdown-divider {
  height: 1px;
  margin: 0.5rem 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  color: #373a3c;
  text-align: inherit;
  white-space: nowrap;
  background: none;
  border: 0;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #2b2d2f;
  text-decoration: none;
  background-color: #f5f5f5;
}

.dropdown-item.active,
.dropdown-item.active:focus,
.dropdown-item.active:hover {
  color: #fff;
  text-decoration: none;
  background-color: #0275d8;
  outline: 0;
}

.dropdown-item.disabled,
.dropdown-item.disabled:focus,
.dropdown-item.disabled:hover {
  color: #818a91;
}

.dropdown-item.disabled:focus,
.dropdown-item.disabled:hover {
  text-decoration: none;
  cursor: not-allowed;
  background-color: transparent;
  background-image: none;
  filter: 'progid:DXImageTransform.Microsoft.gradient(enabled = false)';
}

.open > .dropdown-menu {
  display: block;
}

.open > a {
  outline: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-header {
  display: block;
  padding: 5px 20px;
  font-size: 0.875rem;
  color: #818a91;
  white-space: nowrap;
}

.dropdown-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 990;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  content: '';
  border-top: 0;
  border-bottom: 0.3em solid;
}

.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
}

.nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: inline-block;
}

.nav-link:focus,
.nav-link:hover {
  text-decoration: none;
}

.nav-link.disabled {
  color: #818a91;
}

.nav-link.disabled,
.nav-link.disabled:focus,
.nav-link.disabled:hover {
  color: #818a91;
  cursor: not-allowed;
  background-color: transparent;
}

.nav-inline .nav-item {
  display: inline-block;
}

.nav-inline .nav-item + .nav-item,
.nav-inline .nav-link + .nav-link {
  margin-left: 1rem;
}

.nav-tabs {
  border-bottom: 1px solid #ddd;
}

.nav-tabs::after {
  content: '';
  display: table;
  clear: both;
}

.nav-tabs .nav-item {
  float: left;
  margin-bottom: -1px;
}

.nav-tabs .nav-item + .nav-item {
  margin-left: 0.2rem;
}

.nav-tabs .nav-link {
  display: block;
  padding: 0.5em 1em;
  border: 1px solid transparent;
  border-top-right-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #eceeef #eceeef #ddd;
}

.nav-tabs .nav-link.disabled,
.nav-tabs .nav-link.disabled:focus,
.nav-tabs .nav-link.disabled:hover {
  color: #818a91;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-link.active:focus,
.nav-tabs .nav-link.active:hover,
.nav-tabs .nav-item.open .nav-link,
.nav-tabs .nav-item.open .nav-link:focus,
.nav-tabs .nav-item.open .nav-link:hover {
  color: #55595c;
  background-color: #fff;
  border-color: #ddd #ddd transparent;
}

.nav-tabs .dropdown-menu {
}

.nav-pills::after {
  content: '';
  display: table;
  clear: both;
}

.nav-pills .nav-item {
  float: left;
}

.nav-pills .nav-item + .nav-item {
  margin-left: 0.2rem;
}

.nav-pills .nav-link {
  display: block;
  padding: 0.5em 1em;
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .nav-link.active:focus,
.nav-pills .nav-link.active:hover,
.nav-pills .nav-item.open .nav-link,
.nav-pills .nav-item.open .nav-link:focus,
.nav-pills .nav-item.open .nav-link:hover {
  color: #fff;
  cursor: default;
  background-color: #0275d8;
}

.nav-stacked .nav-item {
  display: block;
  float: none;
}

.nav-stacked .nav-item + .nav-item {
  margin-top: 0.2rem;
  margin-left: 0;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  padding: 0.5rem 1rem;
}

.navbar::after {
  content: '';
  display: table;
  clear: both;
}

@media (min-width: 830px) {
  .navbar {
    border-radius: 0.25rem;
  }
}

.navbar-full {
  z-index: 1000;
}

@media (min-width: 830px) {
  .navbar-full {
    border-radius: 0;
  }
}

.navbar-fixed-top,
.navbar-fixed-bottom {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
}

@media (min-width: 830px) {
  .navbar-fixed-top,
  .navbar-fixed-bottom {
    border-radius: 0;
  }
}

.navbar-fixed-top {
  top: 0;
}

.navbar-fixed-bottom {
  bottom: 0;
}

.navbar-sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1030;
  width: 100%;
}

@media (min-width: 830px) {
  .navbar-sticky-top {
    border-radius: 0;
  }
}

.navbar-brand {
  float: left;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  margin-right: 1rem;
  font-size: 1.25rem;
}

.navbar-brand:focus,
.navbar-brand:hover {
  text-decoration: none;
}

.navbar-brand > img {
  display: block;
}

.navbar-divider {
  float: left;
  width: 1px;
  padding-top: 0.425rem;
  padding-bottom: 0.425rem;
  margin-right: 1rem;
  margin-left: 1rem;
  overflow: hidden;
}

.navbar-divider::before {
  content: '\00a0';
}

.navbar-toggler {
  padding: 0.5rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background: none;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:focus,
.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-nav .nav-item {
  float: left;
}

.navbar-nav .nav-link {
  display: block;
  padding-top: 0.425rem;
  padding-bottom: 0.425rem;
}

.navbar-nav .nav-link + .nav-link {
  margin-left: 1rem;
}

.navbar-nav .nav-item + .nav-item {
  margin-left: 1rem;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.8);
}

.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, 0.8);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.6);
}

.navbar-light .navbar-nav .open > .nav-link,
.navbar-light .navbar-nav .open > .nav-link:focus,
.navbar-light .navbar-nav .open > .nav-link:hover,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .active > .nav-link:focus,
.navbar-light .navbar-nav .active > .nav-link:hover,
.navbar-light .navbar-nav .nav-link.open,
.navbar-light .navbar-nav .nav-link.open:focus,
.navbar-light .navbar-nav .nav-link.open:hover,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.active:focus,
.navbar-light .navbar-nav .nav-link.active:hover {
  color: rgba(0, 0, 0, 0.8);
}

.navbar-light .navbar-divider {
  background-color: rgba(0, 0, 0, 0.075);
}

.navbar-dark .navbar-brand {
  color: white;
}

.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
  color: white;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .open > .nav-link,
.navbar-dark .navbar-nav .open > .nav-link:focus,
.navbar-dark .navbar-nav .open > .nav-link:hover,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link:focus,
.navbar-dark .navbar-nav .active > .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.open,
.navbar-dark .navbar-nav .nav-link.open:focus,
.navbar-dark .navbar-nav .nav-link.open:hover,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.active:focus,
.navbar-dark .navbar-nav .nav-link.active:hover {
  color: white;
}

.navbar-dark .navbar-divider {
  background-color: rgba(255, 255, 255, 0.075);
}

.navbar-toggleable-xs::after {
  content: '';
  display: table;
  clear: both;
}

@media (max-width: 829px) {
  .navbar-toggleable-xs .navbar-nav .nav-item {
    float: none;
    margin-left: 0;
  }
}

@media (min-width: 830px) {
  .navbar-toggleable-xs {
    display: block !important;
  }
}

.navbar-toggleable-sm::after {
  content: '';
  display: table;
  clear: both;
}

@media (max-width: 899px) {
  .navbar-toggleable-sm .navbar-nav .nav-item {
    float: none;
    margin-left: 0;
  }
}

@media (min-width: 900px) {
  .navbar-toggleable-sm {
    display: block !important;
  }
}

.navbar-toggleable-md::after {
  content: '';
  display: table;
  clear: both;
}

@media (max-width: 991px) {
  .navbar-toggleable-md .navbar-nav .nav-item {
    float: none;
    margin-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-toggleable-md {
    display: block !important;
  }
}

.alert {
  padding: 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: bold;
}

.alert-dismissible {
  padding-right: 2rem;
}

.alert-dismissible .close {
  position: relative;
  top: -0.125rem;
  right: -1rem;
  color: inherit;
}

.alert-success {
  background-color: #dff0d8;
  border-color: #d0e9c6;
  color: #3c763d;
}

.alert-success hr {
  border-top-color: #c1e2b3;
}

.alert-success .alert-link {
  color: #2b542c;
}

.alert-info {
  background-color: #d9edf7;
  border-color: #bcdff1;
  color: #31708f;
}

.alert-info hr {
  border-top-color: #a6d5ec;
}

.alert-info .alert-link {
  color: #245269;
}

.alert-warning {
  background-color: #fcf8e3;
  border-color: #faf2cc;
  color: #8a6d3b;
}

.alert-warning hr {
  border-top-color: #f7ecb5;
}

.alert-warning .alert-link {
  color: #66512c;
}

.alert-danger {
  background-color: #f2dede;
  border-color: #ebcccc;
  color: #a94442;
}

.alert-danger hr {
  border-top-color: #e4b9b9;
}

.alert-danger .alert-link {
  color: #843534;
}

.bg-faded {
  background-color: #f7f7f9;
}

.bg-primary {
  color: #fff !important;
  background-color: #0275d8 !important;
}

a.bg-primary:focus,
a.bg-primary:hover {
  background-color: #025aa5 !important;
}

.bg-success {
  color: #fff !important;
  background-color: #5cb85c !important;
}

a.bg-success:focus,
a.bg-success:hover {
  background-color: #449d44 !important;
}

.bg-info {
  color: #fff !important;
  background-color: #5bc0de !important;
}

a.bg-info:focus,
a.bg-info:hover {
  background-color: #31b0d5 !important;
}

.bg-warning {
  color: #fff !important;
  background-color: #f0ad4e !important;
}

a.bg-warning:focus,
a.bg-warning:hover {
  background-color: #ec971f !important;
}

.bg-danger {
  color: #fff !important;
  background-color: #d9534f !important;
}

a.bg-danger:focus,
a.bg-danger:hover {
  background-color: #c9302c !important;
}

.bg-inverse {
  color: #fff !important;
  background-color: #373a3c !important;
}

a.bg-inverse:focus,
a.bg-inverse:hover {
  background-color: #1f2021 !important;
}

.clearfix::after {
  content: '';
  display: table;
  clear: both;
}

.d-block {
  display: block !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-inline {
  display: inline !important;
}

.flex-xs-first {
  -webkit-order: -1;
  -ms-flex-order: -1;
  order: -1;
}

.flex-xs-last {
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
}

.flex-xs-unordered {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
}

.flex-items-xs-top {
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.flex-items-xs-middle {
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.flex-items-xs-bottom {
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.flex-xs-top {
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.flex-xs-middle {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.flex-xs-bottom {
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.flex-items-xs-left {
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.flex-items-xs-center {
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.flex-items-xs-right {
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.flex-items-xs-around {
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.flex-items-xs-between {
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media (min-width: 830px) {
  .flex-sm-first {
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }

  .flex-sm-last {
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }

  .flex-sm-unordered {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
  }
}

@media (min-width: 830px) {
  .flex-items-sm-top {
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .flex-items-sm-middle {
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .flex-items-sm-bottom {
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
}

@media (min-width: 830px) {
  .flex-sm-top {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }

  .flex-sm-middle {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
  }

  .flex-sm-bottom {
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
  }
}

@media (min-width: 830px) {
  .flex-items-sm-left {
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .flex-items-sm-center {
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .flex-items-sm-right {
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .flex-items-sm-around {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .flex-items-sm-between {
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

@media (min-width: 900px) {
  .flex-md-first {
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }

  .flex-md-last {
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }

  .flex-md-unordered {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
  }
}

@media (min-width: 900px) {
  .flex-items-md-top {
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .flex-items-md-middle {
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .flex-items-md-bottom {
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
}

@media (min-width: 900px) {
  .flex-md-top {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }

  .flex-md-middle {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
  }

  .flex-md-bottom {
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
  }
}

@media (min-width: 900px) {
  .flex-items-md-left {
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .flex-items-md-center {
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .flex-items-md-right {
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .flex-items-md-around {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .flex-items-md-between {
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

@media (min-width: 992px) {
  .flex-lg-first {
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }

  .flex-lg-last {
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }

  .flex-lg-unordered {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
  }
}

@media (min-width: 992px) {
  .flex-items-lg-top {
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .flex-items-lg-middle {
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .flex-items-lg-bottom {
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
}

@media (min-width: 992px) {
  .flex-lg-top {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }

  .flex-lg-middle {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
  }

  .flex-lg-bottom {
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
  }
}

@media (min-width: 992px) {
  .flex-items-lg-left {
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .flex-items-lg-center {
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .flex-items-lg-right {
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .flex-items-lg-around {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .flex-items-lg-between {
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

@media (min-width: 1020px) {
  .flex-xl-first {
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }

  .flex-xl-last {
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }

  .flex-xl-unordered {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
  }
}

@media (min-width: 1020px) {
  .flex-items-xl-top {
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .flex-items-xl-middle {
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .flex-items-xl-bottom {
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
}

@media (min-width: 1020px) {
  .flex-xl-top {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }

  .flex-xl-middle {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
  }

  .flex-xl-bottom {
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
  }
}

@media (min-width: 1020px) {
  .flex-items-xl-left {
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .flex-items-xl-center {
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .flex-items-xl-right {
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .flex-items-xl-around {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .flex-items-xl-between {
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.pull-xs-left {
  float: left !important;
}

.pull-xs-right {
  float: right !important;
}

.pull-xs-none {
  float: none !important;
}

@media (min-width: 830px) {
  .pull-sm-left {
    float: left !important;
  }

  .pull-sm-right {
    float: right !important;
  }

  .pull-sm-none {
    float: none !important;
  }
}

@media (min-width: 900px) {
  .pull-md-left {
    float: left !important;
  }

  .pull-md-right {
    float: right !important;
  }

  .pull-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .pull-lg-left {
    float: left !important;
  }

  .pull-lg-right {
    float: right !important;
  }

  .pull-lg-none {
    float: none !important;
  }
}

@media (min-width: 1020px) {
  .pull-xl-left {
    float: left !important;
  }

  .pull-xl-right {
    float: right !important;
  }

  .pull-xl-none {
    float: none !important;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

.w-100 {
  width: 100% !important;
}

.m-x-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.m-a-0 {
  margin: 0 0 !important;
}

.m-t-0 {
  margin-top: 0 !important;
}

.m-r-0 {
  margin-right: 0 !important;
}

.m-b-0 {
  margin-bottom: 0 !important;
}

.m-l-0 {
  margin-left: 0 !important;
}

.m-x-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.m-y-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.m-a-1 {
  margin: 1rem 1rem !important;
}

.m-t-1 {
  margin-top: 1rem !important;
}

.m-r-1 {
  margin-right: 1rem !important;
}

.m-b-1 {
  margin-bottom: 1rem !important;
}

.m-l-1 {
  margin-left: 1rem !important;
}

.m-x-1 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.m-y-1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.m-a-2 {
  margin: 1.5rem 1.5rem !important;
}

.m-t-2 {
  margin-top: 1.5rem !important;
}

.m-r-2 {
  margin-right: 1.5rem !important;
}

.m-b-2 {
  margin-bottom: 1.5rem !important;
}

.m-l-2 {
  margin-left: 1.5rem !important;
}

.m-x-2 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.m-y-2 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.m-a-3 {
  margin: 3rem 3rem !important;
}

.m-t-3 {
  margin-top: 3rem !important;
}

.m-r-3 {
  margin-right: 3rem !important;
}

.m-b-3 {
  margin-bottom: 3rem !important;
}

.m-l-3 {
  margin-left: 3rem !important;
}

.m-x-3 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.m-y-3 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.p-a-0 {
  padding: 0 0 !important;
}

.p-t-0 {
  padding-top: 0 !important;
}

.p-r-0 {
  padding-right: 0 !important;
}

.p-b-0 {
  padding-bottom: 0 !important;
}

.p-l-0 {
  padding-left: 0 !important;
}

.p-x-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.p-y-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-a-1 {
  padding: 1rem 1rem !important;
}

.p-t-1 {
  padding-top: 1rem !important;
}

.p-r-1 {
  padding-right: 1rem !important;
}

.p-b-1 {
  padding-bottom: 1rem !important;
}

.p-l-1 {
  padding-left: 1rem !important;
}

.p-x-1 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.p-y-1 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.p-a-2 {
  padding: 1.5rem 1.5rem !important;
}

.p-t-2 {
  padding-top: 1.5rem !important;
}

.p-r-2 {
  padding-right: 1.5rem !important;
}

.p-b-2 {
  padding-bottom: 1.5rem !important;
}

.p-l-2 {
  padding-left: 1.5rem !important;
}

.p-x-2 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.p-y-2 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.p-a-3 {
  padding: 3rem 3rem !important;
}

.p-t-3 {
  padding-top: 3rem !important;
}

.p-r-3 {
  padding-right: 3rem !important;
}

.p-b-3 {
  padding-bottom: 3rem !important;
}

.p-l-3 {
  padding-left: 3rem !important;
}

.p-x-3 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.p-y-3 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pos-f-t {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-xs-left {
  text-align: left !important;
}

.text-xs-right {
  text-align: right !important;
}

.text-xs-center {
  text-align: center !important;
}

@media (min-width: 830px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 900px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1020px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-normal {
  font-weight: normal;
}

.font-weight-bold {
  font-weight: bold;
}

.font-italic {
  font-style: italic;
}

.text-muted {
  color: #818a91 !important;
}

a.text-muted:focus,
a.text-muted:hover {
  color: #687077 !important;
}

.text-primary {
  color: #0275d8 !important;
}

a.text-primary:focus,
a.text-primary:hover {
  color: #025aa5 !important;
}

.text-success {
  color: #5cb85c !important;
}

a.text-success:focus,
a.text-success:hover {
  color: #449d44 !important;
}

.text-info {
  color: #5bc0de !important;
}

a.text-info:focus,
a.text-info:hover {
  color: #31b0d5 !important;
}

.text-warning {
  color: #f0ad4e !important;
}

a.text-warning:focus,
a.text-warning:hover {
  color: #ec971f !important;
}

.text-danger {
  color: #d9534f !important;
}

a.text-danger:focus,
a.text-danger:hover {
  color: #c9302c !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.invisible {
  visibility: hidden !important;
}

.hidden-xs-up {
  display: none !important;
}

@media (max-width: 829px) {
  .hidden-xs-down {
    display: none !important;
  }
}

@media (min-width: 830px) {
  .hidden-sm-up {
    display: none !important;
  }
}

@media (max-width: 899px) {
  .hidden-sm-down {
    display: none !important;
  }
}

@media (min-width: 900px) {
  .hidden-md-up {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .hidden-md-down {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important;
  }
}

@media (max-width: 1019px) {
  .hidden-lg-down {
    display: none !important;
  }
}

@media (min-width: 1020px) {
  .hidden-xl-up {
    display: none !important;
  }
}

.hidden-xl-down {
  display: none !important;
}

.visible-print-block {
  display: none !important;
}

@media print {
  .visible-print-block {
    display: block !important;
  }
}

.visible-print-inline {
  display: none !important;
}

@media print {
  .visible-print-inline {
    display: inline !important;
  }
}

.visible-print-inline-block {
  display: none !important;
}

@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }
}

@media print {
  .hidden-print {
    display: none !important;
  }
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}

.slick-track:before,
.slick-track:after {
  content: '';
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

body {
  background: #fff;
  position: relative;
}

.wf-loading h1 {
  visibility: hidden;
}

.img-rounded {
  border-radius: 100%;
  overflow: hidden;
}

a {
  transition: all 0.2s;
}

embed,
iframe,
img,
object {
  max-width: 100% !important;
  border: none;
}

/* object-fit requires width and height specified both so
set height in TopStory and set width there to avoid proportions breaking */
.fit-image {
  width: 100%;
  object-fit: cover;
  object-position: top;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

img.toleft {
  margin: 5px 15px 0 0;
}

img.toright {
  margin: 5px 0 0 15px;
}

.image-style-align-left {
  float: left;
  margin: 5px 15px 0 0;
}

.image-style-side {
  float: right;
  margin: 5px 5px 5px 15px;
}

.image-style-align-right {
  float: right;
  margin: 5px 5px 5px 15px;
}

.more-button {
  float: right;
}

.won {
  color: #3e9d27 !important;
}

.lost {
  color: #d12222 !important;
}

.tie {
  color: #797979 !important;
}

select {
  background-color: #fff;
  font-size: 14px;
  color: #252627;
  margin: 0;
  line-height: 1;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  height: 40px;
  padding: 10px 65px 10px 15px;
  vertical-align: middle;
  background: url('./assets/arrow-select.svg') #fff center right no-repeat;
  border-radius: 4px;
  max-width: 100%;
}

//select:hover{background:url("./assets/arrow-select-hover.svg") #fff center right no-repeat}
.clear:after {
  content: '';
  display: table;
  clear: both;
}

@font-face {
  font-family: 'BebasNeue';
  src: url('./assets/fonts/BebasNeueBold.eot');
  src:
    url('./assets/fonts/BebasNeueBold.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/BebasNeueBold.woff') format('woff'),
    url('./assets/fonts/BebasNeueBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
.player-card-photo {
  display: table-cell;
  vertical-align: top;
  width: 200px;
}
@media (max-width: 1250px) {
  .player-card-photo {
    width: 150px;
  }
}
@media (max-width: 920px) {
  .player-card-photo {
    display: none;
  }
}
.player-card-data {
  display: table-cell;
  vertical-align: top;
  padding-left: 65px;
}
@media (max-width: 1410px) {
  .player-card-data {
    padding-left: 30px;
  }
}
@media (max-width: 920px) {
  .player-card-data {
    padding-left: 0;
  }
}
.player-card-data .number {
  display: inline-block;
  font-size: 220px;
  font-family: BebasNeue;
  font-weight: bold;
  line-height: 1;
  color: #1a202c;
}
@media (max-width: 710px) {
  .player-card-data .number {
    font-size: 110px;
  }
}
@media (max-width: 430px) {
  .player-card-data .number {
    font-size: 80px;
  }
}
@media (max-width: 370px) {
  .player-card-data .number {
    display: block;
  }
}
.player-card-data .names {
  display: inline-block;
  vertical-align: top;
  margin-top: 15px;
  margin-left: 35px;
}
@media (max-width: 710px) {
  .player-card-data .names {
    margin-top: 0;
  }
}
@media (max-width: 430px) {
  .player-card-data .names {
    margin-left: 15px;
  }
}
@media (max-width: 370px) {
  .player-card-data .names {
    display: block;
    margin-left: 0;
  }
}
.player-card-data .names .sticker {
  display: inline;
  font-family: BebasNeue;
  color: #fff;
  background-color: #cf072c;
  height: 32px;
  padding: 7px 0 6px;
  margin-left: 18px;
}
.player-card-data .names .sticker:before {
  content: '';
  width: 32px;
  height: 32px;
  display: inline-block;
  background: url('./assets/sticker.svg') no-repeat;
  background-size: cover;
  vertical-align: top;
  margin-top: -2px;
  margin-left: -20px;
}
.player-card-data .names .sticker:after {
  content: '';
  width: 32px;
  height: 32px;
  display: inline-block;
  background: url('./assets/sticker.svg') no-repeat;
  background-size: cover;
  vertical-align: top;
  margin-top: -2px;
  margin-right: -20px;
  background-position-x: -95px;
}
.player-card-data .names .name {
  font-family: 'futura-pt';
  font-size: 40px;
  font-weight: 900;
  line-height: 1.5;
  color: #1a202c;
}
@media (max-width: 710px) {
  .player-card-data .names .name {
    font-size: 22px;
  }
}
.player-card-data .names .surname {
  font-family: 'futura-pt';
  font-size: 70px;
  font-weight: 900;
  line-height: 0.86;
  color: #1a202c;
}
@media (max-width: 710px) {
  .player-card-data .names .surname {
    font-size: 34px;
  }
}
.player-card:before {
  display: block;
  content: '';
  width: 100%;
  height: 85%;
  background-image: linear-gradient(to top, #fff, rgba(255, 255, 255, 0));
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1000;
}
// .player-card:before{display:block;content:"";width:100%;height:85%;background-image:linear-gradient(to top, #00256D, rgba(255, 255, 255, 0));position:absolute;bottom:0;left:0;z-index:1000}
@media (max-width: 510px) {
  .player-card:before {
    height: 50%;
  }
}
.player-card-bio {
  position: relative;
  top: 0px;
  z-index: 10000;
}
@media (max-width: 710px) {
  .player-card-bio {
    top: 0;
  }
}
.player-card-bio .bio-box {
  display: inline-block;
  margin-right: 56px;
  margin-bottom: 20px;
}
.player-card-bio .bio-box-heading {
  font-size: 16px;
  font-family: BebasNeue;
  font-weight: bold;
  line-height: 1.75;
}
.player-card-bio .bio-box-data {
  font-family: 'futura-pt';
  font-weight: 300;
  font-size: 24px;
  line-height: 1.17;
  color: #1a202c;
}

.futura-book {
  font-family: 'futura-pt';
  font-weight: 300;
}

.futura-heavy {
  font-family: 'futura-pt';
  font-weight: 900;
}

strong {
  font-family: 'Loko_Lato-Bold';
  font-weight: 900;
}

.bebas-bold {
  font-family: BebasNeue;
  font-weight: bold;
}

.container {
  max-width: 1920px;
}

@media (max-width: 1019px) {
  .container {
    max-width: 100%;
    width: 100%;
  }
}

.layout {
  max-width: 1440px;
  position: relative;
}

@media (max-width: 1180px) {
  .layout main {
    display: block;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0;
  }
}

.layout aside {
  padding-top: 25px;
}

@media (max-width: 1180px) {
  .layout aside {
    padding-top: 90px;
    position: absolute;
    top: 0;
    right: 0;
    width: 280px;
    max-width: 100%;
    background: #fff;
    z-index: 11000;
    visibility: hidden;
    opacity: 0;
  }
}

.mobile-menu-aside {
  visibility: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: 11000;
  background: #fff;
  width: 270px;
  height: 100%;
  padding-top: 100px;
  -webkit-transform: translateX(-240px);
  -ms-transform: translateX(-240px);
  transform: translateX(-240px);
  transition: all 0.5s;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px #ebebeb;
}

.mobile-menu-aside .mobile-nav-inner {
  list-style: none;
  padding: 0;
}

.mobile-menu-aside .mobile-nav-inner .nav-item {
  border-bottom: 1px solid #ededed;
}

.mobile-menu-aside .mobile-nav-inner .nav-item .nav-link {
  display: block;
  padding: 17px 15px 10px;
  font-size: 22px;
  line-height: 1;
  font-family: BebasNeue;
  font-weight: bold;
  color: #1b212d;
}

.mobile-menu-aside .mobile-nav-inner .nav-item .nav-link:after {
  display: none;
}

.mobile-menu-aside .mobile-nav-inner .nav-item .dropdown-menu {
  position: initial;
  border: 0;
  float: none;
  padding-top: 0;
  margin-top: 0;
  padding-bottom: 15px;
}

.mobile-menu-aside .mobile-nav-inner .nav-item .dropdown-menu .dropdown-item {
  font-family: 'futura-pt';
  font-weight: 300;
  text-transform: uppercase;
}

@media (max-width: 1180px) {
  .dark-page {
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s;
  }

  .show-menu .mobile-menu-aside {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  .show-menu .dark-page {
    opacity: 1;
    visibility: visible;
  }

  .show-aside aside {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  .show-aside .dark-page {
    opacity: 1;
    visibility: visible;
  }
}

@media (max-width: 1180px) {
  .head-bar {
    display: none;
  }
}

.topstories {
  background: #232f37;
}

.topstories .topstory {
  padding: 0;
  position: relative;
  overflow: hidden;
}

@media (max-width: 899px) {
  .topstories .topstory {
    height: 360px;
  }
}

@media (max-width: 440px) {
  .topstories .topstory {
    height: 330px;
  }
}

.topstories .topstory:active .topstory-heading,
.topstories .topstory:focus .topstory-heading,
.topstories .topstory:hover .topstory-heading {
  text-decoration: underline;
}

.topstories .topstory:active:after,
.topstories .topstory:focus:after,
.topstories .topstory:hover:after {
  opacity: 0.9;
}

@media (max-width: 899px) {
  .topstories .topstory img {
    position: relative;
    top: -300px;
  }
}

@media (max-width: 750px) {
  .topstories .topstory img {
    -webkit-transform: translate3d(0px, 0, 0px) !important;
    transform: translate3d(0px, 0, 0px) !important;
    top: 0;
  }
}

@media (max-width: 350px) {
  .topstories .topstory img {
    max-width: initial !important;
    width: 400px;
  }
}

.topstories .topstory:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 388px;
  text-align: center;
  background: linear-gradient(0deg, #1a202c 0%, rgba(16, 36, 84, 0) 100%);
  transition: all 0.2s;
}

.topstories .topstory .topstory-body {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
  padding-top: 20px;
}

.topstories .topstory-sticker {
  display: inline;
  font-family: BebasNeue;
  color: #fff;
  background-color: #cf072c;
  height: 32px;
  padding: 8px 0;
}

.topstories .topstory-sticker:before {
  content: '';
  width: 32px;
  height: 32px;
  display: inline-block;
  background: url('./assets/sticker.svg') no-repeat;
  background-size: cover;
  vertical-align: top;
  margin-top: -2px;
  margin-left: -20px;
}

.topstories .topstory-sticker:after {
  content: '';
  width: 32px;
  height: 32px;
  display: inline-block;
  background: url('./assets/sticker.svg') no-repeat;
  background-size: cover;
  vertical-align: top;
  margin-top: -2px;
  margin-right: -20px;
  background-position-x: -95px;
}

.topstories .topstory-heading {
  font-family: 'futura-pt';
  font-weight: 900;
  font-size: 42px;
  line-height: 1.19;
  text-align: center;
  color: #fff;
  margin-top: 10px;
}

@media (max-width: 1280px) {
  .topstories .topstory-heading {
    font-size: 30px;
  }
}

@media (max-width: 1050px) {
  .topstories .topstory-heading {
    font-size: 26px;
  }
}

@media (max-width: 899px) {
  .topstories .topstory-heading {
    font-size: 30px;
  }
}

@media (max-width: 440px) {
  .topstories .topstory-heading {
    font-size: 26px;
  }
}

.topstories .topstory-date {
  font-size: 16px;
  line-height: 3.12;
  color: #848c9d;
  font-family: 'futura-pt';
  font-weight: 300;
}

.hp-games {
  padding-top: 64px;
  border-bottom: 1px solid #ededed;
  padding-bottom: 27px;
  margin-bottom: 35px;
}

// @media (max-width:1060px) {
@media (max-width: 1360px) {
  .hp-games .game {
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 50px;
  }

  .hp-games .game:last-child {
    margin-bottom: 0;
  }

  .hp-games .game:first-child {
    border-right: 0;
  }
}

.hp-games .game:first-child {
  border-right: 1px solid #ebebeb !important;
}

.hp-games .game .round {
  font-family: 'futura-pt';
  font-weight: 300;
  font-size: 14px;
  line-height: 1.14;
  text-align: center;
  color: #1b212d;
  margin-bottom: 15px;
}

.hp-games .game .game-box {
  display: table;
  width: 100%;
}

.hp-games .game .game-box .team {
  display: table-cell;
  vertical-align: middle;
}

.hp-games .game .game-box .team .logo,
.hp-games .game .game-box .team span {
  display: inline-block;
  vertical-align: middle;
}

.hp-games .game .game-box .team .logo {
  width: 63px;
}

@media (max-width: 1360px) {
  .hp-games .game .game-box .team .logo {
    //width: 40px
    width: 63px;
  }
}

// @media (max-width:1360px) {
//   .hp-games .game .game-box .team .logo {
//     width: 30px
//   }
// }

// @media (max-width:1360px) {
//   .hp-games .game .game-box .team .logo {
//     display: none
//   }
// }

// @media (max-width:1060px) {
//   .hp-games .game .game-box .team .logo {
//     width: 63px;
//     display: inline-block
//   }
// }

.hp-games .game .game-box .team span {
  font-size: 20px;
  color: #1b212d;
  font-family: 'futura-pt';
  font-weight: 900;
  width: 145px;
  max-width: 100%;
}

@media (max-width: 540px) {
  .hp-games .game .game-box .team span {
    display: none;
  }
}

.hp-games .game .game-box .team-left {
  text-align: right;
  padding-right: 24px;
}

.hp-games .game .game-box .team-left .logo {
  margin-left: 25px;
}

.hp-games .game .game-box .team-right {
  text-align: left;
  padding-left: 24px;
}

.hp-games .game .game-box .team-right .logo {
  margin-right: 25px;
}

.hp-games .game .game-box .score-box {
  width: 100px;
  display: table-cell;
  vertical-align: middle;
}

.hp-games .game .game-box .score-numbers {
  display: table-cell;
  vertical-align: middle;
  background-color: #fff;
  border: solid 1px #dedede;
  position: relative;
  width: 100px;
}

.hp-games .game .game-box .score-numbers .number {
  float: left;
  width: 50%;
  text-align: center;
  font-family: BebasNeue;
  font-size: 40px;
  font-weight: bold;
  line-height: 1.25;
  color: #000;
  padding-top: 4px;
}

.hp-games .game .game-box .score-numbers .number:first-child {
  border-right: 1px solid #ebebeb;
}

.hp-games .game .game-box .score-numbers .divider {
  position: absolute;
  top: 19px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  color: #1b212d;
  width: 13px;
  height: 19px;
  background-color: #fff;
  border: solid 1px #ebebeb;
}

.hp-games .game .game-box .score-halftime {
  border-left: solid 1px #dedede;
  border-right: solid 1px #dedede;
  border-bottom: solid 1px #dedede;
  text-align: center;
  background-color: #fff;
  font-size: 12px;
  line-height: 23px;
  text-align: center;
  color: #1b212d;
}

.hp-games .game .game-box .date-box {
  width: 100px;
  display: table-cell;
  vertical-align: middle;
  background-color: #fff;
  border: solid 1px #dedede;
  text-align: center;
}

.hp-games .game .game-box .date-box .time {
  border-bottom: solid 1px #dedede;
  font-family: BebasNeue;
  font-size: 40px;
  font-weight: bold;
  line-height: 1.25;
  text-align: center;
  color: #1b212d;
  padding-top: 4px;
}

.hp-games .game .game-box .date-box .date {
  font-size: 12px;
  line-height: 23px;
  color: #1b212d;
}

.hp-banner {
  text-align: center;
  margin-bottom: 42px;
}

@media (max-width: 829px) {
  .hp-banner .row .banner:nth-child(1) {
    margin-bottom: 30px;
  }
}

.hp-articles {
  border-bottom: 1px solid #ededed;
  padding-bottom: 10px;
  margin-bottom: 40px;
}

.hp-articles .article {
  margin-bottom: 40px;
}

@media (max-width: 1320px) {
  .hp-articles .article {
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 760px) {
  .hp-articles .article {
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 15px;
  }
}

.hp-articles .article-inner {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.hp-articles .article-photo {
  display: table-cell;
  vertical-align: middle;
  width: 160px;
}

@media (max-width: 760px) {
  .hp-articles .article-photo {
    width: 160px;
  }
}

@media (max-width: 460px) {
  .hp-articles .article-photo {
    width: 20%;
  }
}

@media (max-width: 360px) {
  .hp-articles .article-photo {
    display: none;
  }
}

.hp-articles .article-body {
  display: table-cell;
  vertical-align: top;
  padding-left: 30px;
}

@media (max-width: 360px) {
  .hp-articles .article-body {
    padding-left: 0;
  }
}

.hp-articles .article-category {
  font-size: 16px;
  line-height: 3.12;
  color: #cf072c;
  font-family: BebasNeue;
  font-weight: bold;
}

.hp-articles .article-heading {
  font-size: 24px;
  font-family: 'futura-pt';
  font-weight: 900;
  line-height: 1.25;
  color: #1b212d;
}

.hp-articles .article-date {
  font-family: 'futura-pt';
  font-weight: 300;
  font-size: 14px;
  line-height: 3.57;
  color: #848c9d;
}

.hp-articles .article:active,
.hp-articles .article:focus,
.hp-articles .article:hover {
  text-decoration: none;
}

.hp-articles .article:active .article-heading,
.hp-articles .article:focus .article-heading,
.hp-articles .article:hover .article-heading {
  text-decoration: underline;
}

.hp-multimedia {
  margin-bottom: 39px;
}

@media (max-width: 1260px) {
  .hp-multimedia .multimedia {
    -webkit-flex: 0 0 33.33333%;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

@media (max-width: 700px) {
  .hp-multimedia .multimedia {
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 440px) {
  .hp-multimedia .multimedia {
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.hp-multimedia .multimedia img {
  width: 100%;
}

.hp-multimedia .multimedia-inner {
  position: relative;
  overflow: hidden;
}

.hp-multimedia .multimedia-inner:after {
  display: block;
  content: '';
  width: 100%;
  height: 180px;
  opacity: 1;
  background-image: linear-gradient(to top, #1a202c, rgba(16, 36, 84, 0));
  position: absolute;
  bottom: 0;
  left: 0;
  transition: all 0.2s;
}

.hp-multimedia .multimedia-body {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  padding-left: 19px;
  padding-right: 22px;
  padding-bottom: 18px;
}

.hp-multimedia .multimedia-heading {
  font-family: 'futura-pt';
  font-weight: 900;
  font-size: 18px;
  line-height: 1.22;
  color: #fff;
}

.hp-multimedia .multimedia-count {
  font-family: BebasNeue;
  font-weight: bold;
  font-size: 16px;
  line-height: 2;
  color: #fff;
}

.hp-multimedia .multimedia-gallery:before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url('./assets/gallery_ico.svg') no-repeat;
  background-size: cover;
  vertical-align: middle;
  margin-right: 10px;
}

.hp-multimedia .multimedia-video:before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url('./assets/video_ico.svg') no-repeat;
  background-size: cover;
  vertical-align: middle;
  margin-right: 10px;
}

.hp-multimedia .multimedia:active,
.hp-multimedia .multimedia:focus,
.hp-multimedia .multimedia:hover {
  text-decoration: none;
}

.hp-multimedia .multimedia:active .multimedia-heading,
.hp-multimedia .multimedia:focus .multimedia-heading,
.hp-multimedia .multimedia:hover .multimedia-heading {
  text-decoration: underline;
}

.hp-multimedia .multimedia:active .multimedia-inner:after,
.hp-multimedia .multimedia:focus .multimedia-inner:after,
.hp-multimedia .multimedia:hover .multimedia-inner:after {
  opacity: 0.8;
}

.hp-multimedia .multimedia-play {
  position: relative;
}

.hp-multimedia .multimedia-play:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url('./assets/play_ico.png') no-repeat;
  background-size: 50px 50px;
  background-position: center;
  z-index: 1;
}

.hp-stats {
  padding-bottom: 85px;
}

.hp-stats .stat-box {
  margin-bottom: 20px;
}

@media (max-width: 1260px) {
  .hp-stats {
    padding-bottom: 65px;
  }
}

@media (max-width: 1260px) {
  .hp-stats .stat-box {
    -webkit-flex: 0 0 33%;
    -ms-flex: 0 0 33%;
    flex: 0 0 33%;
    max-width: 33%;
    margin-bottom: 20px;
  }
}

@media (max-width: 940px) {
  .hp-stats .stat-box {
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 450px) {
  .hp-stats .stat-box {
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.hp-stats .stat-box:active,
.hp-stats .stat-box:focus,
.hp-stats .stat-box:hover {
  text-decoration: none;
}

.hp-stats .stat-box-title {
  font-size: 40px;
  line-height: 1;
  color: #1a1b20;
  font-family: BebasNeue;
  font-weight: bold;
}

.hp-stats .stat-box-title span {
  font-size: 20px;
  line-height: 2;
  color: #848c9d;
}

.hp-stats .stat-box-player {
  display: table;
  width: 100%;
}

.hp-stats .stat-box-player-photo {
  display: table-cell;
  vertical-align: middle;
  width: 58px;
}

.hp-stats .stat-box-player-photo img {
  border-radius: 100%;
}

.hp-stats .stat-box-player-data {
  display: table-cell;
  vertical-align: middle;
  padding-left: 16px;
}

.hp-stats .stat-box-player-data .name {
  font-size: 15px;
  line-height: 1.2;
  color: #161616;
  font-family: 'futura-pt';
  font-weight: 300;
}

.hp-stats .stat-box-player-data .surname {
  font-size: 24px;
  line-height: 1;
  color: #161616;
  font-family: 'futura-pt';
  font-weight: 300;
}

.hp-stats .stat-box-player-data .place {
  font-family: 'futura-pt';
  font-weight: 300;
  font-size: 14px;
  line-height: 1.4;
  margin-top: 2px;
  color: #848c9d;
}

.hp-fanshop {
  padding-bottom: 70px;
}

.hp-fanshop .product {
  text-align: center;
  border-right: 1px solid #ededed;
}

@media (max-width: 829px) {
  .hp-fanshop .product {
    margin-bottom: 30px;
  }
}

.hp-fanshop .product:last-child {
  border-right: 0;
}

.hp-fanshop .product:hover {
  text-decoration: none;
}

.hp-fanshop .product:hover .product-name {
  text-decoration: underline;
}

.hp-fanshop .product-name {
  font-family: 'futura-pt';
  font-weight: 900;
  font-size: 18px;
  line-height: 1.22;
  text-align: center;
  color: #1b212d;
  margin-top: 23px;
  margin-bottom: 17px;
}

.hp-fanshop .product-price {
  font-size: 22px;
  line-height: 2.27;
  color: #cf072c;
  font-family: BebasNeue;
  font-weight: bold;
}

.calendar {
  padding: 0;
  border-bottom: 1px solid #ededed;
  padding-bottom: 20px;
  margin-bottom: 39px;
}

.calendar .row {
  margin: 0;
}

.calendar-inner {
  padding: 0;
}

.calendar-slick {
  padding-left: 0px;
  padding-right: 0px;
}

.calendar-slick .slick-day {
  width: 100px;
  position: relative;
  margin-top: 25px;
}

.calendar-slick .day {
  border-left: solid 3px #fff;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: solid 3px #fff;
  border-bottom: solid 3px #fff;
  margin-right: -1.5px;
  margin-left: -1.5px;
  border-right: solid 3px #fff;
}

.calendar-slick .previos {
  border-left: none;
}

.calendar-slick .day .day-number {
  font-size: 28px;
  line-height: 1.07;
  color: #1b212d;
  font-family: 'futura-pt';
  font-weight: 900;
}

.calendar-slick .day .day-name {
  font-size: 14px;
  line-height: 3.57;
  color: #848c9d;
  font-family: BebasNeue;
  font-weight: bold;
}

.calendar-slick .day .day-logo img {
  margin: 0 auto;
}

.calendar-slick .slick-day.home {
  margin: 0;
}

.calendar-slick .slick-day.home .day {
  position: absolute;
  top: 0;
  width: 100%;
  min-height: 150px;
  border: 0;
}

.calendar-slick .slick-day.home .day .day-name {
}

.calendar-slick .slick-day.home .day .day-number {
}

.calendar-slick .slick-day.away {
  margin: 0;
}

.calendar-slick .slick-day.away .day {
  position: absolute;
  top: 0;
  width: 100%;
  min-height: 150px;
  border: 0;
}

.calendar-slick .slick-day.away .day .day-name {
  color: #fff;
}

.calendar-slick .slick-day.away .day .day-number {
  color: #fff;
}

.calendar-slick .slick-day.action {
  margin: 0;
}

.calendar-slick .slick-day.action .day {
  background-color: #d6dbdd;
  position: absolute;
  top: 0;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 16px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.07);
  min-height: 150px;
}

.calendar-slick .slick-day.action .day .day-name {
  color: #1b212d;
}

.calendar-slick .slick-day.action .day .day-number {
  color: #1b212d;
}

.calendar-slick .slick-arrow {
  background-color: #fafafa;
  border: 0;
  position: absolute;
  top: 0;
  width: 50px;
  height: 100%;
  color: transparent;
  z-index: 1000;
  border: solid 1px #ebebeb;
  transition: all 0.2s;
  padding: 0px;
}

@media (max-width: 450px) {
  .calendar-slick .slick-arrow {
    width: 55px;
  }
}

.calendar-slick .slick-prev {
  left: 0;
  background: linear-gradient(
    90deg,
    #c8102e 28.1%,
    rgba(200, 16, 46, 0) 104.58%
  );
  border: none;
  opacity: 0.8;
  outline: none !important;
  width: 140px;
  height: 160px;
  margin-top: 20px;
}

.calendar-slick,
.calendar-slick,
.calendar-slick .slick-prev:hover {
  background: linear-gradient(
    90deg,
    #c8102e 28.1%,
    rgba(200, 16, 46, 0) 104.58%
  );
  outline: none;
  opacity: 1;
}

.calendar-slick .slick-next {
  right: 0;
  background: linear-gradient(
    270deg,
    #c8102e 28.1%,
    rgba(200, 16, 46, 0) 104.58%
  );
  border: none;
  opacity: 0.8;
  outline: none !important;
  width: 140px;
  height: 160px;
  margin-top: 20px;
}

.calendar-slick,
.calendar-slick,
.calendar-slick .slick-next:hover {
  background: linear-gradient(
    270deg,
    #c8102e 28.1%,
    rgba(200, 16, 46, 0) 104.58%
  );
  outline: none;
  opacity: 1;
}

.calendar .calendar-data {
  margin-top: 25px;
  text-align: center;
  position: relative;
}

@media (max-width: 370px) {
  .calendar .calendar-data {
    text-align: left;
  }
}

.calendar .calendar-data .legenda {
  display: inline-block;
  font-size: 14px;
  line-height: 2.14;
  color: #1b212d;
  font-family: 'futura-pt';
  font-weight: 900;
  margin-right: 45px;
}

@media (max-width: 500px) {
  .calendar .calendar-data .legenda {
    margin-right: 25px;
  }
}

@media (max-width: 370px) {
  .calendar .calendar-data .legenda {
    display: block;
  }
}

.calendar .calendar-data .legenda:before {
  display: inline-block;
  content: '';
  width: 16px;
  height: 16px;
  vertical-align: sub;
  margin-right: 9px;
}

.calendar .calendar-data .home:before {
  background-color: #cf072c;
}

.calendar .calendar-data .away:before {
  background-color: #102454;
}

.calendar .calendar-data .action:before {
  background-color: #d6dbdd;
}

.calendar .calendar-data .more {
  position: absolute;
  right: 40px;
  top: 0;
  font-size: 14px;
  line-height: 2.14;
  font-family: 'futura-pt';
  font-weight: 900;
  color: #cf072c;
  text-decoration: underline;
}

@media (max-width: 780px) {
  .calendar .calendar-data .more {
    display: none;
  }
}

.calendar .calendar-data .more:hover {
  text-decoration: none;
}

.subpage .calendar {
  border-bottom: 0;
  margin-bottom: 18px;
  border-top: 1px solid #ededed;
  padding-top: 57px;
}

.box-heading {
  width: 100%;
  border-bottom: 1px solid #ededed;
  font-family: 'futura-pt';
  font-weight: 900;
  font-size: 20px;
  line-height: 1.1;
  color: #1a202c;
  text-transform: uppercase;
  padding-bottom: 25px;
  margin-bottom: 45px;
  position: relative;
}

.box-heading:after {
  content: '';
  display: block;
  width: 20px;
  height: 3px;
  background-color: #cf072c;
  position: absolute;
  left: 0;
  bottom: -2px;
}

.box-heading-more {
  float: right;
  font-size: 14px;
  font-family: 'futura-pt';
  font-weight: 900;
  line-height: 2.14;
  color: #cf072c;
  padding-right: 40px;
  text-decoration: underline;
  text-transform: none;
}

.box-heading-more:active,
.box-heading-more:focus,
.box-heading-more:hover {
  color: #cf072c;
  text-decoration: none;
}

.article-topstory {
  position: relative;
  height: 608px;
  overflow: hidden;
}

@media (max-width: 1210px) {
  .article-topstory {
    height: 480px;
  }
}

@media (max-width: 830px) {
  .article-topstory {
    height: auto;
  }
}

.article-topstory img {
  width: 100%;
}

.article-topstory-body {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-left: 70px;
  padding-right: 332px;
  padding-bottom: 20px;
  z-index: 2000;
}

@media (max-width: 900px) {
  .article-topstory-body {
    padding-right: 70px;
  }
}

@media (max-width: 620px) {
  .article-topstory-body {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.article-topstory-body .sticker {
  display: inline-block;
  font-family: BebasNeue;
  color: #fff;
  background-color: #cf072c;
  height: 32px;
  padding: 5px 0;
  margin-left: 20px;
}

.article-topstory-body .sticker:before {
  content: '';
  width: 32px;
  height: 32px;
  display: inline-block;
  background: url('./assets/sticker.svg') no-repeat;
  background-size: cover;
  vertical-align: top;
  margin-top: -5px;
  margin-left: -20px;
}

.article-topstory-body .sticker:after {
  content: '';
  width: 32px;
  height: 32px;
  display: inline-block;
  background: url('./assets/sticker.svg') no-repeat;
  background-size: cover;
  vertical-align: top;
  margin-top: -5px;
  margin-right: -20px;
  background-position-x: -95px;
}

.article-topstory-body .heading {
  margin-top: 15px;
  margin-bottom: 18px;
}

@media (max-width: 550px) {
  .article-topstory-body .heading {
    font-size: 32px;
  }
}

@media (max-width: 420px) {
  .article-topstory-body .heading {
    font-size: 27px;
  }
}

@media (max-width: 330px) {
  .article-topstory-body .heading {
    font-size: 24px;
  }
}

.article-topstory-body .date {
  font-family: 'futura-pt';
  font-weight: 300;
  font-size: 16px;
  line-height: 1.2;
  color: #848c9d;
}

main {
  font-family: 'futura-pt';
  font-weight: 300;
  font-size: 18px;
  line-height: 1.44;
  color: #1b2026;
}

main .text {
}

main .text a {
  color: #000;
}

main .article-text {
  position: relative;
}

main .article-text .socials {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 64px;
  text-align: center;
  width: 140px;
}

main .article-text .socials #vk_like_vertical {
  margin: 0 auto 30px;
}

@media (max-width: 1180px) {
  main .article-text .socials {
    display: none;
  }
}

main .article-text .text {
  padding: 64px 60px 68px 170px;
}

@media (max-width: 1180px) {
  main .article-text .text {
    padding: 80px 60px 68px 70px;
  }
}

@media (max-width: 620px) {
  main .article-text .text {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 25px;
  }
}

main .article-share {
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  text-align: center;
  position: relative;
  padding-top: 44px;
  padding-bottom: 44px;
}

main .article-share-heading {
  position: absolute;
  left: 20px;
  top: 44px;
  font-family: BebasNeue;
  font-weight: bold;
  font-size: 24px;
  line-height: 1.17;
  color: #1b2026;
}

@media (max-width: 790px) {
  main .article-share-heading {
    display: none;
  }
}

main .article-share .socials > div {
  display: inline-block;
  vertical-align: -45%;
  width: 150px;
  height: 31px;
  overflow: hidden;
}

main .article-share .socials div.fb {
  margin-left: 2px;
}

main .article-share .socials div.tw {
  width: 100px;
  margin-left: 2px;
}

main .said {
  border-left: 2px solid #c01d22;
  padding-left: 34px;
  padding-right: 34px;
  margin-bottom: 20px;
}

main .said .said-text {
  font-size: 27.3px;
  font-weight: 300;
  line-height: 1.4;
  color: #271c1a;
  margin-bottom: 20px;
  padding-top: 20px;
}

main .said .said-person {
  font-size: 18px;
  font-weight: bold;
  line-height: 2.22;
  color: #8a8e97;
}

main .said .said-person .number {
  color: #cc181f;
}

main .said .said-person .name {
  color: #271c1a;
}

main .said .said-person span {
  display: inline-block;
  margin-right: 5px;
}

main .person {
  text-align: center;
  background-color: #fff;
  border: solid 1px #ededed;
  float: left;
  width: 190px;
  max-width: 100%;
  padding: 19px 26px;
  margin-right: 37px;
  margin-bottom: 37px;
}

@media (max-width: 500px) {
  main .person {
    float: none;
    width: 100%;
    margin-right: 0;
  }
}

main .person-heading img {
  border-radius: 100%;
}

main .person-name {
  font-size: 24px;
  font-weight: bold;
  line-height: 1;
  color: #1b2026;
  margin-top: 18px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 18px;
}

main .person-name span {
  font-size: 16px;
  line-height: 1.5;
  display: block;
}

main .person-text {
  font-family: Arial;
  font-size: 14px;
  line-height: 1.43;
  color: #1b2026;
}

main h1,
main h2,
main h3,
main h4,
main h5,
main h6 {
  font-family: 'futura-pt';
  font-weight: 900;
  color: #000;
}

main h1 {
  margin-bottom: 30px;
}

.text {
  padding: 50px 16px 0;
}

.text form#form_filter {
}

.text form#form_filter .formElementHolder {
  float: left;
  margin-right: 10px;
}

.text form#form_filter .formElementHolder label {
  font-weight: bold;
  padding: 7px 6px 5px;
}

.text form#form_filter .formElementHolder input[type='text'] {
  width: 160px;
  padding: 5px 12px;
}

.text .small-box.articles {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 29px;
}

.text .small-box.articles .item {
  overflow: hidden;
}

.text .small-box.articles .item .img {
  float: left;
  background: #cf072c no-repeat 50% 25%;
  background-size: cover;
}

.text .small-box.articles .item .text {
  line-height: 1.3;
  padding: 0 0 0 80px;
}

.text .small-box.articles .item .text .date {
  font-size: 14.1px;
  line-height: 1;
  color: #a6a6a6;
  margin-bottom: 3px;
}

.text .small-box.articles .item .text a {
  font-size: 16px;
  font-weight: bold;
  padding-right: 16px;
}

.text .small-box.articles .item .text a[rel='external'] {
  background: url('./assets/ico_external.png') no-repeat bottom right;
}

.text .box.related .small-box.articles {
  clear: both;
}

.text .box.related .small-box.articles:after {
  content: '';
  clear: both;
  display: block;
}

.text .box.related .small-box.articles .item {
  margin: 4px 0 12px;
  width: 50%;
  float: left;
  min-height: 110px;
}

@media (max-width: 1345px) {
  .text .box.related .small-box.articles .item {
    float: none;
    width: 100%;
  }
}

.text .box.related .small-box.articles .item .text a {
  display: block;
}

.text form fieldset {
  border: 1px solid #ededed;
}

.text form legend {
  color: #aeaeae;
  padding: 3px 15px;
  margin-left: 10px;
  font-size: 0.9em;
}

.text .button {
  padding: 7px 5px 6px;
  border: 1px solid #a6a6a6;
  background: #eeedef;
  color: #1b212d;
}

.text .button:active,
.text .button:focus,
.text .button:hover {
  text-decoration: none;
  background: #a6a6a6;
}

.text .strankovani {
  margin: 0 0 40px;
  text-align: center;
}

.text .strankovani .nextPages {
  display: none;
}

.text .strankovani a {
  display: inline;
  border: 2px solid #cf072c;
  padding: 7px 23px 6px;
  line-height: 35px;
  background: white !important;
  color: black !important;
  text-transform: uppercase;
  text-decoration: none !important;
  font-size: 12px;
  font-weight: bold;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
  margin-right: -2px;
  position: relative;
}

.text .strankovani a.strankovaniVyrazne {
  background: #cf072c !important;
  color: #fff !important;
}

.text .strankovani a.strankovaniSipky {
  border-color: transparent;
  position: static;
}

.text table.schedule {
  margin-top: 5px;
}

.text table.schedule td {
  padding: 12px 4px 12px 18px;
  vertical-align: middle;
}

.text table.schedule td.logos {
  white-space: nowrap;
  padding: 11px 32px 7px 16px;
}

@media (max-width: 1000px) {
  .text table.schedule td.logos {
    padding-right: 30px;
  }
}

@media (max-width: 850px) {
  .text table.schedule td.logos {
    padding-right: 23.5px;
  }
}

@media (max-width: 700px) {
  .text table.schedule td.logos {
    padding-right: 17px;
  }
}

@media (max-width: 550px) {
  .text table.schedule td.logos {
    padding-right: 10.5px;
  }
}

@media (max-width: 400px) {
  .text table.schedule td.logos {
    padding-right: 4px;
  }
}

.text table.schedule td.logos img.logo {
  max-width: 52px;
  max-height: 52px;
  margin: 3px 0 0 11px;
}

@media (max-width: 1350px) {
  .text table.schedule td.logos img.logo {
    max-width: 38px;
    max-height: 38px;
  }
}

@media (max-width: 1300px) {
  .text table.schedule td.logos img.logo {
    max-width: 30px;
    max-height: 30px;
  }
}

@media (max-width: 770px) {
  .text table.schedule td.logos {
    display: none;
  }
}

.text table.schedule td.teams {
  line-height: 1.6;
}

.text table.schedule td.teams .info {
  color: #a6a6a6;
}

@media (max-width: 810px) {
  .text table.schedule td.teams .names span {
    display: none;
  }

  .text table.schedule td.teams .names strong {
    display: block;
  }
}

.text table.schedule td.score {
  width: 1%;
  line-height: 40.5px;
  white-space: nowrap;
  font-family: 'futura-pt';
  font-weight: 900;
}

.text table.schedule td.score strong {
  font-size: 40.5px;
  vertical-align: top;
}

.text table.schedule td.score a {
  text-decoration: none;
  font-family: 'futura-pt';
  font-weight: 900;
}

.text table.schedule td.score span {
  display: inline-block;
  padding-left: 16px;
  font-size: 20.25px;
  line-height: 1;
  vertical-align: -14%;
}

@media (max-width: 960px) {
  .text table.schedule td.score span {
    display: none;
  }
}

.text table.schedule td.buttons {
  width: 164px;
  white-space: nowrap;
}

.text table.schedule td.buttons .button {
  font-weight: bold;
  font-size: 16px;
  padding: 4px 11px;
  margin-right: 4px;
  background: #cf072c;
  color: #fff;
  border: 0;
}

.text table.schedule td.buttons .button:hover {
  background: #a90725;
}

@media (max-width: 600px) {
  .text table.schedule td.buttons {
    white-space: normal;
    text-align: right;
  }

  .text table.schedule td.buttons .button {
    display: inline-block;
    white-space: nowrap;
    margin: 1px auto;
  }
}

@media (max-width: 500px) {
  .text table.schedule td.buttons {
    display: none;
  }
}

.menicko {
  padding: 16px 24px 10px;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.2;
  text-transform: uppercase;
  border-top: 1px solid #f1f0f4;
  border-bottom: 1px solid #f1f0f4;
}

.menicko.toright {
  border: 0;
  margin: 0 15px 15px 30px;
  padding: 0;
  text-align: right;
}

.text tr td,
.text tr th {
  vertical-align: top;
}

.text tr td.left,
.text tr th.left {
  text-align: left;
}

.text tr td.right,
.text tr th.right {
  text-align: right;
}

.text tr td.center,
.text tr th.center {
  text-align: center;
}

.text tr td:first-child,
.text tr th:first-child {
  padding-left: 37px;
}

.text tr th {
  padding-bottom: 16px;
  font-size: 18px;
  text-transform: uppercase;
  text-align: left;
}

@media (max-width: 750px) {
  .text .table {
    display: block;
    overflow-x: scroll;
  }
}

.text td,
.text th {
  white-space: nowrap;
}

.text .table.right tr th {
  text-align: left;
}

.text .table.right.center tr th {
  text-align: center;
}

.text .table.right.right tr th {
  text-align: right;
}

.text .box h3.header {
  padding: 16px 6px 28px;
}

.text .buttons.toright {
  margin: -51px 0 0;
}

.text .toright {
  float: right;
}

.text .box.photogallery {
  overflow: hidden;
}

.text .box.photogallery a.photo {
  width: 33.33333%;
  height: 197px;
  float: left;
  background: #ededed no-repeat center 25%;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

@media screen and (max-width: 1229px) {
  .text .box.photogallery a.photo {
    width: 25%;
  }
}

@media screen and (max-width: 1180px) {
  .text .box.photogallery a.photo {
    width: 33.33333%;
  }
}

@media screen and (max-width: 780px) {
  .text .box.photogallery a.photo {
    width: 50%;
  }
}

@media screen and (max-width: 550px) {
  .text .box.photogallery a.photo {
    width: 100%;
  }
}

.text .box.photogallery a.photo.video {
  background-position: center center;
  background-size: 120% auto;
}

.text .box.photogallery a.photo h2 {
  z-index: 2;
  position: absolute;
  display: block;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding: 50px 10px 10px;
  text-transform: uppercase;
  font-size: 13px;
  color: white;
  background: linear-gradient(
    to bottom,
    rgba(9, 22, 45, 0) 11%,
    rgba(9, 22, 45, 0.83) 71%,
    #081328 96%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5209162d', endColorstr='#081328', GradientType=0);
}

.text .box.photogallery a.photo span {
  z-index: 3;
  position: relative;
  display: block;
  height: 100%;
  background: url('./assets/expand.png') no-repeat center center;
  background: rgba(207, 7, 44, 0.85) url('./assets/expand.png') no-repeat center
    center;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.text .box.photogallery a.photo span h2 {
  background: none;
}

.text .box.photogallery a.photo:hover span {
  opacity: 1;
}

.text .small-box {
  margin: 0 20px 21px;
  padding: 0 0 20px;
  border-bottom: 1px solid #ededed;
  clear: both;
}

.text .small-box h3.header {
  margin: 0 0 0 -21px;
}

.text .small-box.voting {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 10px;
  padding-right: 5px;
  margin-top: 20px;
}

.text .small-box.voting .answers {
  text-align: center;
  margin: 0 -13px;
}

.text .small-box.voting .answers .answer {
  display: inline-block;
  box-sizing: border-box;
  padding: 15px 15px 20px;
  text-align: left;
}

.text .small-box.voting .answers.cols1 .answer {
  width: 100%;
}

.text .small-box.voting .answers.cols2 .answer {
  width: 50%;
}

.text .small-box.voting .answers.cols3 .answer {
  width: 33.33333%;
}

.text .small-box.voting .answers.cols4 .answer {
  width: 25%;
}

.text .small-box.voting .answers.cols5 .answer {
  width: 20%;
}

.text .small-box.voting .answers.cols6 .answer {
  width: 16.66667%;
}

@media (max-width: 980px) {
  .text .small-box.voting .answers.cols4 .answer {
    width: 50%;
  }

  .text .small-box.voting .answers.cols5 .answer {
    width: 33.33333%;
  }

  .text .small-box.voting .answers.cols6 .answer {
    width: 33.33333%;
  }
}

@media (max-width: 700px) {
  .text .small-box.voting .answers.cols3 .answer {
    width: 50%;
  }

  .text .small-box.voting .answers.cols5 .answer {
    width: 50%;
  }

  .text .small-box.voting .answers.cols6 .answer {
    width: 50%;
  }
}

@media (max-width: 600px) {
  .text .small-box.voting .answers .answer {
    width: 100% !important;
  }
}

.text .small-box.voting .answer {
  line-height: 20px;
}

.text .small-box.voting .answer input {
  display: none;
}

.text .small-box.voting .answer label {
  display: block;
  padding: 0;
  line-height: inherit;
}

.text .small-box.voting .answer strong {
  float: right;
  padding-left: 8px;
}

.text .small-box.voting .answer input + label::before {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('./assets/checkbox.png') no-repeat bottom left;
  content: ' ';
  padding-right: 10px;
  vertical-align: top;
  margin-top: -1px;
}

.text .small-box.voting .answer input:checked + label::before {
  background: url('./assets/checkbox.png') no-repeat top left;
}

.text .small-box.voting .graph {
  clear: both;
  margin: 8px 0 0;
  height: 4px;
  background-color: #ededed;
  overflow: hidden;
}

.text .small-box.voting .graph div {
  background-color: #cf072c;
  height: 100%;
}

.text .small-box.voting .hlasuj_box {
  margin-top: 25px;
}

.text .small-box.voting .hlasuj_box input {
  display: none;
}

.text .small-box.voting.player .img {
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background: #cf072c no-repeat center 5%;
  background-size: cover;
  vertical-align: middle;
  margin: -4px 6px 0;
}

.text .small-box.voting.player .player {
  margin: 10px 30px 20px;
  display: inline-block;
}

.player-card {
  height: 700px;
  position: relative;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

@media (max-width: 510px) {
  .player-card {
    height: 400px;
  }
}

.player-card-heading {
  bottom: 0;
  left: 0;
  width: 100%;
  display: table;
  z-index: 10000;
}

.player-card-photo {
  display: table-cell;
  vertical-align: top;
  width: 200px;
}

@media (max-width: 1250px) {
  .player-card-photo {
    width: 150px;
  }
}

@media (max-width: 920px) {
  .player-card-photo {
    display: none;
  }
}

.player-card-data {
  display: table-cell;
  vertical-align: top;
  padding-left: 65px;
}

@media (max-width: 1410px) {
  .player-card-data {
    padding-left: 30px;
  }
}

@media (max-width: 920px) {
  .player-card-data {
    padding-left: 0;
  }
}

.player-card-data .number {
  display: inline-block;
  font-size: 220px;
  font-family: BebasNeue;
  font-weight: bold;
  line-height: 1;
  color: #1a202c;
}

@media (max-width: 710px) {
  .player-card-data .number {
    font-size: 110px;
  }
}

@media (max-width: 430px) {
  .player-card-data .number {
    font-size: 80px;
  }
}

@media (max-width: 370px) {
  .player-card-data .number {
    display: block;
  }
}

.player-card-data .names {
  display: inline-block;
  vertical-align: top;
  margin-top: 15px;
  margin-left: 35px;
}

@media (max-width: 710px) {
  .player-card-data .names {
    margin-top: 0;
  }
}

@media (max-width: 430px) {
  .player-card-data .names {
    margin-left: 15px;
  }
}

@media (max-width: 370px) {
  .player-card-data .names {
    display: block;
    margin-left: 0;
  }
}

.player-card-data .names .sticker {
  display: inline;
  font-family: BebasNeue;
  color: #fff;
  background-color: #cf072c;
  height: 32px;
  padding: 7px 0 6px;
  margin-left: 18px;
}

.player-card-data .names .sticker:before {
  content: '';
  width: 32px;
  height: 32px;
  display: inline-block;
  background: url('./assets/sticker.svg') no-repeat;
  background-size: cover;
  vertical-align: top;
  margin-top: -2px;
  margin-left: -20px;
}

.player-card-data .names .sticker:after {
  content: '';
  width: 32px;
  height: 32px;
  display: inline-block;
  background: url('./assets/sticker.svg') no-repeat;
  background-size: cover;
  vertical-align: top;
  margin-top: -2px;
  margin-right: -20px;
  background-position-x: -95px;
}

.player-card-data .names .name {
  font-family: 'futura-pt';
  font-size: 40px;
  font-weight: 900;
  line-height: 1.5;
  color: #1a202c;
}

@media (max-width: 710px) {
  .player-card-data .names .name {
    font-size: 22px;
  }
}

.player-card-data .names .surname {
  font-family: 'futura-pt';
  font-size: 70px;
  font-weight: 900;
  line-height: 0.86;
  color: #1a202c;
}

@media (max-width: 710px) {
  .player-card-data .names .surname {
    font-size: 34px;
  }
}

.player-card:before {
  display: block;
  content: '';
  width: 100%;
  height: 85%;
  background-image: linear-gradient(to top, #00256d, rgba(255, 255, 255, 0));
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1000;
}

@media (max-width: 510px) {
  .player-card:before {
    height: 50%;
  }
}

.player-card-bio {
  position: relative;
  top: 0px;
  z-index: 10000;
}

@media (max-width: 710px) {
  .player-card-bio {
    top: 0;
  }
}

.player-card-bio .bio-box {
  display: inline-block;
  margin-right: 56px;
  margin-bottom: 20px;
}

.player-card-bio .bio-box-heading {
  font-size: 16px;
  font-family: BebasNeue;
  font-weight: bold;
  line-height: 1.75;
}

.player-card-bio .bio-box-data {
  font-family: 'futura-pt';
  font-weight: 300;
  font-size: 24px;
  line-height: 1.17;
  color: #1a202c;
}

.tab-bar {
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  padding: 0 20px;
}

.tab-bar a {
  font-family: BebasNeue;
  font-size: 20px;
  font-weight: bold;
  line-height: 1;
  color: #1a202c;
  margin-right: 40px;
  position: relative;
  display: inline-block;
  padding: 30px 0 26px;
}

.tab-bar a:after {
  content: '';
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #cf072c;
  opacity: 0;
  transition: opacity 0.2s;
}

.tab-bar a.active,
.tab-bar a:active,
.tab-bar a:focus,
.tab-bar a:hover {
  color: #cf072c;
  text-decoration: none;
}

.tab-bar a.active:after,
.tab-bar a:active:after,
.tab-bar a:focus:after,
.tab-bar a:hover:after {
  opacity: 1;
}

.article-text {
  min-height: 260px;
}

.article-text .player-text {
  display: none;
}

.article-text .player-text-show {
  display: block;
}

.text .scorebox {
  background: #f7f7f7;
  border: 1px solid #ededed;
  margin: 39px 0 -7px;
  padding: 15px 0 14px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  overflow: hidden;
  position: relative;
  margin-bottom: 50px;
}

.text .scorebox .date {
  border-bottom: 1px solid #ededed;
  font-size: 20px;
  padding-bottom: 12px;
  margin: 0 53px 15px;
}

.text .scorebox .score-wrapper {
  position: relative;
}

.text .scorebox .logo {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: 300px;
  font-weight: bold;
  font-size: 21px;
}

@media (max-width: 1430px) {
  .text .scorebox .logo {
    width: 250px;
  }
}

.text .scorebox .logo span {
  display: inline-block;
  width: 115px;
  vertical-align: middle;
  line-height: 1;
}

@media (max-width: 1430px) {
  .text .scorebox .logo span {
    width: 115px;
  }
}

.text .scorebox .logo img {
  max-height: 91px;
  max-width: 91px;
  vertical-align: middle;
}

.text .scorebox .logo.right img {
  margin-left: 32px;
}

.text .scorebox .logo.left img {
  margin-right: 32px;
}

@media (max-width: 1300px) {
  .text .scorebox .logo {
    padding-top: 100px;
    width: 150px;
    vertical-align: top;
  }

  .text .scorebox .logo span {
    width: auto;
    display: block;
  }

  .text .scorebox .logo.right span {
    margin-right: 18px;
  }

  .text .scorebox .logo.left span {
    margin-left: 18px;
  }

  .text .scorebox .logo img {
    position: absolute;
    top: 0;
    left: 0;
  }

  .text .scorebox .logo.right img {
    left: auto;
    right: 0;
  }
}

@media (max-width: 750px) {
  .text .scorebox .logo {
    width: 160px;
    font-size: 40px;
    padding-top: 80px;
    text-align: center;
  }

  .text .scorebox .logo.right span {
    margin-right: 0;
  }

  .text .scorebox .logo.left span {
    margin-left: 0;
  }

  .text .scorebox .logo img {
    max-height: 71px;
    max-width: 71px;
    left: 50% !important;
    right: auto !important;
    margin-left: -35.5px !important;
    margin-right: 0 !important;
  }
}

@media (max-width: 580px) {
  .text .scorebox .logo {
    width: 120px;
    font-size: 30px;
  }
}

@media (max-width: 480px) {
  .text .scorebox .logo {
    margin: 80px 0 0;
    width: 45%;
  }

  .text .scorebox .logo.right {
    margin-right: 5%;
  }

  .text .scorebox .logo.left {
    margin-left: 5%;
  }
}

.text .scorebox .score {
  display: inline-block;
  vertical-align: middle;
  width: 200px;
  font-size: 45px;
  line-height: 1.2;
}

.text .scorebox .score .periods {
  color: #80858f;
  font-size: 14px;
  line-height: 1.4;
}

@media (max-width: 580px) {
  .text .scorebox .score {
    width: 170px;
    font-size: 50px;
  }

  .text .scorebox .score .periods {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .text .scorebox .score {
    width: 250px;
    position: absolute;
    left: 50%;
    margin-left: -125px;
    top: -10px;
  }
}

.text.magazin li,
.text.magazin ul,
.text.wallpapers li,
.text.wallpapers ul {
  display: block;
  padding: 0;
  margin: 0;
  list-style: none;
  overflow: hidden;
}

.text.magazin ul li,
.text.wallpapers ul li {
  color: #000;
  font-size: 0.9em;
  margin: 0 0 5px;
  padding: 10px;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 240px;
  min-height: 274px;
  text-align: center;
  line-height: 1.4em;
}

.text.magazin h2,
.text.magazin h3,
.text.magazin ul,
.text.wallpapers h2,
.text.wallpapers h3,
.text.wallpapers ul {
  clear: both;
  display: block;
}

.text.magazin h2,
.text.wallpapers h2 {
  cursor: pointer;
  color: #666;
  text-decoration: underline;
}

.text.magazin h2:hover,
.text.wallpapers h2:hover {
  color: black;
}

.text.magazin h2.active,
.text.wallpapers h2.active {
  cursor: default;
  color: black;
  text-decoration: none;
}

.text.magazin h3,
.text.wallpapers h3 {
  margin-top: 0;
}

.text.magazin a,
.text.wallpapers a {
  padding-left: 0;
  background: none;
}

.text.magazin.wallpapers ul li,
.text.wallpapers.wallpapers ul li {
  width: 360px;
  min-height: auto;
}

.text.magazin.wallpapers a,
.text.wallpapers.wallpapers a {
  display: inline-block;
  margin: 3px 5px;
}

.text.magazin.wallpapers ul li div,
.text.wallpapers.wallpapers ul li div {
  padding: 0 40px;
}

aside .box {
  margin-bottom: 28px;
}

aside .box-heading {
  margin-bottom: 0;
}

aside .box .links .dropdown-item {
  font-family: 'futura-pt';
  font-weight: 900;
  font-size: 18px;
  line-height: 1.35;
  color: #1b212d;
  padding: 0;
  border-bottom: 1px solid #ededed;
  position: relative;
  padding-left: 60px;
  white-space: initial;
  padding-top: 12px;
  padding-bottom: 12px;
}

aside .box .links .dropdown-item em {
  text-transform: lowercase;
  font-style: normal;
  color: #80858f;
}

aside .box .links .dropdown-item:before {
  content: '';
  display: block;
  width: 10px;
  height: 3px;
  background-color: #ededed;
  position: absolute;
  top: 24px;
  left: 32px;
  transition: all 0.2s;
}

aside .box .links .dropdown-item.active,
aside .box .links .dropdown-item:active,
aside .box .links .dropdown-item:focus,
aside .box .links .dropdown-item:hover {
  color: #cf072c;
  background: transparent;
}

aside .box .links .dropdown-item.active:before,
aside .box .links .dropdown-item:active:before,
aside .box .links .dropdown-item:focus:before,
aside .box .links .dropdown-item:hover:before {
  background-color: #cf072c;
}

aside .box-partners .partners-inner:after {
  content: '';
  display: table;
  clear: both;
}

aside .box-partners .partners-inner .partner {
  display: inline-block;
  width: 50%;
  float: left;
  padding: 10px;
  max-width: 140px;
}

aside .box-partners .partners-inner .partner-logo {
  border: solid 1px rgba(39, 41, 47, 0.1);
  text-align: center;
}

aside .box-related-articles {
  margin-bottom: 20px;
}

aside .box-related-articles .articles-list {
  padding-left: 0;
  padding-right: 0;
  padding-top: 28px;
}

aside .box-related-articles .articles-list .article {
  margin-bottom: 30px;
  display: block;
}

aside .box-related-articles .articles-list .article-category {
  font-size: 16px;
  line-height: 1.2;
  color: #cf072c;
  font-family: BebasNeue;
  font-weight: bold;
  margin-bottom: 8px;
}

aside .box-related-articles .articles-list .article-heading {
  font-family: 'futura-pt';
  font-weight: 900;
  font-size: 24px;
  line-height: 1.25;
  color: #1b212d;
}

aside .box-related-articles .articles-list .article-date {
  font-family: 'futura-pt';
  font-weight: 300;
  font-size: 14px;
  line-height: 3.57;
  color: #848c9d;
}

aside .box-related-articles .articles-list .article:active,
aside .box-related-articles .articles-list .article:focus,
aside .box-related-articles .articles-list .article:hover {
  text-decoration: none;
}

aside .box-related-articles .articles-list .article:active .article-heading,
aside .box-related-articles .articles-list .article:focus .article-heading,
aside .box-related-articles .articles-list .article:hover .article-heading {
  text-decoration: underline;
}

aside .box-stats .stats {
  padding-top: 36px;
  padding-bottom: 36px;
}

aside .box-stats .stats .stat-box {
  margin-bottom: 40px;
  display: block;
}

aside .box-stats .stats .stat-box:active,
aside .box-stats .stats .stat-box:focus,
aside .box-stats .stats .stat-box:hover {
  text-decoration: none;
}

aside .box-stats .stats .stat-box-title {
  font-size: 40px;
  line-height: 1;
  color: #1a1b20;
  font-family: BebasNeue;
  font-weight: bold;
}

aside .box-stats .stats .stat-box-title span {
  font-size: 20px;
  line-height: 2;
  color: #848c9d;
}

aside .box-stats .stats .stat-box-player {
  display: table;
  width: 100%;
}

aside .box-stats .stats .stat-box-player-photo {
  display: table-cell;
  vertical-align: middle;
  width: 58px;
}

aside .box-stats .stats .stat-box-player-photo img {
  border-radius: 100%;
}

aside .box-stats .stats .stat-box-player-data {
  display: table-cell;
  vertical-align: middle;
  padding-left: 16px;
}

aside .box-stats .stats .stat-box-player-data .name {
  font-size: 15px;
  line-height: 1.2;
  color: #161616;
  font-family: 'futura-pt';
  font-weight: 300;
}

aside .box-stats .stats .stat-box-player-data .surname {
  font-size: 24px;
  line-height: 1;
  color: #161616;
  font-family: 'futura-pt';
  font-weight: 300;
}

aside .box-stats .stats .stat-box-player-data .place {
  font-family: 'futura-pt';
  font-weight: 300;
  font-size: 14px;
  line-height: 1.4;
  margin-top: 2px;
  color: #848c9d;
}

aside .box-fanshop .products {
  padding-top: 35px;
}

aside .box-fanshop .product {
  text-align: center;
  display: block;
  margin-bottom: 70px;
}

aside .box-fanshop .product:active,
aside .box-fanshop .product:focus,
aside .box-fanshop .product:hover {
  text-decoration: none;
}

aside .box-fanshop .product:active .product-name,
aside .box-fanshop .product:focus .product-name,
aside .box-fanshop .product:hover .product-name {
  text-decoration: underline;
}

aside .box-fanshop .product-name {
  font-family: 'futura-pt';
  font-weight: 900;
  font-size: 18px;
  line-height: 1.22;
  text-align: center;
  color: #1b212d;
  margin-top: 23px;
  margin-bottom: 17px;
}

aside .box-fanshop .product-price {
  font-size: 22px;
  line-height: 2.27;
  color: #cf072c;
  font-family: BebasNeue;
  font-weight: bold;
}

aside .small-box.text {
  margin-bottom: 6px;
  border-bottom: 0;
}

aside .small-box.text h3.header {
  font-family: BebasNeue;
  font-weight: bold;
}

aside .small-box.text h4 {
  color: #cf072c;
  font-family: BebasNeue;
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
  margin: 25px 0 14px;
}

aside .small-box.text h3.header + h4 {
  margin-top: 11px;
}

aside .small-box.text p {
  font-size: 14px;
  line-height: 1.58;
  margin: 0 45px 8px 0;
}

aside .small-box.stats {
  border-bottom: 0;
}

aside .small-box.stats h3.header {
  font-family: BebasNeue;
  font-weight: bold;
}

aside .small-box.stats .item {
  width: 50%;
  float: left;
  padding-bottom: 17px;
}

aside .small-box.stats .item span,
aside .small-box.stats .item strong {
  display: block;
  text-transform: uppercase;
  line-height: 1.2;
  font-family: BebasNeue;
  font-weight: bold;
}

aside .small-box.stats .item span {
  color: #cf072c;
  font-family: BebasNeue;
  font-weight: bold;
  font-size: 16px;
  font-weight: bold;
  margin-top: 9px;
}

aside .small-box.stats .item strong {
  font-size: 40px;
  line-height: 1.14;
}

aside .small-box.stats .item strong.small {
  font-size: 20px;
  line-height: 1;
  padding-top: 9px;
}

aside .small-box.stats .item.clear {
  overflow: hidden;
  float: none;
  width: auto;
}

.footer .partners-box {
  padding-top: 37px;
  padding-bottom: 42px;
  border-bottom: 1px solid rgba(48, 56, 73, 0.15);
}

footer .footer .partners-box .partners-heading {
  font-size: 16px;
  font-family: 'futura-pt';
  font-weight: 900;
  line-height: 1;
  color: #fff;
  text-align: center;
  margin-bottom: 12px;
}

footer .footer .partners {
  width: 100%;
  text-align: center;
}

@media (max-width: 500px) {
  footer .footer .partners {
    display: block;
  }

  footer .footer .partners tbody,
  footer .footer .partners tr {
    display: block;
    width: 100%;
  }
}

footer .footer .partners td {
  padding: 20px 10px;
  vertical-align: top;
}

@media (max-width: 500px) {
  footer .footer .partners td {
    display: block;
    text-align: center;
    width: 100%;
  }

  footer .footer .partners td a {
    width: 100%;
  }
}

footer .footer .partners td a {
  display: block;
}

footer .footer .partners td img {
  -webkit-filter: brightness(0) invert(0);
  filter: brightness(0) invert(0);
  opacity: 0.4;
}

footer .footer .partners td img:hover {
}

footer .newsletter {
  display: table;
  width: 100%;
  border-bottom: 1px solid rgba(48, 56, 73, 0.15);
  padding-top: 39px;
  padding-bottom: 45px;
}

@media (max-width: 750px) {
  footer .newsletter {
    display: none;
  }
}

footer .newsletter .title {
  font-size: 32px;
  font-family: 'futura-pt';
  font-weight: 900;
  line-height: 1;
  color: #fff;
  display: table-cell;
  vertical-align: middle;
}

@media (max-width: 960px) {
  footer .newsletter .title {
    display: none;
  }
}

footer .newsletter .desc {
  display: table-cell;
  vertical-align: middle;
  font-family: 'futura-pt';
  font-weight: 300;
  font-size: 20px;
  line-height: 1.1;
  color: #fff;
}

@media (max-width: 1345px) {
  footer .newsletter .desc {
    display: none;
  }
}

footer .newsletter .form {
  display: table-cell;
  vertical-align: middle;
}

footer .newsletter .form input[type='text'] {
  background-color: #a0102b;
  border: solid 1px rgba(0, 0, 0, 0.4);
  font-family: 'futura-pt';
  font-weight: 300;
  font-size: 16px;
  line-height: 1.88;
  color: #fff;
  padding: 9px 25px;
  height: 50px;
  float: left;
  width: 288px;
}

footer .newsletter .form input[type='text']::-webkit-input-placeholder {
  color: #fff;
}

footer .newsletter .form input[type='text']:focus {
  outline: 0;
}

footer .newsletter .form input[type='submit'] {
  font-family: 'futura-pt';
  font-weight: 900;
  font-size: 14px;
  line-height: 2.14;
  letter-spacing: 1.4px;
  color: #fff;
  height: 50px;
  background: url('./assets/mail-ico.svg') no-repeat #1b212d;
  background-size: 16px 12px;
  background-position: 28px center;
  text-transform: uppercase;
  border: 0;
  float: left;
  padding-left: 87px;
  padding-right: 88px;
}

footer .socials {
  display: table;
  width: 100%;
  border-bottom: 1px solid rgba(48, 56, 73, 0.15);
  padding-top: 39px;
  padding-bottom: 45px;
}

footer .socials .title {
  font-size: 32px;
  font-family: 'futura-pt';
  font-weight: 900;
  line-height: 1;
  color: #fff;
  display: table-cell;
  vertical-align: middle;
}

@media (max-width: 960px) {
  footer .socials .title {
    display: none;
  }
}

footer .socials .desc {
  display: table-cell;
  vertical-align: middle;
  font-family: 'futura-pt';
  font-weight: 300;
  font-size: 20px;
  line-height: 1.1;
  color: #fff;
}

@media (max-width: 1345px) {
  footer .socials .desc {
    display: none;
  }
}

footer .socials .icons {
  display: table-cell;
  vertical-align: middle;
}

@media (max-width: 960px) {
  footer .socials .icons {
    text-align: center;
  }
}

footer .socials .icons a {
  display: inline-block;
  margin: 0 15px;
}

footer .footer-esports,
footer .footer-tellinger {
  font-family: 'futura-pt';
  font-weight: 300;
  font-size: 16px;
  color: #fff;
  padding-top: 38px;
  padding-bottom: 38px;
}

@media (max-width: 750px) {
  footer .footer-esports,
  footer .footer-tellinger {
    display: block;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center !important;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

footer .footer-esports a,
footer .footer-tellinger a {
  color: #fff;
  text-decoration: underline;
}

footer .footer-esports a:hover,
footer .footer-tellinger a:hover {
  text-decoration: none;
}

footer .footer-tellinger {
  text-align: right;
}

footer .footer-tellinger img {
  margin-left: 3px;
  margin-right: 3px;
}

footer .scrollremember span {
  color: #fff;
}
// .footer .partners-box{padding-top:37px;padding-bottom:42px;border-bottom:1px solid rgba(48, 56, 73, 0.15)}
// footer .footer .partners-box .partners-heading{font-size:16px;font-family:"futura-pt";font-weight:900;line-height:1;color:#fff;text-align:center;margin-bottom:12px}
// footer .footer .partners{width:100%;text-align:center}
// @media (max-width:500px){footer .footer .partners{display:block}
// footer .footer .partners tbody,footer .footer .partners tr{display:block;width:100%}}
// footer .footer .partners td{padding:20px 10px;vertical-align:top}
// @media (max-width:500px){footer .footer .partners td{display:block;text-align:center;width:100%}
// footer .footer .partners td a{width:100%}}
// footer .footer .partners td a{display:block}
// footer .footer .partners td img{-webkit-filter:brightness(0) invert(0);filter:brightness(0) invert(0);opacity:0.4}
// footer .footer .partners td img:hover{}

// footer .newsletter{display:table;width:100%;border-bottom:1px solid rgba(48, 56, 73, 0.15);padding-top:39px;padding-bottom:45px}
// @media (max-width:750px){footer .newsletter{display:none}}
// footer .newsletter .title{font-size:32px;font-family:"futura-pt";font-weight:900;line-height:1;color:#fff;display:table-cell;vertical-align:middle}
// @media (max-width:960px){footer .newsletter .title{display:none}}
// footer .newsletter .desc{display:table-cell;vertical-align:middle;font-family:"futura-pt";font-weight:300;font-size:20px;line-height:1.1;color:#fff}
// @media (max-width:1345px){footer .newsletter .desc{display:none}}
// footer .newsletter .form{display:table-cell;vertical-align:middle}
// footer .newsletter .form input[type="text"]{background-color:#a0102b;border:solid 1px rgba(0, 0, 0, 0.4);font-family:"futura-pt";font-weight:300;font-size:16px;line-height:1.88;color:#fff;padding:9px 25px;height:50px;float:left;width:288px}
// footer .newsletter .form input[type="text"]::-webkit-input-placeholder{color:#fff}
// footer .newsletter .form input[type="text"]:focus{outline:0}
// footer .newsletter .form input[type="submit"]{font-family:"futura-pt";font-weight:900;font-size:14px;line-height:2.14;letter-spacing:1.4px;color:#fff;height:50px;background:url("./assets/mail-ico.svg") no-repeat #1b212d;background-size:16px 12px;background-position:28px center;text-transform:uppercase;border:0;float:left;padding-left:87px;padding-right:88px}
// footer .socials{display:table;width:100%;border-bottom:1px solid rgba(48, 56, 73, 0.15);padding-top:39px;padding-bottom:45px}
// footer .socials .title{font-size:32px;font-family:"futura-pt";font-weight:900;line-height:1;color:#fff;display:table-cell;vertical-align:middle}
// @media (max-width:960px){footer .socials .title{display:none}}
// footer .socials .desc{display:table-cell;vertical-align:middle;font-family:"futura-pt";font-weight:300;font-size:20px;line-height:1.1;color:#fff}
// @media (max-width:1345px){footer .socials .desc{display:none}}
// footer .socials .icons{display:table-cell;vertical-align:middle}
// @media (max-width:960px){footer .socials .icons{text-align:center}}
// footer .socials .icons a{display:inline-block;margin:0 15px}
// footer .footer-esports,footer .footer-tellinger{font-family:"futura-pt";font-weight:300;font-size:16px;color:#fff;padding-top:38px;padding-bottom:38px}
// @media (max-width:750px){footer .footer-esports,footer .footer-tellinger{display:block;-webkit-flex:0 0 100%;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;text-align:center !important;padding-top:10px;padding-bottom:10px}}
// footer .footer-esports a,footer .footer-tellinger a{color:#fff;text-decoration:underline}
// footer .footer-esports a:hover,footer .footer-tellinger a:hover{text-decoration:none}
// footer .footer-tellinger{text-align:right}
// footer .footer-tellinger img{margin-left:3px;margin-right:3px}
// footer .scrollremember span{color:#fff}

footer {
  background-color: #cf072c;
}
footer .footer .partners-box {
  padding-top: 37px;
  padding-bottom: 42px;
  border-bottom: 1px solid rgba(48, 56, 73, 0.15);
}
footer .footer .partners-box .partners-heading {
  font-size: 16px;
  font-family: 'futura-pt';
  font-weight: 900;
  line-height: 1;
  color: #fff;
  text-align: center;
  margin-bottom: 12px;
}
footer .footer .partners {
  width: 100%;
  text-align: center;
}
@media (max-width: 500px) {
  footer .footer .partners {
    display: block;
  }
  footer .footer .partners tbody,
  footer .footer .partners tr {
    display: block;
    width: 100%;
  }
}
footer .footer .partners td {
  padding: 20px 10px;
  vertical-align: top;
}
@media (max-width: 500px) {
  footer .footer .partners td {
    display: block;
    text-align: center;
    width: 100%;
  }
  footer .footer .partners td a {
    width: 100%;
  }
}
footer .footer .partners td a {
  display: block;
}
footer .footer .partners td img {
  -webkit-filter: brightness(0) invert(0);
  filter: brightness(0) invert(0);
  opacity: 0.4;
}
footer .footer .partners td img:hover {
}
footer .footer .partners td span {
  display: block;
  font-family: 'Tahoma';
  font-size: 10px;
  line-height: 1.2;
  padding-top: 4px;
  color: #000;
  margin-top: 5px;
}
footer .newsletter {
  display: table;
  width: 100%;
  border-bottom: 1px solid rgba(48, 56, 73, 0.15);
  padding-top: 39px;
  padding-bottom: 45px;
}
@media (max-width: 750px) {
  footer .newsletter {
    display: none;
  }
}
footer .newsletter .title {
  font-size: 32px;
  font-family: 'futura-pt';
  font-weight: 900;
  line-height: 1;
  color: #fff;
  display: table-cell;
  vertical-align: middle;
}
@media (max-width: 960px) {
  footer .newsletter .title {
    display: none;
  }
}
footer .newsletter .desc {
  display: table-cell;
  vertical-align: middle;
  font-family: 'futura-pt';
  font-weight: 300;
  font-size: 20px;
  line-height: 1.1;
  color: #fff;
}
@media (max-width: 1345px) {
  footer .newsletter .desc {
    display: none;
  }
}
footer .newsletter .form {
  display: table-cell;
  vertical-align: middle;
}
footer .newsletter .form input[type='text'] {
  background-color: #a0102b;
  border: solid 1px rgba(0, 0, 0, 0.4);
  font-family: 'futura-pt';
  font-weight: 300;
  font-size: 16px;
  line-height: 1.88;
  color: #fff;
  padding: 9px 25px;
  height: 50px;
  float: left;
  width: 288px;
}
footer .newsletter .form input[type='text']::-webkit-input-placeholder {
  color: #fff;
}
footer .newsletter .form input[type='text']:focus {
  outline: 0;
}
footer .newsletter .form input[type='submit'] {
  font-family: 'futura-pt';
  font-weight: 900;
  font-size: 14px;
  line-height: 2.14;
  letter-spacing: 1.4px;
  color: #fff;
  height: 50px;
  background: url('./assets/mail-ico.svg') no-repeat #1b212d;
  background-size: 16px 12px;
  background-position: 28px center;
  text-transform: uppercase;
  border: 0;
  float: left;
  padding-left: 87px;
  padding-right: 88px;
}
footer .socials {
  display: table;
  width: 100%;
  border-bottom: 1px solid rgba(48, 56, 73, 0.15);
  padding-top: 39px;
  padding-bottom: 45px;
}
footer .socials .title {
  font-size: 32px;
  font-family: 'futura-pt';
  font-weight: 900;
  line-height: 1;
  color: #fff;
  display: table-cell;
  vertical-align: middle;
}
@media (max-width: 960px) {
  footer .socials .title {
    display: none;
  }
}
footer .socials .desc {
  display: table-cell;
  vertical-align: middle;
  font-family: 'futura-pt';
  font-weight: 300;
  font-size: 20px;
  line-height: 1.1;
  color: #fff;
}
@media (max-width: 1345px) {
  footer .socials .desc {
    display: none;
  }
}
footer .socials .icons {
  display: table-cell;
  vertical-align: middle;
}
@media (max-width: 960px) {
  footer .socials .icons {
    text-align: center;
  }
}
footer .socials .icons a {
  display: inline-block;
  margin: 0 15px;
}
footer .footer-esports,
footer .footer-tellinger {
  font-family: 'futura-pt';
  font-weight: 300;
  font-size: 16px;
  color: #fff;
  padding-top: 38px;
  padding-bottom: 38px;
}
@media (max-width: 750px) {
  footer .footer-esports,
  footer .footer-tellinger {
    display: block;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center !important;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
footer .footer-esports a,
footer .footer-tellinger a {
  color: #fff;
  text-decoration: underline;
}
footer .footer-esports a:hover,
footer .footer-tellinger a:hover {
  text-decoration: none;
}
footer .footer-tellinger {
  text-align: right;
}
footer .footer-tellinger img {
  margin-left: 3px;
  margin-right: 3px;
}
footer .scrollremember span {
  color: #fff;
}

.flashalert {
  position: relative;
  width: 100%;
  font-size: 14px;
  padding: 9px 30px 7px;
  margin: 8px auto;
  color: #c09853;
  text-align: center;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  background-color: #fcf8e3;
  border: 1px solid #fbeed5;
  border-radius: 4px;
}

.flashalert-success {
  color: #468847;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.flashalert-error {
  color: #b94a48;
  background-color: #f2dede;
  border-color: #eed3d7;
}

.flashalert-info {
  color: #3a87ad;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.flashalert button.flashclose {
  position: absolute;
  top: 8px;
  right: 8px;
  line-height: 20px;
  float: right;
  font-size: 20px;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.2;
  padding: 0 0 0 20px;
  cursor: pointer;
  background: 0 0;
  border: 0;
}

.flashalert button.flashclose:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}
