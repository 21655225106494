@import '/src/variables.scss';

.pagination {
  text-align: center;
  display: flex;
  gap: 10px;
  justify-content: flex-end;

  .pageNumber {
    padding: 7px 11px;
    border-radius: 5px;
    background: $strokeGreyColor;
    cursor: pointer;
    min-width: 30px;

    color: $darkGreyText;
    font-family: Loko_Lato-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .active {
    background-color: $mainRedColor;
    color: $whiteColor;
  }
}
