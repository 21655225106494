@import '/src/variables.scss';

.tableHeader {
  border-top: 1px solid $strokeGreyColor;
  border-bottom: 1px solid $strokeGreyColor;
  padding: 10px 0px;
}

.tableHeader th {
  font-family: Loko_Lato-Black;
  font-size: 16px;
  line-height: 19.2px;
  padding: 12px 15px;
  height: 60px;
  text-align: center;
  min-width: 100px;
  position: sticky;
  top: -1px;
  background-color: $whiteColor;
}

.tableTr {
  border-top: 1px solid $strokeGreyColor;
  border-bottom: 1px solid $strokeGreyColor;
  width: 80px;
  height: 60px;
}

.tableTrBottom {
  border-top: 1px solid $strokeGreyColor;
  border-bottom: 1px solid $mainRedColor;
}

.tableTr td {
  font-family: Loko_Lato-Regular;
  font-weight: 400;
  font-size: 14px;
  line-height: 16.8px;
  color: $blackColor;
  height: 60px;
  padding: 0;
  text-align: center;
}

.tableTr .club {
  font-family: Loko_Lato-Regular;
  font-weight: 400;
  font-size: 14px;
  line-height: 16.8px;
  color: $blackColor;
  height: 60px;
  padding: 0;
  text-align: left;
  width: auto;
  min-width: 250px;
  padding-left: 20px;
}

.tableHeader th .club {
  text-align: left;
}

.firstTd {
  width: 180px;
  margin-left: 10px;
  text-align: left;
  position: sticky;
  left: 0;
  background-color: $whiteColor;
  height: 60px;
}

.firstTd a {
  font-family: Loko_Lato-Regular;
  font-weight: 700;
  font-size: 14px;
  line-height: 16.8px;
  color: $blackColor;
}

td .club,
.club {
  width: 250px;
  padding-left: 0px;
  position: sticky;
  left: 0;
  text-align: left;
  white-space: nowrap;
  background-color: $whiteColor;
  height: 60px;
}

.clubName {
  font-family: Loko_Lato-Bold;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: $blackColor;
}

.clubInfo {
  display: inline-flex;
  margin-left: 16px;
  flex-direction: column;
  gap: 2.19px;
}

.clubCity {
  display: block;
  font-family: Loko_Lato-Regular;
  font-size: 11px;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: 0em;
  text-align: left;
  color: $greyTextColor;
}

.nowrap {
  white-space: nowrap;
}

.tableRed td {
  background-color: $mainRedColor;
  color: $whiteColor;
  font-family: Loko_Lato-Bold;
}

.clubNameWhite {
  font-family: Loko_Lato-Bold;
  color: $whiteColor;
}

.logoAlignCenter {
  text-align: center;
  width: 250px;
  padding-left: 0px;
  position: sticky;
  left: 0;
  white-space: nowrap;
  background-color: $whiteColor;
}
