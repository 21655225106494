@import '/src/variables.scss';

.wrapper {
  .note {
    color: $darkGreyText;
    margin-top: 45px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    padding-bottom: 30px;
    font-size: 14px;

    .icon {
      margin-right: 10px;
      height: 20px;
    }
  }
}
