@import '/src/variables.scss';

.photoWrapper {
  display: inline-block;
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  height: 100%;
  width: 100%;
}

.imgWrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.photo {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  overflow: hidden;
  aspect-ratio: 3/2;
  z-index: 2;
  cursor: pointer;

  border-radius: 10px 10px 0px 0px;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  object-fit: cover;
  transition: 1.2s;

  &:before {
    display: block;
    content: '';
    width: 100%;
    height: 85%;
    background-image: linear-gradient(
      to top,
      $newsGradient 0%,
      transparent 70%
    );
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &:hover {
    transform: scale(1.05);
  }

  &Icon {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 3;
    border-radius: 5px;
    background-color: $backgroundIconColor;

    &Wrapper {
      padding: 2px 8px;
      display: flex;
      align-items: center;
    }
  }

  &Count {
    margin-left: 4px;
    font-family: Loko_Lato-Medium;
    font-weight: 600;
    font-size: 18px;
    color: $whiteColor;
    text-shadow: 2px 2px 3px rgba($blackColor, 0.5);
  }
}

.description {
  display: block;
  &Wrapper {
    position: absolute;
    width: 100%;
    height: calc(100% / 3);
    bottom: 0;
    padding: 0px 0px 16px 16px;
    background: linear-gradient(
      to bottom,
      transparent 10%,
      $newsGradient 80% 100%
    );
    border-radius: 10px;
    font-family: Loko_Lato-Bold;
    font-size: 19px;
    line-height: 1.2;
    color: $whiteColor;
    z-index: 2;
    .descriptionContent {
      position: absolute;
      bottom: 0;
      width: 100%;
      margin-bottom: 16px;
      .description {
        display: block;
        width: calc(100% - 32px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 6px;
      }
    }
  }
  &Date {
    display: block;
    font-family: Loko_Lato-Regular;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.2;
    color: $whiteColor;
    margin-bottom: 6px;
  }
}

.authors {
  font-family: Loko_Lato-Regular;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  color: $whiteColor;
}

@media screen and (max-width: 1080px) {
  .descriptionDate {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
  .authors {
    font-size: 12px !important;
    margin-bottom: 4px;
  }
  .description {
    &Wrapper {
      .descriptionContent {
        .description {
          margin-bottom: 2px;
        }
      }
    }
  }
  .descriptionWrapper {
    font-size: 19px;
  }
  .descriptionDate {
    font-size: 14px;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 360px) {
  .descriptionWrapper {
    font-size: 14px;
  }
  .descriptionDate {
    font-size: 10px;
  }
}
