@import '/src/variables.scss';

.wrapperFullWidth {
  width: 100%;
  border-top: 1px solid $footerLineColor;
  border-bottom: 1px solid $footerLineColor;
  margin-top: 30px;
  margin-bottom: 20px;
  padding: 30px 16px;
}

.wrapperGrid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;
  gap: 20px;
}

.logo {
  width: 130px;
}

.mobileLogo {
  width: 80px;
  height: 80px;
}

.wrapperSmallWidth {
  width: 100%;
  padding: 18px 0 20px;
  border-top: 1px solid $footerLineColor;
}
