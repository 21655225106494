@import '/src/variables.scss';

.startInfo {
  position: relative;
  z-index: 1000;
  margin-bottom: 5%;
}

.flex {
  display: flex;
  align-items: end;
}

.Position {
  margin-bottom: 20px;
  display: block;
  font-family: Loko_Lato-Regular;
  font-weight: 400;
  font-size: 24px;
  text-transform: uppercase;
  color: $whiteColor;
  line-height: 1;

  &SmallTablet {
    margin-bottom: 20px;
    display: block;
    font-family: Loko_Lato-Regular;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0em;
    text-align: left;
    color: $whiteColor;
    text-transform: uppercase;
  }
}

.Name,
.Surname {
  display: block;
  box-sizing: border-box;
  font-family: L_Halvar_Mittelschrift-Bold;
  font-weight: 700;
  font-size: 86px;
  color: $whiteColor;
  height: 100;
  line-height: 1;
  text-transform: uppercase;

  &SmallTablet {
    display: block;
    font-family: L_Halvar_Mittelschrift-Bold;
    font-size: 48px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0em;
    text-align: left;
    color: $whiteColor;
    text-transform: uppercase;
  }
}

.Surname,
.SurnameSmallTablet {
  margin-bottom: 20px;
}

.Info {
  display: block;
  font-family: Loko_Lato-Regular;
  font-weight: 400;
  font-size: 18px;
  color: $whiteColor;
  line-height: 1.3;
  max-width: 611px;

  &SmallTablet {
    display: block;
    font-family: Loko_Lato-Regular;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: 0em;
    text-align: left;
    color: $whiteColor;
    margin-bottom: 20px;
  }
}

.path {
  margin-bottom: 120px;
}

.numberWrapper {
  position: absolute;
  bottom: 0;
  right: 0;
}

.number {
  font-family: hc_Lokomotiv;
  font-weight: 400;
  font-size: 130px;
  line-height: 1;
  color: $whiteColor;

  &SmallTablet {
    font-family: hc_Lokomotiv;
    font-size: 75px;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 5px;
    text-align: center;
    color: $whiteColor;
  }
}

.numberIcon {
  font-family: hc_Lokomotiv;
  font-weight: 400;
  font-size: 100px;
  line-height: 1;
  color: $mainRedColor;
  margin-right: 10px;

  &SmallTablet {
    font-family: hc_Lokomotiv;
    font-size: 50px;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 5px;
    text-align: center;
    color: $mainRedColor;
  }
}

@media screen and (max-width: 1000px) {
  .flex {
    flex-direction: column;
    align-items: start;
  }
}

@media screen and (max-width: 360px) {
  .NameSmallTablet,
  .SurnameSmallTablet {
    font-size: 48px;
  }
}
