@import '/src/variables.scss';

.tooltip {
  display: flex;
  width: 320px;
  max-height: 376px;
  padding: 10px 10px 20px 10px;
  flex-direction: column;
  border-radius: 10px;
  pointer-events: none;
}

.tooltipImgContainer {
  width: 100%;
  height: 150px;
  pointer-events: all;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }

  .slider {
    :global {
      width: 100%;
      height: 100%;
      .slick-arrow {
        width: 30px;
        height: 30px;
        position: absolute;
        z-index: 10;
        top: 50%;
        transform: translateY(-50%);
        filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.8));
      }

      .slick-list,
      .slick-track {
        height: inherit;
      }

      .slick-slide > div {
        height: 100%;

        img {
          height: 100%;
          object-fit: cover;
          min-height: 200px;
        }
      }

      .slick-next {
        right: 0;
      }
    }
  }
}

.infoContainer {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.titleContainer {
  text-align: left;
  font-family: Loko_Lato-Bold;
  font-size: 18px;
  line-height: normal;

  .title {
    font-size: 18px;
    word-wrap: break-word;
    color: $whiteColor;
    line-height: 1.2;
    margin-bottom: 15px;
  }
}

.descriptionContainer {
  font-family: Loko_Lato-Regular;

  .description {
    font-size: 14px;
    word-wrap: break-word;
    color: $whiteColor;
    line-height: 1.2;
    margin: 0;
    white-space: pre-wrap;
  }
}

.fullscreenSlide {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;

  img {
    width: 70%;
    height: 70%;
  }
}
