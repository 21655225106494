@import '/src/variables.scss';

.wrapper {
  margin: 0 auto;
  font-family: Loko_Lato-Regular;
  color: $whiteColor;

  .subscription {
    display: flex;
    justify-content: space-between;
    padding: 28px 30px 28px 50px;
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 50px;
    border-radius: 10px;
    background: linear-gradient(
      45deg,
      $darkRedColor,
      $mainBlueColor,
      $mainRedColor
    );
    position: relative;
    min-height: 350px;

    .subscriptionInfo {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .subscriptionTitle {
        font-family: L_Halvar_Mittelschrift-Bold;
        font-size: 50px;
        font-style: normal;
        margin-bottom: 10px;
        line-height: 1.4;
      }

      .subscriptionDescription {
        max-width: 666px;
        font-size: 18px;
        margin-right: 40px;
        line-height: 1.4;
      }
    }

    .subscriptionImage {
      position: absolute;
      object-fit: contain;
      right: 0;
      top: -70px;
    }
  }

  .header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: 40px;

    .title {
      font-family: L_Halvar_Mittelschrift-Bold;
      font-size: 35px;
      margin: 0 40px 0 0;
      padding: 0;
      line-height: 1.158;
    }

    .description {
      font-size: 16px;
      margin: 0;
      padding: 0;
      max-width: 785px;
      line-height: 1.2;
    }
  }

  .advantagesList {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    margin-bottom: 50px;

    .advantagesItem {
      display: flex;
      align-items: center;

      .iconWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        width: 100px;
        height: 100px;
        background-color: $lokomaniaBlueColor;
        margin-right: 20px;

        .icon {
          width: 70px;
          height: 70px;
        }
      }

      .text {
        max-width: 260px;
        margin: 0;
        font-size: 16px;
        line-height: 1.2;
      }
    }
  }

  .sliderContainer {
    padding-bottom: 60px;

    .slider {
      padding-bottom: 40px;

      :global {
        .slick-arrow {
          bottom: -20px;
          position: absolute;
          cursor: pointer;
          z-index: 10;
          width: 48px;
          height: 48px;
          filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2));
        }

        .slick-slide > div {
          background-color: $lokomaniaBlueColor;
          border-radius: 10px;
          height: 180px;
          margin: 0 10px;
          display: flex;
          align-items: center;
          justify-content: center;

          > div {
            height: 125px;

            img {
              margin: 0 auto;
              height: inherit;
              object-fit: contain;
            }
          }
        }

        .slick-active + .slick-active + .slick-active + .slick-active {
          position: relative;

          ::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(to right, transparent, $mainBlueColor);
            pointer-events: none;
            z-index: 1;
          }
        }

        .slick-current > div {
          background-color: $mainRedColor;
        }

        .slick-disabled {
          opacity: 0.5;
        }

        .slick-next {
          left: 80px;
        }

        .slick-prev {
          left: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 1320px) {
  .wrapper {
    .content {
      padding: 0 16px;
    }
  }
}

@media screen and (max-width: 1216px) {
  .wrapper {
    .subscription .subscriptionInfo .subscriptionTitle {
      font-size: 35px;
    }

    .subscription .subscriptionImage {
      top: -30px;
      width: 380px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .wrapper .subscription {
    .subscriptionInfo .subscriptionTitle {
      max-width: 300px;
    }

    .subscriptionImage {
      width: 220px;
    }
  }
}

@media screen and (max-width: 550px) {
  .wrapper .subscription .subscriptionImage {
    display: none;
  }
}
