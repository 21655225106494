.statistic {
    flex: 6;
    height: 100%;
}

@media screen and (max-width: 500px) {
    .statistic {
        flex-direction: column;
    }
}
