@import '/src/variables.scss';

.headerText {
  font-family: L_Halvar_Mittelschrift-Bold;
  font-size: 35px;
  font-weight: 700;
  line-height: 1.158;
  letter-spacing: 0em;
  text-align: left;
  color: $blackColor;
  margin-bottom: 30px;
}

.wrapper {
  margin: 0 20px;
  padding-top: 30px;
  background-color: $whiteColor;
  border-radius: 10px;
}

.title {
  font-family: L_Halvar_Mittelschrift-Bold;
  font-size: 35px;
  font-weight: 700;
  line-height: 1.16;
  letter-spacing: 0em;
  text-align: left;
  color: $blackColor;
  margin: 0px;
}

.titleFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  gap: 20px;
}

.innerWrapper {
  padding-bottom: 40px;
}

.select {
  width: fit-content;
  height: 40px;
}

.wrapperBreadcrumbs {
  margin-bottom: 197px;
}

.catastropheHeaderText {
  font-family: L_Halvar_Mittelschrift-Bold;
  font-size: 74px;
  line-height: 1.16;
  letter-spacing: 0em;
  text-align: left;
  color: $whiteColor;
  margin-bottom: 50px;
}

@media screen and (max-width: 1320px) {
  .wrapperBreadcrumbs {
    padding-left: 16px;
    padding-right: 16px;
  }
  .catastropheHeaderText {
    margin-left: 16px;
    margin-right: 16px;
  }
}

@media screen and (max-width: 768px) {
  .catastropheHeaderText {
    font-size: 50px;
  }
}

@media screen and (max-width: 480px) {
  .titleFlex {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
  }
}
