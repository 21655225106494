@import '/src/variables.scss';

.video {
  flex-basis: calc((100% - 2 * 20px) / 3);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-radius: 10px 10px 0 0;
  border: 1px solid $strokeGreyColor;
  overflow: hidden;

  .previewImage {
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: cover;
  }
}

@media (max-width: 1200px) {
  .video {
    flex-basis: calc((100% - 20px) / 2);
  }
}

@media (max-width: 900px) {
  .video {
    flex-basis: 100%;
  }
}

.previewImageMainVideo {
  width: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
  border-radius: 10px 10px 0px 0px;
}

.description,
.descriptionMainVideo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  height: 100px;
  box-sizing: content-box;

  .title {
    font-family: Loko_Lato-Bold;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: $blackColor;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .date {
    font-family: Loko_Lato-Medium;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: $greyTextColor;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.descriptionMainVideo {
  margin-bottom: 15px;
  border-radius: 0px 0px 10px 10px;
  border: 1px solid $strokeGreyColor;
}
