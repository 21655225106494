@import "/src/variables.scss";

.socialsWrapper {
  position: relative;
  user-select: none;

  .socialsButtonText {
    font-family: L_Halvar_Mittelschrift-Regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: $whiteColor;
  }

  .socialsDropdown {
    border-radius: 0px 0px 5px 5px;
    background-color: $darkRedColor;
    position: absolute;
    top: 73px;
    left: 0;
    z-index: 100;
    box-shadow: 2px 8px 20px 0px #00000040;
    padding: 20px;
    width: 178px;

    .socialsDropdownItemIcon {
      width: 16px;
      height: 16px;
      object-fit: contain;
    }

    .socialsDropdownItemText {
      font-family: Loko_Lato-Medium;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: $whiteColor;
    }
  }
}
