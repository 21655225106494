@import '/src/variables.scss';

.imageInSideContainer {
  display: flex;
  height: 100%;
}

.leftSideContainer {
  display: flex;
  flex-direction: column;
  flex: 2;
  gap: 15px;
}

.rightSideContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.containerButtonImageInSide {
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: 20px;
  padding-left: 40px;
}

.alignBtnImageInSide {
  width: 127px;
  height: 100%;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.widgetBtn {
  height: 34.11px;
  border-radius: 5px;
  outline: none !important;
  background-color: $mainRedColor;
  border: none;
  color: #fff;
  font-family: Loko_Lato-Heavy;
  font-weight: 800;
  font-size: 14px;
  line-height: 16.8px;
  position: relative;
}

.widgetBtnOrder {
  color: #fff;
  font-family: Loko_Lato-Heavy;
  font-weight: 800;
  font-size: 10.84px;
  line-height: 13.01px;
}

.leftSideInnerText {
  font-family: Loko_Lato-Black;
  font-size: 16px;
  font-weight: 900;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  padding-left: 40px;
}
