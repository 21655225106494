@import '/src/variables.scss';

.breadcrumbs {
  font-size: 16px;
  font-style: normal;
  line-height: 1.2;
  color: $strokeGreyColor;

  a {
    color: $strokeGreyColor;

    .other {
      font-family: Loko_Lato-Regular;
      font-weight: 400;
    }

    .current {
      font-family: Loko_Lato-Bold;
      font-weight: 700;
    }

    .darkCurrent,
    .darkOther {
      font-family: Loko_Lato-Regular;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.2;
      letter-spacing: 0em;
      text-align: left;
      color: $blackColor;
    }

    .darkOther {
      color: $darkGrey;
    }
  }
}

.separatorDark {
  color: $darkGrey;
}
