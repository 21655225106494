@import '/src/variables.scss';

.footerWrapper {
  padding-bottom: 25px;
  position: relative;
  z-index: 2;
  background-color: $darkBlue;

  &Inner {
    background-color: $darkBlue;
    max-width: 1288px;
    margin: 0 auto;
  }
}
