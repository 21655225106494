@import '/src/variables.scss';

.wrapper {
  flex: 6;
  border-radius: 10px;
  overflow: hidden;
  display: grid;
  aspect-ratio: 16/9;

  .player {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 1024px) {
  .wrapper {
    flex-basis: 100%;
  }
}
