@import '/src/variables.scss';

.header {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}

.filterWrapper {
  display: flex;
  gap: 16px;
}

.tabsWrapper {
  overflow-x: auto;
  overflow-y: hidden;
  margin-bottom: 30px;
}
.tabBar {
  white-space: nowrap;
}

.tabBar button {
  border: none;
  outline: none;
  background-color: transparent;
  font-family: Loko_Lato-Regular;
  font-weight: 400;
  font-size: 18px;
  color: $notActiveTab;
  line-height: 21.6px;
  margin-right: 40px;
  position: relative;
  display: inline-block;
  padding-bottom: 12px;

  &:disabled {
    color: $notActiveTab;
    cursor: default;
  }
}

.tabBar button:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: $mainRedColor;
  opacity: 0;
  transition: opacity 0.2s;
}

.tabBar button.active,
.tabBar button:active,
.tabBar button:focus,
.tabBar button:hover {
  color: $mainRedColor !important;
  font-family: Loko_Lato-Bold;
  font-weight: 800;
  text-decoration: none;
}

.tabBar button.active:after,
.tabBar button:active:after,
.tabBar button:focus:after,
.tabBar button:hover:after {
  opacity: 1;
  color: $mainRedColor !important;
}

.games {
  .slider {
    :global {
      .slick-slide > div > div {
        margin: 0 10px;
        width: unset;
      }

      .slick-list {
        position: relative;
        width: 100%;
      }

      .slick-track {
        overflow: hidden;
      }

      .slick-arrow {
        position: absolute;
        z-index: 10;
        top: 50%;
        transform: translateY(-50%);
        filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.8));
      }

      .slick-next {
        right: 0;
      }
    }
  }
}

.text {
  display: none;
}

.textShow {
  display: block;
}

@media screen and (max-width: 1320px) {
  .pageHeaderWrapper {
    padding: 0px 16px;
  }
}

@media screen and (max-width: 810px) {
  .filterWrapper {
    margin-bottom: 30px;
    width: 100%;
  }
}
