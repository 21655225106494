@import '/src/variables.scss';

.mainWrapper {
  background-color: $mainBlueColor;
}

.contentWrapper {
  max-width: 1288px;
  margin: 0 auto;
  position: relative;
  background-color: $articleContentBackground;
}

.headerWrapper {
  padding: 20px;

  .breadcrumbsWrapper {
    padding-bottom: 30px;
  }

  .title {
    color: $blackColor;
    font-family: L_Halvar_Mittelschrift-Bold;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.158;
  }
}

.wrapper {
  display: flex;
  padding: 30px 20px 20px 20px;
  border-radius: 10px 10px 0 0;
  gap: 20px;
  background-color: $articleContentBackground;

  .content {
    flex-basis: calc((100% - 20px) * 2 / 3);
    max-width: calc((100% - 20px) * 2 / 3);
  }
}

.sideMenu {
  flex-basis: calc((100% - 20px) / 3);
  max-width: calc((100% - 20px) / 3);

  .sideTables {
    display: flex;
    flex-direction: column;
    gap: 30px;
    background-color: $whiteColor;
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-bottom: 30px;
}

@media screen and (max-width: 1100px) {
  .wrapper {
    display: block;
    .content {
      width: 100%;
      max-width: 100%;
    }
  }

  .sideMenu {
    width: 100%;
    max-width: 100%;
  }

  .contentWrapper {
    margin-left: 0;
    margin-right: 0;
  }
}
