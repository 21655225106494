@import '/src/variables.scss';

.text {
  font-family: Loko_Lato-Regular;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0em;
  text-align: left;
  color: $blackColor;

  &Truncated {
    max-height: 330px;
    overflow: hidden;
  }

  &Truncated p,
  p {
    font-family: Loko_Lato-Regular;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.7;
    margin-bottom: 30px;
  }

  a {
    color: $mainBlueColor;
    font-family: Loko_Lato-Regular;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.7;
    text-decoration: underline;
  }
}
