@import '/src/variables.scss';

.dayWrapper {
  aspect-ratio: 1 / 1;
  border-radius: 8px;
  border: 1px solid $lightGrey;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.customLogo {
  width: 32px;
  height: 32px;
}

.day_page {
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  justify-items: baseline;
  justify-content: center;
  text-align: center;
}

.dateNumber {
  font-family: L_Halvar_Mittelschrift-Bold;
  font-weight: 700;
  font-size: 32px;
  line-height: 37.06px;
  color: $whiteColor;
}

.dateName {
  font-family: Loko_Lato-Regular;
  font-weight: 400;
  font-size: 14px;
  line-height: 16.8px;
  color: $whiteColor;
}

.greyText {
  color: $darkGreyText;
}

.logoWrapper {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.divider {
  color: $darkGreyText;
  font-family: L_Halvar_Mittelschrift-Bold;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.15em;
  text-align: center;
}

.gameWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dateNumber_page {
  font-family: L_Halvar_Mittelschrift-Bold;
  font-size: 32px;
  font-weight: 700;
  line-height: 37px;
  letter-spacing: 0em;
  text-align: left;
  color: $greyTextColor;
}

.dateNumber_notCurrent {
  color: $strokeGreyColor;
}

.dateWrapper {
  display: contents;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.day_isHome {
  border-radius: 8px;
  background: linear-gradient(-62deg, #fff 0% 74.8%, $mainRedColor 75% 100%);
  color: $mainRedColor;
  display: flex;
  align-items: center;
  justify-content: center;
}

.day_isAway {
  border-radius: 8px;
  background: linear-gradient(-62deg, #fff 0% 74.8%, $mainBlueColor 75% 100%);
  color: $mainBlueColor;
  display: flex;
  align-items: center;
  justify-content: center;
}

.divider_page {
  color: $greyTextColor;
  font-family: L_Halvar_Mittelschrift-Bold;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.15em;
  text-align: center;
}

.red {
  color: $mainRedColor;
}

.blue {
  color: $mainBlueColor;
}

.widgetGame {
  width: 100px;
  height: 100px;
  background-color: #fff;
  padding: 16px 23px 12px 23px;
  border: 2.5px solid $whiteColor;
}

.dateName_page {
  font-family: Loko_Lato-Regular;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: $greyTextColor;
}
