@import "/src/variables.scss";

.team {
  &Wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &Name {
    font-family: Loko_Lato-Bold;
    font-weight: 800;
    margin-bottom: 3.1px;
    color: $blackColor;
    font-size: 12px;
    font-weight: 800;
    line-height: 12px;
    letter-spacing: 0px;
    text-align: center;
  }

  &City {
    font-family: Loko_Lato-Regular;
    color: $blackColor;
    font-size: 9px;
    font-weight: 400;
    line-height: 9px;
    letter-spacing: 0px;
    text-align: center;
  }
}
