@import '/src/variables.scss';

.title {
  color: $whiteColor;
  margin-bottom: 50px;
  font-family: L_Halvar_Mittelschrift-Bold;
}

.executiveHeader {
  padding: 120px 0 30px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .info {
    color: $whiteColor;
    width: 100%;
    display: flex;
    flex-direction: column;

    .position {
      font-family: Loko_Lato-Regular;
      font-size: 24px;
      line-height: 1;
      text-transform: uppercase;
      margin-bottom: 20px;

      &Mobile {
        font-family: Loko_Lato-Regular;
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
        text-transform: uppercase;
      }
    }

    .name {
      max-width: 583px;
      font-family: L_Halvar_Mittelschrift-Bold;
      font-size: 96px;
      line-height: 1;
      letter-spacing: 0em;
      text-align: left;
      text-transform: uppercase;

      &Mobile {
        font-family: L_Halvar_Mittelschrift-Bold;
        font-size: 48px;
        font-weight: 700;
        line-height: 48px;
        letter-spacing: 0em;
        text-align: left;
        text-transform: uppercase;
      }
    }
  }

  .mainInfo {
    display: flex;
    align-self: flex-end;
    flex-direction: column;
    justify-content: center;
  }

  .bioWrapper {
    width: 100%;
    margin-top: auto;
  }

  .imageWrapper {
    max-width: 400px;
    width: 100%;
    margin-bottom: 20px;

    img {
      width: 100%;
      border-radius: 10px;
    }
  }
}

.otherExecutives {
  padding: 40px 0 60px;

  .title {
    font-family: L_Halvar_Mittelschrift-Bold;
    font-size: 24px;
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1000px) {
  .mainInfo {
    width: 45%;
  }
}

@media screen and (max-width: 1320px) {
  .otherExecutives {
    padding-left: 16px;
    padding-right: 16px;
  }

  .executiveHeader {
    padding: 120px 16px 40px;
  }
}

@media screen and (max-width: 1000px) {
  .executiveHeader {
    width: 100%;
    height: 100%;
    display: block;
    align-items: flex-start;
    justify-content: space-between;
  }
}
