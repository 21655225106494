@import '/src/variables.scss';
.table {
  width: 100%;
  max-width: 100%;

  .tableBody {
    border: 1px solid $strokeGreyColor;

    th {
      text-align: center;
      align-items: center;
      padding: 15px 5px;
      vertical-align: middle;
    }

    td {
      text-align: center;
      align-items: center;
      padding: 15px 5px;
      vertical-align: middle;
      background-color: $whiteColor;
    }

    .status {
      text-align: left;
      padding-left: 15px;
    }

    .lastItem {
      padding-right: 15px;
    }
  }

  .tableHeader {
    border-top: 1px solid $strokeGreyColor;
    border-bottom: 1px solid $strokeGreyColor;
    padding: 10px 0px;
    background-color: $strokeGreyColor;

    color: $greyTextColor;
    text-align: center;
    font-family: Loko_Lato-Black;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
  }

  .tableRow {
    border-bottom: 1px solid $strokeGreyColor;

    font-family: Loko_Lato-regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background-color: $whiteColor;

    .total {
      color: $blackColor;
      text-align: center;
      font-family: Loko_Lato-Bold;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .team {
      display: flex;
      gap: 10px;

      .logo {
        width: 50px;
        height: auto;
      }

      .nameAndScore {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .name {
          color: $blackColor;
          font-family: Loko_Lato-Bold;
          font-size: 16px;
          font-style: normal;
          font-weight: 800;
          line-height: 100%;
        }
      }
    }
  }
}
