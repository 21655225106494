@import '/src/variables.scss';

.header {
  border-top: 1px solid $strokeGreyColor;
  border-bottom: 1px solid $strokeGreyColor;
  background-color: $strokeGreyColor;

  padding: 10px 15px;
  color: $greyTextColor;
  font-family: Loko_Lato-Black;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  background-color: $articleContentBackground;
}

.tableHeader {
  display: flex;
  padding: 20px 10px;
  justify-content: space-between;
  border-bottom: 1px solid $strokeGreyColor;

  .team {
    display: flex;
    gap: 10px;
    align-items: center;

    .logo {
      width: 32px;
      height: 32px;
    }

    .nameAndCity {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 3px;
      align-items: flex-start;

      .name {
        color: $blackColor;
        font-family: Loko_Lato-Bold;
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: 100%;
      }

      .city {
        color: $blackColor;
        font-family: Loko_Lato-Regular;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
      }
    }

    .rtl {
      align-items: flex-end;
    }
  }
}

.tableRow {
  display: flex;
  padding: 20px 10px;
  justify-content: space-between;
  border-bottom: 1px solid $strokeGreyColor;

  .player {
    display: flex;
    justify-content: space-between;
    flex-basis: calc((100% - 20px) / 2);

    .nameAndNumber {
      display: flex;
      gap: 13px;

      color: $blackColor;
      font-family: Loko_Lato-Bold;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      .number {
        font-family: hc_Lokomotiv;
        font-size: 19px;
        font-weight: 400;
        line-height: 1;
        letter-spacing: 0em;
        text-align: center;
      }
    }

    .score {
      color: $blackColor;
      font-family: Loko_Lato-Regular;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .lastScore {
      color: $mainRedColor;
      font-family: Loko_Lato-Bold;
      font-size: 16px;
      line-height: 1.2;
      letter-spacing: 0em;
      text-align: left;
    }
  }
}
