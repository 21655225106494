@import '/src/variables.scss';

.partner {
  position: relative;
  width: 50%;
}

.partnerLast {
  position: relative;
  width: 100%;
  padding: 0;
  min-width: 400px;
}

.partnerImg {
  width: auto;
  display: block;
  border: none;
  margin: 0 auto;
}

.partnerLastImg {
  display: block;
  border: none;
  margin: 0 auto;
}

@media screen and (max-width: 860px) {
  .partner,
  .partnerLast {
    width: 100%;
  }
}
