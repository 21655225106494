@import '/src/variables.scss';

.videoListWrapper {
  flex: 4;
}

.videoList {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: $whiteColor;
  border-radius: 10px;
  padding: 20px;
  border: 1px solid $strokeGreyColor;
  height: 100%;
  justify-content: space-between;

  .video {
    display: flex;
    gap: 16px;
    cursor: pointer;

    .imgWrapper {
      min-width: 102px;
      height: 80px;
      .previewImage {
        width: 102px;
        height: 80px;
        object-fit: cover;
        border-radius: 5px;
      }
    }

    .descriptionBlock {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 5px 0 10px;

      .title {
        font-family: Loko_Lato-Bold;
        font-size: 16px;
        line-height: 1.2;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 4px;

        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* Количество видимых строк */
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .descriptionWrapper {
        display: flex;
        gap: 8px;

        .description {
          font-family: Loko_Lato-Medium;
          font-size: 12px;
          line-height: 1.2;
          letter-spacing: 0em;
          text-align: left;
          align-items: center;
          color: $darkGrey;

          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          -webkit-line-clamp: 2;
        }
      }
    }
  }

  .separator {
    border: 1px solid $strokeGreyColor;
  }
}

@media (max-width: 1024px) {
  .videoList {
    flex-basis: 100%;
  }
}

@media (max-width: 360px) {
  .videoList {
    padding: 0;
    border: none;
  }
}
