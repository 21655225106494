@import '/src/variables.scss';

.bottomBlock {
  position: fixed;
  bottom: 0;
  z-index: 1101;
  height: 46px;
  width: 100%;
  background-color: #f1f1f2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.5);
}

.insideBlock {
  margin: auto;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.linkContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fullVersionGameArrowBtn {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1101;
  background-color: none;
  height: 46px;
  width: 46px;
}

.smallVersionArrowBtn {
  position: fixed;
  height: 46px;
  bottom: 0;
  z-index: 1101;
  background-color: $mainRedColor;
  right: 0;
  box-shadow: -5px -5px 5px rgba(0, 0, 0, 0.5);
}

.smallVersionGameArrowBtn {
  position: fixed;
  height: 46px;
  bottom: 0;
  z-index: 1101;
  background-color: $mainRedColor;
  right: 0;
  box-shadow: -5px -5px 5px rgba(0, 0, 0, 0.5);
}

.fullVersionArrow {
  transform: rotate(90deg);
  margin: 10px;
}

.fullVersionGameArrow {
  transform: rotate(90deg);
  margin: 10px;
  filter: brightness(0) saturate(100%) invert(94%) sepia(34%) saturate(331%)
    hue-rotate(268deg) brightness(109%) contrast(90%);
}

.smallVersionArrow {
  transform: rotate(270deg);
  margin: 10px;
}

.smallVersionGameArrow {
  transform: rotate(270deg);
  margin: 10px;
  filter: brightness(0) saturate(100%) invert(94%) sepia(34%) saturate(331%)
    hue-rotate(268deg) brightness(109%) contrast(90%);
}

@media screen and (max-width: 480px) {
  .insideBlock {
    margin-left: 20px;
  }
}
