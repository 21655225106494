:global {
  .todo-list li {
    list-style-type: none;
  }
}

.playerStartInfo {
  padding-top: 50px;
  padding-bottom: 0%;
  line-height: normal;
  max-width: 1288px;
  margin: auto;
}

.wrapper {
  padding-top: 40px;
}

.videoItem {
  video {
    width: 100%;
    max-height: calc(100vh - 102px);
  }
  width: 100%;
  max-height: calc(100vh - 102px);
}

@media screen and (max-width: 1288px) {
  .playerStartInfo {
    padding-left: 16px;
    padding-right: 16px;
  }
}
