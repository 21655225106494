@import '/src/variables.scss';

.scheme {
  padding-bottom: 80px;

  :global {
    .r:hover,
    .t1011s2:hover,
    .t67s3:hover,
    .t910s4:hover,
    .t23s3:hover,
    .t2s1:hover,
    .t7s3:hover,
    .t1415s2:hover,
    .t34s2:hover,
    .t78s4:hover {
      fill: $blueSector;
    }

    .t2s2:hover,
    .t89s1:hover {
      fill: $greenSector;
    }

    .t23s2:hover,
    .t89s2:hover,
    .t2s3:hover,
    .t15s2:hover,
    .t23s2:hover,
    .t78s2:hover,
    .t910s2:hover {
      fill: $pinkSector;
    }

    .t78s3:hover,
    .t23s4:hover,
    .t45s2:hover,
    .t1112s2:hover,
    .t1314s2:hover,
    .t1415s3:hover,
    .t23s1:hover,
    .t67s2:hover,
    .t910s1:hover,
    .t910s3:hover,
    .t1011s3:hover,
    .t78s1:hover,
    .t15s1:hover {
      fill: $orangeSector;
    }

    .t56guest:hover {
      fill: $greenGuestSector;
    }

    .t1213s1:hover {
      fill: $roseSector;
    }

    .t67s1:hover,
    .t1213s2:hover,
    .t1314s1:hover,
    .t1112s1:hover,
    .t45s1:hover {
      fill: $aquaSector;
    }

    .t1314s3:hover,
    .t1112s3:hover,
    .t1415s1:hover,
    .t34s1:hover,
    .t67s4:hover,
    .t1011s1:hover {
      fill: $yellowSector;
    }

    .t1s2:hover {
      fill: $darkOrangeSctor;
    }

    .t1s1:hover {
      fill: $redSector;
    }

    .l25:hover {
      fill: $darkRedSector;
    }

    .l1:hover,
    .l2:hover,
    .l3:hover,
    .l4:hover,
    .l5:hover,
    .l6:hover,
    .l7:hover,
    .l8:hover,
    .l9:hover,
    .l10:hover,
    .l11:hover,
    .l12:hover,
    .l13:hover,
    .l14:hover,
    .l15:hover,
    .l16:hover,
    .l17:hover,
    .l18:hover,
    .l19:hover,
    .l20:hover,
    .l21:hover,
    .l22:hover,
    .l23:hover,
    .l24:hover,
    .l25:hover,
    .l26:hover,
    .l27:hover,
    .l28:hover,
    .l29:hover,
    .l30:hover,
    .l31:hover,
    .l32:hover,
    .l33:hover,
    .banket-1:hover,
    .banket-3:hover,
    .banket-2:hover,
    .pool:hover {
      fill: $mainRedColor;
    }
  }
}

.breadcrumbs {
  padding-left: 27px;
}
