@import '/src/variables.scss';

.bookletItem {
  display: flex;
  position: relative;
  height: 420px;
  padding: 10px 30px 23px 30px;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  border-radius: 10px;
  background: linear-gradient(
    45deg,
    $darkRedColor,
    $mainBlueColor,
    $mainRedColor
  );

  .badge {
    margin-top: 50px;
    max-width: 116px;
    margin-bottom: 10px;
    background-color: #c8102e;
    color: $whiteColor;
    padding: 5px 10px;
    border-radius: 5px;
    text-align: center;
    text-transform: uppercase;
    font-family: L_Halvar_Mittelschrift-Bold;
    font-size: 16px;
    font-style: normal;
    position: relative;
    z-index: 40;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .title {
    position: relative;
    z-index: 40;
    max-width: 676px;
    word-wrap: break-word;
    color: $whiteColor;
    font-family: L_Halvar_Mittelschrift-Bold;
    font-size: 54px;
    font-style: normal;
  }

  .small {
    font-size: 24px;
  }

  .date {
    color: $whiteColor;
    margin-top: 10px;
    margin-bottom: 0;
    font-family: Loko_Lato-Regular;
    font-size: 14px;
    font-style: normal;
  }

  .dateSmall {
    color: $whiteColor;
    margin-top: 10px;
    margin-bottom: 0;
    font-family: Loko_Lato-Regular;
    font-size: 11px;
    font-style: normal;
  }

  .imageBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .imageContainer {
    width: 280px;
    height: 300px;
    display: flex;
    align-items: center;
  }

  .imageContainerSmall {
    width: 115px;
    height: 105px;
    display: flex;
    align-items: center;
  }

  .image {
    height: 100%;
    object-fit: contain;
  }
}

.smallBookletItem {
  max-height: 330px;
  height: 220px;
  padding: 15px 15px 10px 15px;

  .title {
    width: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .badge {
    max-width: 90px;
    padding: 2px 4px;
    font-size: 12px;
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 1000px) {
  .bookletItem {
    .content {
      justify-content: start;
    }
    .title {
      font-size: 40px;
    }

    .small {
      font-size: 24px;
    }

    .imageContainer {
      width: 200px;
      height: 220px;
    }
  }
}

@media screen and (max-width: 480px) {
  .bookletItem {
    height: auto;
    width: auto;

    .title,
    .small {
      font-size: 16px;
    }

    .imageBlock {
      justify-content: space-around;
    }
  }
}
