@import '/src/variables.scss';

.grid {
  display: flex;
  gap: 40px 0px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.slider {
  margin-bottom: 30px;

  :global {
    .slick-arrow {
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      cursor: pointer;
      z-index: 10;
      width: 48px;
      height: 48px;
      filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2));
    }

    .slick-slide > div > div {
      margin: 0 auto;
    }

    .slick-disabled {
      opacity: 0.5;
    }

    .slick-next {
      right: 0;
    }

    .slick-prev {
      left: 0;
    }
  }
}

@media screen and (max-width: 1200px) {
  .grid {
    padding: 0 16px;
  }
}
