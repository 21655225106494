@import '/src/variables.scss';

.date {
  padding: 20px 0;
  color: $blackColor;
  font-family: Loko_Lato-Bold;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.teamTable {
  margin-bottom: 20px;
}

.teamHeader {
  padding: 20px 30px;
  background-color: $whiteColor;
  border-radius: 10px 10px 0 0;
  display: flex;
  flex-direction: column;
  gap: 5px;

  .teamName {
    color: $blackColor;
    font-family: Loko_Lato-Bold;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 100%;
    /* 24px */
  }

  .teamCity {
    color: $darkGreyText;
    font-family: Loko_Lato-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 14px */
  }
}

.table {
  display: block;
  overflow-y: auto;
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
  border-spacing: 10px;
  position: relative;
  height: 100%;
  max-height: calc(82px * 5);
}

.teamStatsWrapper {
  margin-bottom: 30px;
}

.additions {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: $whiteColor;
  border-radius: 10px;

  .mainHeader {
    color: $blackColor;
    font-family: L_Halvar_Mittelschrift-Bold;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .header {
    color: $blackColor;
    font-family: Loko_Lato-Bold;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 20px;
  }

  .rowWrapper {
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-template-rows: 1fr 1fr;
    row-gap: 10px;
    column-gap: 20px;

    .row {
      display: inline-flex;
      gap: 20px;
    }

    .title {
      min-width: 86px;
      color: $darkGreyText;
      font-family: Loko_Lato-Bold;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-bottom: 10px;
    }

    .text {
      width: 100%;
      color: $blackColor;
      font-family: Loko_Lato-Regular;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.teamTabs {
  display: flex;
  gap: 7.74px;
}

.teamTab {
  width: 180px;
  height: 40px;
  border-radius: 5px;
  border: none;
  outline: none !important;
  padding: 6px 19px;
  background-color: $greyTextColor;
  font-family: Loko_Lato-Regular;
  font-weight: 400;
  font-size: 16px;
  line-height: 19.2px;
  color: $notActiveTab;
  cursor: pointer;
  margin-bottom: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &Active {
    background-color: $mainRedColor;
    color: $whiteColor;
  }
}
