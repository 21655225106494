@import '/src/variables.scss';

.svgIsBackground {
  position: absolute;
}

.contentWrapper {
  position: relative;
  min-height: 100vh;
  z-index: 20;
}

.title {
  font-family: L_Halvar_Mittelschrift-Bold;
  font-size: 52px;
  font-style: normal;
  line-height: normal;
  text-transform: uppercase;
  color: $whiteColor;
  z-index: 2;
  position: relative;
  margin-top: 0;

  @media screen and (max-width: 1550px) {
    font-size: 40px;
    margin-top: 0;
  }
  @media screen and (max-width: 1200px) {
    text-align: center;
  }
}

.arena {
  position: absolute;
  z-index: 1;
}

.article {
  position: relative;
  z-index: 5;
  width: 100%;
  height: 100%;
  display: block;
  pointer-events: none;

  .text {
    margin-bottom: 30px;
    font-family: Loko_Lato-Regular;
  }

  .container {
    padding-left: 15%;
    @media screen and (max-width: 1600px) {
      padding-left: 10%;
    }
    @media screen and (max-width: 1200px) {
      padding-left: 5%;
    }
    @media screen and (max-width: 1024px) {
      padding-left: 20px;
    }
    @media screen and (max-width: 1000px) {
      padding-left: 0;
    }
  }

  .arenaContainer {
    width: 100%;
    position: relative;
    z-index: 20;
    margin: 0;
    height: 100%;

    @media screen and (max-width: 1024px) {
      padding-left: 0;
      display: flex;
      justify-content: center;
    }
  }

  .contentContainer {
    text-align: justify;
    padding-top: 200px;
    margin-right: 60px;

    .content {
      font-family: Loko_Lato-Regular;
      font-size: 22px;
      font-style: normal;
      color: $whiteColor;
      line-height: 1.7;
      margin-bottom: 30px;
      margin-left: 190px;
      @media screen and (max-width: 1550px) {
        font-size: 14px;
      }
      @media screen and (max-width: 1024px) {
        margin-left: 0;
      }
    }

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  .arenaContentContainer {
    padding-top: 40px;
    position: absolute;
    text-align: right;
    top: 0;
    left: 20px;

    .content {
      margin-top: 33px;
      margin-bottom: 20px;
    }

    @media screen and (max-width: 1550px) {
      padding: 0 20px 0 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      top: 0;

      .content {
        margin-top: 0;
        text-align: justify;
        align-self: center;
      }
    }

    @media screen and (max-width: 1024px) {
      padding: 0 20px;
      left: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      position: relative;

      .content {
        margin-top: 0;
        text-align: justify;
        align-self: center;
      }
    }

    @media screen and (max-width: 768px) {
      width: calc(95% - 30px);
      padding: 0;
    }
  }
}

.content {
  white-space: pre-wrap;
}

.bioWrapper {
  display: flex;
  justify-content: end;
}

.bio {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 170px;
  min-width: 50%;

  @media screen and (max-width: 1024px) {
    padding-top: 0;
    min-width: 100%;
  }
}

@media screen and (max-width: 1320px) {
  .arena {
    position: absolute;
    width: 100%;
    z-index: 1;
    object-fit: cover;
  }
}
