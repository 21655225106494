@import '/src/variables.scss';

:global {
  .todo-list li {
    list-style-type: none;
  }
  li {
    line-height: 1.7;
  }
}

.row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 10px;
}

.colSm8,
.colSm4 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
}

.colSm8 {
  -webkit-flex: 0 0 66.66667%;
  -ms-flex: 0 0 66.66667%;
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.colSm4 {
  -webkit-flex: 0 0 33.33333%;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
  margin-top: 20px;
}

.wrapper {
  max-width: 1288px;
  margin: 0 auto;
  font-family: Loko_Lato-Regular;

  .title {
    color: $whiteColor;
    padding-top: 197px;
    padding-bottom: 50px;
    font-family: L_Halvar_Mittelschrift-Bold;
    font-size: 74px;
    line-height: 1;
  }

  .content {
    background-color: $whiteColor;
    padding: 20px;
    border-radius: 10px 10px 0 0;
  }

  .mainNews {
    width: 100%;
    height: 260px;
    margin-bottom: 20px;
    border-radius: 8px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 30px;

    .mainNewsContent {
      vertical-align: middle;

      .title {
        font-family: L_Halvar_Mittelschrift-Bold;
        font-size: 35px;
        font-style: normal;
        line-height: normal;
        color: $whiteColor;
        margin: 0;
      }

      .annotation {
        font-family: Loko_Lato-Regular;
        font-size: 18px;
        font-style: normal;
        line-height: normal;
      }
    }
  }
}

.ScrollToTopButtonWrapper {
  margin-top: 30px;
}

@media screen and (max-width: 830px) {
  .ScrollToTopButtonWrapper {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 1200px) {
  .row {
    flex-direction: column;
  }
  .colSm8,
  .colSm4 {
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 1320px) {
  .pageHeaderWrapper {
    padding: 0 16px;
  }
}
