@import '/src/variables.scss';

.tournament {
  font-family: Loko_Lato-Regular;
  padding: 0 16px 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .section {
    display: flex;
    gap: 10px;
    align-items: center;

    .title {
      color: $whiteColor;
      font-family: L_Halvar_Mittelschrift-Bold;
    }

    .arrow {
      height: 24px;
      width: 24px;
    }
  }
}
