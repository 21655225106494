@import "/src/variables.scss";

.schedule {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(5, 1fr);
  gap: 11.5px;
  overflow: hidden;
}

@media screen and (max-width: 1199px) {
  .schedule {
    gap: 4px;
  }
}
