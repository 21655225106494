@import '/src/variables.scss';

.table {
  overflow: scroll;
  overflow-y: hidden;
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
  border-spacing: 10px;
}

.tableHeader {
  border-top: 1px solid $strokeGreyColor;
  border-bottom: 1px solid $strokeGreyColor;
  padding: 10px 0px;
}

.tableHeader th {
  font-family: Loko_Lato-Bold;
  font-weight: 900;
  font-size: 16px;
  line-height: 19.2px;
  color: $greyTextColor;
  padding: 12px 15px;
  height: 60px;
  text-align: center;
  min-width: 68px;
  white-space: nowrap;
}

.season {
  width: 180px;
  text-align: left;
  padding-left: 10px;
  position: sticky;
  left: 0;
  background-color: $whiteColor;
}

.firstTh {
  position: sticky;
  left: 0;
  background-color: $whiteColor;
}
