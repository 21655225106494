@import '/src/variables.scss';

.newsInfo {
  padding-left: 2vw;
  padding-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: end;

  .newsInfoFlex {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1100;

    .newsInfoColumn {
      max-width: 60vw;
      padding-bottom: 30px;

      .date {
        font-family: Loko_Lato-Regular;
        font-weight: 400;
        font-size: 20px;
        line-height: normal;
        color: $whiteColor;
        margin-bottom: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .title {
        font-family: L_Halvar_Mittelschrift-Bold;
        font-weight: 700;
        font-size: 58px;
        line-height: 1.1;
        color: $whiteColor;
        margin-bottom: 60px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }

      @media screen and (max-width: 1024px) {
        .title {
          font-size: 40px;
        }
      }

      .link {
        display: flex;
        justify-content: space-between;
        width: 258px;
        height: 50px;

        &:hover {
          .arrowIcon {
            transform: rotate(45deg);
            transition: transform 0.3s ease-in-out;
          }
        }

        .linkText {
          width: 208px;
          height: 50px;
          border-radius: 5px 0px 0px 5px;
          border: 2.5px solid $whiteColor;
          font-family: Loko_Lato-Bold;
          font-weight: 600;
          font-size: 24px;
          line-height: 1.2;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $whiteColor;
        }

        .arrow {
          height: 50px;
          width: 50px;
          background-color: $whiteColor;
          border-radius: 0px 5px 5px 0px;
          border: 2.5px solid $whiteColor;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .arrowIcon {
          transition: transform 0.3s ease-in-out;
        }
      }
    }
  }

  .imgWrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: end;
    height: 80%;
    margin-right: -60px;

    .img {
      width: 100%;
      height: auto;
      z-index: 1100;
    }
  }
}

@media screen and (max-width: 1400px) {
  .newsInfo {
    .imgWrapper {
      margin-right: 0;
    }
  }
}
