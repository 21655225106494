@import '/src/variables.scss';

.post {
  display: flex;
  gap: 20px;
  border-bottom: 1px solid $strokeGreyColor;

  .imageWrapper {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    min-width: 60px;

    .image {
      width: 60px;
      height: 60px;
    }
  }

  .postBody {
    width: calc(100vw - 160px);
    display: flex;
    flex-direction: column;
    gap: 20px;

    .postTitle {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      overflow: hidden;
      align-items: center;
      gap: 20px;

      .userName {
        color: $blackColor;
        font-family: Loko_Lato-Bold;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        overflow: hidden;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .date {
        color: $greyTextColor;
        font-family: Loko_Lato-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .content {
      color: $blackColor;
      font-family: Loko_Lato-Regular;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding-bottom: 20px;
      white-space: pre-line;
      word-wrap: break-word; /* Разрыв строки на словах */
      overflow-wrap: break-word;
    }
  }
}
