@import '/src/variables.scss';

.conferences {
  display: flex;
  align-items: center;
  width: 100%;
  color: $whiteColor;
  font-size: 22px;
  color: $whiteColor;
  font-family: L_Halvar_Mittelschrift-Bold;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  .conference {
    flex: 1;
    text-align: center;
  }
}

.tournament {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 100px;
  color: $whiteColor;

  .column {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 60px;

    .matches {
      display: flex;
      flex-direction: column;
      gap: 2px;
      position: relative;
      z-index: 10;
      cursor: pointer;

      &:hover {
        > div {
          opacity: 1;
        }
      }
    }

    .image {
      width: fit-content;
      height: 100px;
    }
  }

  .column.lineLeft1 {
    &::after {
      content: '';
      position: absolute;
      top: 70px;
      left: 150px;
      z-index: 2;
      width: 0;
      height: 580px;
      border-right: 2px solid rgba(255, 255, 255, 0.3);
    }
  }

  .column.lineLeft2 {
    &::after {
      content: '';
      position: absolute;
      top: 170px;
      left: 150px;
      z-index: 2;
      width: 0;
      height: 380px;
      border-right: 2px solid rgba(255, 255, 255, 0.3);
    }
  }

  .column.lineRight1 {
    &::after {
      content: '';
      position: absolute;
      top: 70px;
      right: 150px;
      z-index: 2;
      width: 0;
      height: 580px;
      border-right: 2px solid rgba(255, 255, 255, 0.3);
    }
  }

  .column.lineRight2 {
    &::after {
      content: '';
      position: absolute;
      top: 170px;
      right: 150px;
      z-index: 2;
      width: 0;
      height: 380px;
      border-right: 2px solid rgba(255, 255, 255, 0.3);
    }
  }

  .left .matches {
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      z-index: 2;
      right: 0;
      width: 8px;
      height: 8px;
      background-color: $whiteColor;
      border-radius: 50%;
      transform: translate3d(50%, -50%, 0);
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 100%;
      z-index: 2;
      width: 125px;
      height: 25px;
      border-radius: 0 10px 0 0;
      border-top: 2px dotted rgba(255, 255, 255, 0.3);
      border-right: 2px dotted rgba(255, 255, 255, 0.3);
    }
  }

  .center .matches {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      z-index: 2;
      left: calc(50% - 8px);
      width: 8px;
      height: 8px;
      background-color: $whiteColor;
      border-radius: 50%;
      transform: translate3d(50%, -50%, 0);
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: calc(50% - 1px);
      z-index: 2;
      width: 1px;
      height: 32px;
      border-radius: 0;
      border-top: 2px dotted rgba(255, 255, 255, 0.3);
      border-right: 2px dotted rgba(255, 255, 255, 0.3);
      transform: translate(0, -100%);
    }
  }

  .right .matches {
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      z-index: 2;
      width: 8px;
      height: 8px;
      background-color: $whiteColor;
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      z-index: 2;
      width: 125px;
      height: 29px;
      border-radius: 10px 0 0 0;
      border-top: 2px dotted rgba(255, 255, 255, 0.3);
      border-left: 2px dotted rgba(255, 255, 255, 0.3);
      transform: translate(-100%, 0);
    }
  }

  .matches.lower {
    &::after {
      transform: translate(0, -100%);
      border-bottom: 2px dotted rgba(255, 255, 255, 0.3);
      border-top: none;
      border-radius: 0 0 10px 0;
    }
  }

  .matches.hidden {
    &::after {
      visibility: hidden;
    }

    &::before {
      visibility: hidden;
    }
  }

  .right .lower {
    &::after {
      content: '';
      position: absolute;
      bottom: 50%;
      top: auto;
      left: 0;
      z-index: 2;
      width: 125px;
      height: 29px;
      border-radius: 0 0 0 10px;
      border-bottom: 2px dotted rgba(255, 255, 255, 0.3);
      border-left: 2px dotted rgba(255, 255, 255, 0.3);
      transform: translate(-100%, 0);
    }
  }

  .matches.long {
    &::after {
      height: 117px;
    }
  }

  .column {
    margin-right: 50px;
  }

  .column:nth-of-type(3),
  .column:nth-of-type(4) {
    margin-right: 19px;
  }

  .column:last-of-type {
    margin-right: 0;
  }

  .column:nth-of-type(2),
  .column:nth-of-type(3),
  .column:nth-of-type(5),
  .column:nth-of-type(6) {
    .matches > div {
      &::before {
        content: '';
        position: absolute;
        top: -4px;
        z-index: 2;
        left: calc(50% - 8px);
        width: 8px;
        height: 8px;
        background-color: $mainBlueColor;
        border: 1px solid $whiteColor;
        border-radius: 50%;
        transform: translate3d(50%, -50%, 0);
      }

      &:nth-child(2)::before {
        display: none;
      }

      &:nth-child(2)::after {
        content: '';
        position: absolute;
        bottom: -8px;
        z-index: 2;
        left: calc(50% - 4px);
        width: 8px;
        height: 8px;
        background-color: $mainBlueColor;
        border: 1px solid $whiteColor;
        border-radius: 50%;
      }
    }
  }

  .column:nth-of-type(2),
  .column:nth-of-type(3),
  .column:nth-of-type(5),
  .column:nth-of-type(6) {
    .semihidden > div {
      &::before {
        visibility: hidden;
      }

      &:nth-child(2)::after {
        visibility: hidden;
      }
    }
  }

  .column:nth-of-type(3),
  .column:nth-of-type(5) {
    .matches {
      &::after {
        border-right: none;
        border-left: none;
        border-radius: 0;
        width: 32px;
      }
    }
  }

  .column:nth-of-type(2),
  .column:nth-of-type(6) {
    padding: 100px 0;
  }

  .column:nth-of-type(3),
  .column:nth-of-type(4),
  .column:nth-of-type(5) {
    justify-content: center;
  }

  .column.center {
    > div {
      flex-direction: row;
    }
  }

  .center .matches {
    position: relative;

    .winnerInfo {
      position: absolute;
      left: calc(50% - 60px);
      bottom: 100px;
      text-align: center;

      .image {
        margin-bottom: 20px;
      }
    }
  }
}
