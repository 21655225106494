@import '/src/variables.scss';

.round {
  width: 100%;
  background-color: $whiteColor;
  border-radius: 10px;

  .conference {
    padding: 20px 20px 10px;
    font-size: 24px;
    font-family: Loko_Lato-Bold;
    font-style: normal;
    line-height: normal;
    text-align: center;
  }

  .row {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 16px;
    border-radius: 10px;

    &:hover {
      background-color: $lightGrey;
      cursor: pointer;
    }
  }

  .information {
    max-width: 220px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
  }

  .information:first-of-type {
    flex-wrap: wrap-reverse;
  }

  .logo {
    width: 60px;
  }

  .name,
  .score {
    text-align: center;
    font-size: 20px;
    margin: 0;
  }
}

@media screen and (max-width: 768px) {
  .round .row .information {
    max-width: 100px;
    justify-content: center;
  }
}
