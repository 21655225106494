.photosGrid {
  display: grid;
  grid-template-rows: auto;
  gap: 20px;
  margin-top: 30px;
  margin-bottom: 40px;
}

.photosGridThreeColumns {
  grid-template-columns: 1fr 1fr 1fr;
}

.photosGridTwoColumns {
  grid-template-columns: 1fr 1fr;
}

.photosGridOneColumn {
  grid-template-columns: 1fr;
}
