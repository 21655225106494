@import '/src/variables.scss';

.statisticNumber {
  text-align: center;
  color: $whiteColor;

  &Value {
    font-family: L_Halvar_Mittelschrift-Medium;
    font-size: 75px;
    line-height: 1.158;
  }
  &Text {
    font-family: L_Halvar_Mittelschrift-Bold;
    font-size: 18px;
    line-height: 1.158;
    margin-top: 8px;
    margin-bottom: 14px;
  }
}

@media screen and (max-width: 480px) {
  .statisticNumber {
    &Value {
      font-size: 45px;
    }
    &Text {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 360px) {
  .statisticNumber {
    &Value {
      font-size: 40px;
    }
    &Text {
      font-size: 12px;
    }
  }
}
