@import '/src/variables.scss';

.wrapper {
  border-radius: 10px;
  background: $whiteColor;
  padding: 20px;

  display: flex;
  flex-direction: column;
  gap: 20px;

  .link {
    color: $mainRedColor;
    font-family: L_Halvar_Mittelschrift-Regular;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
  }

  .header {
    color: $blackColor;
    font-family: Loko_Lato-Bold;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 170%; /* 30.6px */
    margin-bottom: 5px;
  }

  .list {
    margin-left: 20px;

    color: $blackColor;
    font-family: Loko_Lato-Regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 170%;

    li::marker {
      font-size: 20px;
    }
  }
}

.collapse {
  :global {
    .ant-collapse-item {
      .ant-collapse-header {
        display: flex;
        align-items: center;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .wrapper {
    border-radius: 0 0 10px 10px;
  }
}
