@import '/src/variables.scss';

.navItem {
  float: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;
}

.navItemScroll {
  height: 68px;
}

.linkWrapper {
  width: 100%;
}

.linkWrapper:hover,
.linkWrapperActive {
  background-color: $whiteColor;
  border-radius: 10px;
}

.dropdown {
  position: relative;
}

.navLink {
  display: inline-block;
  color: $whiteColor;
  cursor: default;
}

.dropdownToggle:focus {
  outline: 0;
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 200px;
  padding: 20px;
  font-size: 1rem;
  color: #373a3c;
  text-align: left;
  list-style: none;
  background-color: $whiteColor;
  background-clip: padding-box;
  border-radius: 0px 0px 10px 10px;
  box-shadow: 1px 4px 15px 0px $shadowColor;
}

.open > .dropdownMenu {
  display: block;
}

.open > a {
  outline: 0;
}

.dropdownItem {
  display: block;
  width: 100%;
  clear: both;
  font-family: Loko_Lato-Medium;
  font-size: 16px;
  line-height: 1.2;
  color: #000;
  text-align: inherit;
  white-space: nowrap;
  background: none;
  border: 0;
}

.dropdownItem:focus,
.dropdownItem:hover {
  color: $mainRedColor;
  text-decoration: none;
}

.linkText {
  display: block;
  font-family: L_Halvar_Mittelschrift-Medium;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.16;
  color: $whiteColor;
  padding: 7px 15px;
}

.linkText:hover,
.linkTextActive {
  color: $mainRedColor;
  display: block;
  padding: 7px 15px;
}

.dropdownMenuContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
}
