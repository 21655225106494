$darkBlue: #0c1931;
$darkBlue2: #16223a;
$whiteColor: #fff;
$blackColor: #000;
$mainRedColor: #c8102e;
$mainRedColor20Pct: #c8102f33;
$mainBlueColor: #00256d;
$mainBlueColor40pct: #00256d66;
$strokeGreyColor: #f1f1f2;
$darkGreyText: #5b5b5b;
$greyTextColor: #a3a3a3;
$lightGrey: #e1e1e1;
$darkGrey: #5b5b5b;
$shadowPlayersColor: #00256d1a;
$bannerRedColor: #ff1313;
$triangleColor: #a3a3a3;
$triangleColorStandingPage: #acacac;
$articleContentBackground: #f1f1f2;
$flagColor: #002a70;
$whiteColor30Pct: rgba(255, 255, 255, 0.3);
$darkBorderColor: #ffffff1a;

$backgroundIconColor: rgba(255, 255, 255, 0.2);
$borderBioBoxColor: rgba(255, 255, 255, 0.08);

$shadowColor: #0000004d;
$darkRedColor: #b40925;

$borderBioBoxColor: rgba(255, 255, 255, 0.08);
$greyTextColor: #a3a3a3;

$backgroundIconColor: rgba(255, 255, 255, 0.2);
$notActiveTab: #5b5b5b;

$newsDateText: #a3a3a3;
$newsGradient: rgba(0, 0, 0, 0.7);

$lidersBorder: rgba(255, 255, 255, 0.1);

$footerSocialNetworkscolor: #16223a;
$footerSocialTextColor: #f4f6fa;
$smallFooterTextColor: #9aa0a9;
$footerLineColor: #ffffff1a;
$footerBorderColor: #243046;
$lokomaniaBlueColor: #002f71;

$playoffTileBlueColor: #001d57;

$blueSector: rgba(0, 98, 251, 1);
$greenSector: rgba(5, 145, 0, 1);
$pinkSector: rgba(137, 9, 128, 1);
$orangeSector: rgba(248, 116, 9, 1);
$greenGuestSector: rgba(1, 92, 111, 1);
$roseSector: rgba(255, 96, 194, 1);
$aquaSector: rgba(0, 254, 254, 1);
$yellowSector: rgba(255, 211, 9, 1);
$darkOrangeSctor: rgba(168, 69, 41, 1);
$redSector: rgba(249, 3, 0, 1);
$darkRedSector: rgba(200, 16, 46, 1);
