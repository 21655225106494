@import '/src/variables.scss';

.innerWrapper {
  padding: 0 20px;

  .searchWrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 25px;
    gap: 10px;

    .select {
      border-radius: 8px;
    }

    .selectCategory {
      width: 220px;
    }

    .selectYear,
    .selectMonth {
      width: 120px;
    }
  }

  .photogalleryContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 25px;
    column-gap: 20px;
    padding-bottom: 25px;

    > div {
      max-width: 500px;
      width: 100%;
      margin: 0 auto;
    }
  }

  .viewMoreWrapper {
    margin-bottom: 30px;
  }

  .photoNotFound {
    width: 100%;
    text-align: center;
    padding: 25px 0;
    font-family: Loko_Lato-Regular;
    font-size: 20px;
  }
}

.scrollToTopButton {
  padding-bottom: 20px;
}

@media screen and (max-width: 1000px) {
  .innerWrapper .photogalleryContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .innerWrapper {
    .searchWrapper {
      .select {
        width: 100%;
      }
    }

    .photogalleryContainer {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

@media screen and (max-width: 1320px) {
  .pageHeaderWrapper {
    padding: 0px 16px;
  }
}
