@import '/src/variables.scss';

.drawerPadding {
  padding: 55px 16px 20px 16px;

  .drawerItems {
    padding-top: 20px;
  }
}

.hiddenTopPadding {
  padding: 0px 16px 20px 16px;
}
