@import '/src/variables.scss';

.wrapper {
  margin: 10px 20px 0 20px;
  background-color: $whiteColor;
  border-radius: 10px;
}

.title {
  font-family: L_Halvar_Mittelschrift-Bold;
  font-size: 35px;
  font-weight: 700;
  line-height: 41px;
  letter-spacing: 0em;
  text-align: left;
  color: $blackColor;
}

.titleFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.innerWrapper {
  padding: 30px 20px 20px 20px;
}

.breadcrumbsPadding {
  padding-left: 20px;
  padding-bottom: 20px;
}

.select {
  width: 220px;
  height: 40px;
  padding: 0px 20px 0px 20px;
  border-radius: 8px;
  border: none;
  background: url(../../assets/arrow_filter.svg) $strokeGreyColor center right
    15px no-repeat;
  font-family: Loko_Lato-Medium;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: $darkGrey;
}

.tabBar {
  margin-top: 40px;
  overflow: auto;
  white-space: nowrap;
}

a .tabSpan {
  color: $notActiveTab;
}

a.active .tabSpan {
  color: $mainRedColor;
}

.tabBar a {
  font-family: Loko_Lato-Regular;
  font-weight: 400;
  font-size: 18px;
  color: $notActiveTab;
  line-height: 21.6px;
  margin-right: 40px;
  position: relative;
  display: inline-block;
  padding-bottom: 12px;
  margin-bottom: 30px;
}

.tabBar a:after {
  content: '';
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: $mainRedColor;
  opacity: 0;
  transition: opacity 0.2s;
}

.tabBar a.active {
  color: $mainRedColor !important;
  font-family: Loko_Lato-Bold;
  font-weight: 800;
  text-decoration: none;
}

.tabBar a.active:after {
  opacity: 1;
  color: $mainRedColor !important;
}

.text {
  display: none;
}

.textShow {
  display: block;
}

.subTabs {
  display: flex;
  gap: 40px;
  align-items: center;
  margin-bottom: 20px;
  overflow: auto;
}

.active {
  font-family: L_Halvar_Mittelschrift-Bold;
  font-size: 32px;
  line-height: 1.2;
  letter-spacing: 0px;
  text-align: center;
  color: $blackColor;
  cursor: pointer;
}

.subTab {
  font-family: L_Halvar_Mittelschrift-Bold;
  font-size: 22px;
  line-height: 1.14;
  letter-spacing: 0px;
  text-align: center;
  color: $greyTextColor;
  cursor: pointer;
}

.selectsWrappers {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.table {
  display: block;
  overflow: auto;
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
  border-spacing: 10px;
}

.firstTh {
  position: sticky;
  left: 0;
  background-color: $whiteColor;
  z-index: 1;
}

.tableHeader {
  border-top: 1px solid $strokeGreyColor;
  border-bottom: 1px solid $strokeGreyColor;
  padding: 10px 0px;
}

.club {
  width: 250px;
  text-align: left;
  padding-left: 10px;
  position: sticky;
  left: 0;
  background-color: $whiteColor;
}

.divisionText {
  font-family: Loko_Lato-Regular;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0px;
  color: $blackColor;
}

.statsTableWrapper {
  overflow-x: auto;
  height: 100%;
  max-height: calc(82px * 5);
}

.tableHeader th {
  font-family: Loko_Lato-Black;
  font-weight: 900;
  font-size: 16px;
  line-height: 1.2;
  padding: 12px 15px;
  height: 60px;
  text-align: center;
  min-width: 68px;
  position: sticky;
  top: -1px;
  background-color: $whiteColor;
}

.tableMarginBottom {
  margin-bottom: 40px;
}

.selectWrapper {
  display: flex;
}

.printerBtn {
  outline: none !important;
  border: none !important;
  background-color: $whiteColor;
}

.buttonDownloadWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonDownload {
  outline: none !important;
  border: none !important;
  background-color: $mainRedColor;
  color: $whiteColor;
  font-family: Loko_Lato-Heavy;
  font-weight: 800;
  font-size: 14px;
  line-height: 1.2;
  margin-left: 10px;
  height: 30px;
  border-radius: 5px;
}

@media screen and (max-width: 480px) {
  .active,
  .subTab {
    font-size: 22px;
    line-height: 1.14;
  }
  .subTabs {
    justify-content: center;
  }
  .divisionText {
    font-size: 17px;
    line-height: 1.2;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.team {
  width: 130px;
}
