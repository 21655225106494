.modal {
  :global {
    .ant-modal-content {
      padding: 10px 10px 5px 10px;
      height: 100%;
    }

    .ant-modal-body {
      height: 100%;
    }

    .ant-modal-footer {
      margin: 0;
    }
  }

  margin: 0;
  padding: 0;
  max-width: 100vw;
  max-height: 100vh;
  width: 60vw !important;
  height: calc(0.6 * 60vw);

  .modalContent {
    height: 100%;
    width: 100%;

    .player {
      height: 100%;
    }
  }
}

.video {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 900px) {
  .modal {
    :global {
      width: 100vw !important;
      height: calc(0.6 * 100vw);
    }
  }
}
