@import '/src/variables.scss';

.gallery {
  .customImage img {
    height: 652px;
    border-radius: 8px;
    object-fit: cover !important;
    object-position: top;
  }

  .image-gallery-icon:hover {
    color: $mainRedColor;
  }

  .image-gallery-thumbnails {
    padding: 5px 3px;
  }

  .image-gallery-thumbnail {
    &:focus,
    &:hover,
    &.active {
      border: none;
      border-radius: 8px;
      outline: 3px solid $mainRedColor;
    }

    img {
      border-radius: 8px;
      height: 84px;
      object-fit: cover;
    }
  }
}

@media screen and (max-width: 768px) {
  .gallery .customImage img {
    height: 450px;
  }
}
