@import '/src/variables.scss';

.shopButton {
  user-select: none;

  .shopButtonText {
    font-family: L_Halvar_Mittelschrift-Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.shopButtonHeader {
  background-color: $whiteColor;
  .shopButtonTextHeader {
    color: $mainRedColor;
  }
}

.shopButtonDrawer {
  background-color: $mainRedColor;

  img {
    width: 24px;
    height: 19px;
  }

  .shopButtonTextDrawer {
    color: $whiteColor;
  }
}
