@import '/src/variables.scss';

.wrapper {
  height: 330px;
  width: 225px;
  margin: 0 auto;
}

.innerWrapper {
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.period {
  display: block;
  font-family: hc_Lokomotiv;
  font-size: 50px;
  font-weight: 400;
  line-height: 1.7;
  letter-spacing: 0em;
  text-align: justify;
  color: $whiteColor;
}

.title {
  display: block;
  font-family: Loko_Lato-Regular;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0em;
  text-align: left;
  color: $whiteColor;
}

.flag {
  width: 100%;
  height: 280px;
  background-color: $mainRedColor;
  position: relative;
}

.hoverFlag {
  width: 100%;
  height: 280px;
  background-color: $flagColor;
  position: relative;
}

.flag::before {
  position: absolute;
  display: block;
  content: '';
  top: 279.26px;
  width: 0;
  height: 0;
  border-top: 53.86px solid $mainRedColor;
  border-right: 112.5px solid transparent;
}

.flag::after {
  position: absolute;
  display: block;
  content: '';
  top: 279.26px;
  right: 0;
  width: 0;
  height: 0;
  border-top: 53.86px solid $mainRedColor;
  border-left: 112.5px solid transparent;
}

.hoverFlag::before {
  position: absolute;
  display: block;
  content: '';
  top: 279.26px;
  width: 0;
  height: 0;
  border-top: 53.86px solid $flagColor;
  border-right: 112.5px solid transparent;
}

.hoverFlag::after {
  position: absolute;
  display: block;
  content: '';
  top: 279.26px;
  right: 0;
  width: 0;
  height: 0;
  border-top: 53.86px solid $flagColor;
  border-left: 112.5px solid transparent;
}
