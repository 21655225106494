@import '/src/variables.scss';

.sideMenuWrapper {
  display: flex;
  flex-direction: column;

  .partnerSection {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 20px;

    .imageWrapper {
      overflow: hidden;
      cursor: pointer;
      text-align: center;

      img {
        border-radius: 10px;
      }
    }
  }
}
