@import '/src/variables.scss';

.selectContainer {
  display: inline-block;
  position: relative;
  height: 40px;
  background-color: $articleContentBackground;
  border-radius: 5px;
  padding: 8px 20px;
  display: flex;
  align-items: center;
  vertical-align: middle;
  cursor: pointer;
}

.containerShowDropdown {
  border-radius: 5px 5px 0px 0px;
}

.selectedText {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Loko_Lato-Regular;
  font-size: 12px;
  font-weight: 400;
  color: $darkGrey;
  text-transform: uppercase;
  width: 100%;
}

.selectArrow {
  margin-left: 8px;

  svg {
    width: 14px;
    height: 8px;
  }
}

.selectOptions {
  padding: 0;
  margin: 0;
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 10001;
  width: 100%;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  background-color: #fff;
  overflow-y: scroll;
  max-height: 140px;
}

.showDropdownOptions {
  min-height: 50px;
  opacity: 1;
  visibility: visible;
  padding: 10px 20px;
}

.hideDropdownOptions {
  min-height: 0;
  opacity: 0;
  visibility: hidden;
}

@media screen and (max-width: 768px) {
  .selectContainer {
    width: 100%;
  }
}
