@import '/src/variables.scss';

.wrapper {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;

  .image {
    width: 100%;
    aspect-ratio: 275/330;
    border-radius: 10px;
    margin-bottom: 20px;
    object-fit: cover;
  }

  .fullname {
    font-family: L_Halvar_Mittelschrift-Bold;
    font-size: 22px;
    font-weight: 700;
    line-height: 1.14;
    letter-spacing: 0em;
    text-align: left;
    color: $blackColor;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid $strokeGreyColor;
    text-transform: uppercase;
  }

  .position {
    font-size: 16px;
    font-family: Loko_Lato-Regular;
    color: $darkGreyText;
    margin-bottom: 0px;
    font-weight: 400;
    line-height: 1.2;
  }

  .bottomPosition {
    margin-bottom: auto;
  }
}

.birthText {
  font-family: Loko_Lato-Medium;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0em;
  text-align: left;
  color: $blackColor;
  margin: 0 0 10px 0;
}

.birth {
  font-family: L_Halvar_Mittelschrift-Medium;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.16;
  letter-spacing: 0em;
  text-align: left;
  color: $blackColor;
  margin: 0;
}
