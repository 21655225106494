@import '/src/variables.scss';

.wrapper {
  margin: 00px 20px;
  background-color: $whiteColor;
  border-radius: 10px;
}

.title {
  font-family: L_Halvar_Mittelschrift-Bold;
  font-size: 35px;
  line-height: 1.158;
  letter-spacing: 0em;
  text-align: left;
  color: $blackColor;
}

.titleFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.innerWrapper {
  padding: 30px 20px 20px 20px;
}

.selectWrapper {
  display: flex;
}

.select {
  width: auto;
  height: 40px;
  padding: 0px 40px 0px 20px;
  border-radius: 8px;
  gap: 8px;
  border: none;
  background: url(../../assets/arrow_filter.svg) $strokeGreyColor center right
    15px no-repeat;
  font-family: Loko_Lato-Medium;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: $darkGreyText;
}

option {
  font-family: Loko_Lato-Medium;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: $darkGreyText;
}

.tabBar {
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  gap: 40px;
}

.tabBar a {
  font-family: Loko_Lato-Bold;
  font-size: 32px;
  line-height: 1.158;
  letter-spacing: 0px;
  text-align: center;
  color: $greyTextColor !important;
}

.tabBar a.active,
.tabBar a:active,
.tabBar a:focus,
.tabBar a:hover {
  color: $blackColor !important;
  font-family: Loko_Lato-Bold;
  font-size: 32px;
  line-height: 1.158;
  letter-spacing: 0px;
  text-align: center;
  text-decoration: none;
}

.text {
  display: none;
}

.textShow {
  display: block;
}

.table {
  display: block;
  overflow-y: hidden;
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
  border-spacing: 10px;
}

.tableHeader {
  border-top: 1px solid $strokeGreyColor;
  border-bottom: 1px solid $strokeGreyColor;
  padding: 10px 0px;
}

.club {
  width: 250px;
  text-align: left;
  padding-left: 10px;
  position: sticky;
  left: 0;
  background-color: $whiteColor;
}

.breadcrumbsWrapper {
  padding-left: 20px;
  padding-bottom: 30px;
}

.statsTableWrapper {
  overflow-x: auto;
  height: 100%;
  // max-height: calc({высота_игрока}px * {кол-во_игроков + 1(для шапки)}
  // + {высота игрока} / 2) если показать последнего игрока наполовину
  max-height: calc(82px * 7);
}

.printerBtn {
  outline: none !important;
  border: none !important;
  background-color: $whiteColor;
}

.buttonDownloadWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonDownload {
  outline: none !important;
  border: none !important;
  background-color: $mainRedColor;
  color: $whiteColor;
  font-family: Loko_Lato-Heavy;
  font-weight: 800;
  font-size: 14px;
  line-height: 1.2;
  margin-left: 10px;
  height: 30px;
  border-radius: 5px;
}

@media screen and (max-width: 1000px) {
  .titleFlex {
    display: block;
  }
}

@media screen and (max-width: 480px) {
  .tabBar {
    gap: 20px;
    justify-content: center;
    align-items: center;
  }
}
