@import '/src/variables.scss';

.wrapper,
.wrapperHomePage {
  border-radius: 10px;
  padding: 20px;
  background-color: $whiteColor;
  height: 100%;
  border: 1px solid $strokeGreyColor;
}

.header {
  font-family: L_Halvar_Mittelschrift-Bold;
  font-size: 35px;
  line-height: 1.16;
  color: $blackColor;
}

@media screen and (max-width: 480px) {
  .wrapperHomePage {
    padding: 0;
    border: none;
  }
}

@media screen and (max-width: 360px) {
  .header {
    font-size: 24px;
  }
}
