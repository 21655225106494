@import '/src/variables.scss';

.wrapper {
  margin: 0 20px;
  background-color: $whiteColor;
  border-radius: 10px;
}

.subTitle {
  font-family: L_Halvar_Mittelschrift-Bold;
  font-size: 35px;
  font-weight: 700;
  line-height: 41px;
  letter-spacing: 0em;
  text-align: left;
  color: $blackColor;
  margin-bottom: 30px;
}

.title {
  font-family: L_Halvar_Mittelschrift-Bold;
  font-size: 35px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0em;
  text-align: left;
  color: $blackColor;
  margin-bottom: 0;
}

.titleFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.select {
  width: 220px;
}

.breadcrumbsWrapper {
  margin-bottom: 197px;
}

@media screen and (max-width: 550px) {
  .titleFlex {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
  }
}

.catastropheHeaderText {
  font-family: L_Halvar_Mittelschrift-Bold;
  font-size: 74px;
  line-height: 1.16;
  letter-spacing: 0em;
  text-align: left;
  color: $whiteColor;
  margin-bottom: 50px;
}

@media screen and (max-width: 1320px) {
  .catastropheHeaderText {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media screen and (max-width: 768px) {
  .catastropheHeaderText {
    font-size: 50px;
  }
}
