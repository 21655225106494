@import '/src/variables.scss';

.infoWrapper {
  position: relative;
  z-index: 2;
  background-color: $mainBlueColor;
  border-radius: 10px;

  &PlayerPage {
    padding-top: 30px;
  }

  &Inner {
    background-color: $strokeGreyColor;
    border-radius: 10px 10px 0px 0px;

    &Player {
      background-color: $whiteColor;
      border-radius: 10px 10px 0px 0px;
    }
  }
}

.whiteBackground {
  background-color: $whiteColor;
  min-height: 300px;
}

.innerPadding {
  padding-top: 30px;
}

.infoWrapperPaddingZero {
  padding-left: 0;
  padding-right: 0;
}
