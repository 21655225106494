@import '/src/variables.scss';

.tableHeader {
  border-top: 1px solid $strokeGreyColor;
  border-bottom: 1px solid $strokeGreyColor;
  padding: 10px 0px;
  background-color: $strokeGreyColor;
  border-left: 2.5px solid $mainRedColor;
}

.tableHeader th {
  font-family: Loko_Lato-Black;
  font-size: 14px;
  line-height: 1.2;
  padding: 12px 15px;
  height: 60px;
  text-align: center;
  min-width: 38px;
  position: sticky;
  top: -1px;
  background-color: $articleContentBackground;
}

.tableTr {
  border-top: 1px solid $strokeGreyColor;
  border-bottom: 1px solid $strokeGreyColor;
  background-color: $whiteColor;
}

.tableTrBottom {
  border-top: 1px solid $strokeGreyColor;
  border-bottom: 1px solid $mainRedColor;
}

.tableTr td {
  font-family: Loko_Lato-Regular;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: $blackColor;
  height: 60px;
  padding: 17px 20px 15px 15px;
  text-align: center;
}

.tableTr .club {
  font-family: Loko_Lato-Regular;
  font-weight: 400;
  font-size: 14px;
  line-height: 16.8px;
  color: $blackColor;
  height: 60px;
  padding: 17px 20px 17px 15px;
  text-align: left;
}

.tableHeader th .club {
  text-align: left;
}

.role {
  width: 250px;
  padding-left: 0px;
  position: sticky;
  left: 0;
  text-align: left;
}

.club {
  width: 250px;
  padding-left: 0px;
  position: sticky;
  left: 0;
  text-align: left;
  background-color: $whiteColor;
}

.name {
  display: block;
  font-family: Loko_Lato-Bold;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: $blackColor;
  white-space: nowrap;
}

.position {
  display: block;
  font-family: Loko_Lato-Regular;
  font-size: 11px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: $greyTextColor;
}

.playerInfo {
  display: flex;
  gap: 16px;
  align-items: center;
}

.number {
  font-family: hc_Lokomotiv;
  font-size: 19px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0em;
  text-align: left;
  color: $blackColor;
}

.pointer {
  cursor: pointer;
}
