@import '/src/variables.scss';

.wrapper {
  display: flex;
  align-self: flex-end;
  gap: 10px;
  position: absolute;
  right: 0;
}

.activeWrapper {
  display: flex;
  gap: 20px;
}

.likeSection {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;

  .counter {
    color: $greyTextColor;
    font-family: Loko_Lato-Bold;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .counterMainNews {
    font-size: 16px;
  }

  .likeFilled {
    color: $mainBlueColor;
  }

  .likeFilledMainNews {
    color: $whiteColor;
  }

  .active {
    font-size: 16px;
  }

  .dislike {
    color: $greyTextColor;
  }

  .dislikeFilled {
    color: $greyTextColor;
  }

  .dislikeNotMainNews {
    color: $lightGrey;
  }
}

.pointer {
  cursor: pointer;
}

.pointer:hover {
  transform: scale(1.2);
}

.image {
  width: 16px;
  height: 16px;
}

.activeImage {
  width: 24px;
  height: 24px;
}
