@import '/src/variable.scss';

.customLogo {
  width: 32px;
  height: 32px;
}

.dateNumber {
  font-family: L_Halvar_Mittelschrift-Bold;
  font-weight: 700;
  font-size: 32px;
  line-height: 1.2;
  color: $whiteColor;

  &Home {
    color: $mainRedColor;
  }

  &Away {
    color: $mainBlueColor;
  }
}

.dateName {
  font-family: Loko_Lato-Regular;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: $whiteColor;
}

.greyText {
  color: $greyText;
}

.logoWrapper {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.divider {
  color: $greyText;
  font-family: L_Halvar_Mittelschrift-Bold;
  font-size: 16px;
  line-height: 1.16;
  letter-spacing: 2.4px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1px;
}

.gameWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home .day,
.away .day {
  position: absolute;
  top: 0;
  width: 100%;
  min-height: 150px;
  border: 0;
}

.slickDay {
  width: 100px;
  position: relative;
  margin-top: 25px;
}

.slickDay.home,
.slickDay.away,
.slickDayMobile.home,
.slickDayMobile.away {
  margin: 0;
}

.day {
  border-left: solid 3px $whiteColor;
  text-align: center;
  padding-top: 16px;
  padding-bottom: 20px;
  border-top: solid 3px $whiteColor;
  border-bottom: solid 3px $whiteColor;
  margin-right: -1.5px;
  margin-left: -1.5px;
  border-right: solid 3px $whiteColor;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slickDayMobile {
  width: 70px;
  height: 70px;
  position: relative;
  margin-top: 25px;
  margin-bottom: 25px;
  padding-bottom: 0;
}

.dayMobile {
  border-left: solid 3px $whiteColor;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  border-top: solid 3px $whiteColor;
  border-bottom: solid 3px $whiteColor;
  margin-right: -1.5px;
  margin-left: -1.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: solid 1.5px #fff;
}

.dayMobileHome {
  text-align: center;
  margin-right: -1.5px;
  margin-left: -1.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.slickDay.away .day {
  position: absolute;
  top: 0;
  width: 100%;
  min-height: 150px;
  border: 0;
}

.customHome {
  width: 150px;
  height: 150px;
  border-radius: 8px;
  border: none;
  box-sizing: border-box;
  border-top: solid 3px $whiteColor;
  border-left: solid 3px $whiteColor;
  background: linear-gradient(
    -62deg,
    $whiteColor 0% 74.8%,
    $mainRedColor 75% 100%
  );
  color: $mainRedColor;
}

.customAway {
  width: 150px;
  height: 150px;
  border-radius: 8px;
  border-top: solid 3px $whiteColor;
  border-left: solid 3px $whiteColor;
  background: linear-gradient(
    -62deg,
    $whiteColor 0% 74.8%,
    $mainBlueColor 75% 100%
  );
  color: $mainBlueColor;
}

.mobileGame {
  width: 120px;
  height: 120px;
}

.mobileDefault {
  width: 70px;
  height: 70px;
}

.category {
  font-family: Loko_Lato-Regular;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: $greyText;
}
