@import '/src/variables.scss';

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
  margin-left: -3px;

  .tableHeader {
    color: $whiteColor;
    font-family: L_Halvar_Mittelschrift-Bold;
    font-size: 18px;
    line-height: 1.16;

    th div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 80px;
      background-color: $mainBlueColor;
      border-radius: 5px;
      margin: 0 3px;
    }

    .bigCell div {
      min-width: 120px;
      height: 140px;
    }

    .middleCell div {
      height: 80px;
    }

    .smallCell div {
      min-width: 35px;
      height: 50px;
    }

    .middleCell:last-child div,
    .smallCell:last-child div {
      margin-right: 0;
    }
  }

  .tableRow {
    background-color: $strokeGreyColor;
    border-radius: 5px;
    height: 60px;
    margin-bottom: 10px;

    td {
      text-align: center;
      color: $blackColor;
      font-size: 16px;
      line-height: 1.2;
      font-family: Loko_Lato-Regular;
    }
  }
}
