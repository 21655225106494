@import '/src/variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .writingForm {
    width: 100%;

    .formHeader {
      width: 100%;
      padding: 10px 20px;
      border-radius: 5px 5px 0px 0px;
      background: $strokeGreyColor;

      color: $darkGreyText;
      font-family: Loko_Lato-Regular;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .textarea {
      width: 100%;
      height: 120px;
      border-left: 1px solid $strokeGreyColor;
      border-right: 1px solid $strokeGreyColor;
      border-top: 0;
      border-bottom: 0;
      padding: 20px;
    }

    .textarea:focus {
      outline: none;
    }

    .formatButtons {
      border-left: 1px solid $strokeGreyColor;
      border-right: 1px solid $strokeGreyColor;
      border-top: 0;
      border-bottom: 1px solid $strokeGreyColor;
      border-radius: 0px 0px 10px 10px;
      padding: 0 20px 20px;
      display: flex;
      justify-content: flex-end;
      gap: 6px;

      .iconWrapper {
        cursor: pointer;

        .icon {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  .actionBlock {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    .userBlock {
      display: flex;
      align-items: center;
      gap: 15px;

      .imageWrapper {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        min-width: 40px;

        .image {
          width: 40px;
          height: 40px;
        }
      }

      .userName {
        color: $blackColor;
        font-family: L_Halvar_Mittelschrift-Regular;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .actionButtons {
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: flex-end;

      .actionButton {
        padding: 8px 24px;
        border-radius: 8px;
        border: 0;
        background: $strokeGreyColor;
        color: $darkGreyText;
        font-family: Loko_Lato-Bold;
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        height: 40px;
        width: 140px;
      }

      .active {
        background: $mainRedColor;
        color: $whiteColor;
      }
    }
  }
}
