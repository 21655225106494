@import '/src/variables.scss';

.bottomRightBlock {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 3;
  height: 160px;
  width: 250px;
  background-color: $mainRedColor;
  border-radius: 10px 0 0 0;
  border: 2px solid #fff;
}

.insideBlock {
  height: 100%;
  width: 100%;
  background: linear-gradient(120deg, transparent 50px, white 25px);
  border-radius: 3px 0 0 0;
}

.fullVersionBtn {
  background: none;
  padding-top: 8px;
  padding-left: 8px;
  border: none;
  outline: none !important;

  &:hover {
    outline: none;
  }
}

.fullVersionArrow {
  transform: rotate(90deg);
}

.smallBottomRightBlock {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 3;
  height: 120px;
  width: 60px;
  background-color: none;
}

.insideSmallBlock {
  height: 100%;
  width: 100%;
  background: linear-gradient(120deg, transparent 63px, #c8102e 25px);
}

.insideBigSmallBlock {
  height: 100%;
  width: 100%;
  background: linear-gradient(120deg, transparent 60px, $whiteColor 25px);
}

.smallVersionBtn {
  position: fixed;
  bottom: 0;
  right: 0;
  margin-bottom: 5px;
  margin-right: 5px;
  background: none;
  border: none;
  outline: none !important;

  &:hover {
    outline: none;
  }
}

.smallVersionArrow {
  transform: rotate(270deg);
}
