@import '/src/variables.scss';

.tableTr {
  border-top: 1px solid $strokeGreyColor;
  border-bottom: 1px solid $strokeGreyColor;
}

.tableTr td {
  font-family: Loko_Lato-Regular;
  font-weight: 400;
  font-size: 14px;
  line-height: 16.8px;
  color: $blackColor;
  height: 60px;
  padding: 10px 20px 10px 15px;
  text-align: center;
}

.firstTd {
  width: 180px;
  margin-left: 10px;
  text-align: left;
  position: sticky;
  left: 0;
  background-color: $whiteColor;
}

.firstTd div {
  font-family: Loko_Lato-Regular;
  font-weight: 700;
  font-size: 14px;
  line-height: 16.8px;
  color: $blackColor;
}

.season {
  display: block;
  font-family: Loko_Lato-Regular;
  font-weight: 400;
  font-size: 12px;
  color: $blackColor;
}
