@import '/src/variables.scss';

.logoContainer {
  display: flex;
  flex: 1;
  position: sticky;
  width: auto;
}

.leftBlock {
  display: flex;
  line-height: 0;
  overflow: hidden;

  .block {
    line-height: 0;
  }

  .line {
    margin-left: -60px;
    line-height: 0;
  }
}

.rightBlock {
  display: flex;
  line-height: 0;
  overflow: hidden;

  .block {
    line-height: 0;
  }

  .line {
    margin-right: -60px;
    line-height: 0;
  }
}

.content {
  display: flex;
  justify-content: space-between;
  position: sticky;
  width: 100%;
  align-items: center;
  flex: 1;
  padding: 0 44px;
}

.team {
  align-items: center;
  display: flex;
  min-width: calc((100% - 92px) / 3 - 40px);
  justify-content: flex-end;
}

.justifyAtEnd {
  justify-content: flex-start;
}

.logo {
  width: 35px;
  height: 35px;
  margin: 0 20px;
  @media screen and (max-width: 360px) {
    width: 30px;
    height: 30px;
    margin: 0 10px;
  }
}

.textContainer {
  flex: 1;
  text-align: center;
  align-items: center;
}

.teamTextWrapper {
  text-align: center;
}

.gameDateText {
  font-family: Loko_Lato-Black;
  font-weight: 400;
  font-size: 10px;
  color: $blackColor;
  margin-bottom: 5px;
}

.buyTicketsText {
  font-family: Loko_Lato-Black;
  font-weight: 400;
  font-size: 16px;
  margin-top: -5px;
  color: $mainRedColor;
}

.city {
  font-size: 10px;
  line-height: 10px;
  font-weight: 500;
  font-family: Loko_Lato-Regular;
  color: $greyTextColor;
}

.teamName {
  font-size: 14px;
  line-height: 14px;
  font-weight: 300;
  font-family: Loko_Lato-Bold;
  color: $blackColor;
}
