@import "/src/variables.scss";

.button {
  display: flex;
  width: 196px;
  height: 40px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 8px;
  background: $mainRedColor;

  .buttonText {
    font-family: Loko_Lato-Bold;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    color: $whiteColor;
  }
}
