@import '/src/variables.scss';

.topstory {
  padding: 0;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 350px;
  border-radius: 10px;
  display: block;

  &Inner {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    text-align: center;
    padding: 16px;
    background-color: $whiteColor;
    border-radius: 0px 0px 10px 10px;
  }

  &Text {
    height: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
  }

  &Heading {
    font-family: Loko_Lato-Black;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.2;
    color: $blackColor;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 0;

    &Column {
      font-family: Loko_Lato-Bold;
      font-size: 16px;
      line-height: 1.2;
      letter-spacing: 0em;
      text-align: left;
      color: $blackColor;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-bottom: 2px;
    }
  }

  &Footer {
    display: flex;
    justify-content: space-between;
  }

  &Date {
    font-family: Loko_Lato-Regular;
    font-size: 12px;
    line-height: 1.2;
    color: $newsDateText;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &Img {
    width: 100%;
    height: auto;
    border-radius: 10px 10px 0px 0px;
    position: relative;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    object-fit: cover;
    transition: 1s;
    display: block;

    &:before {
      display: block;
      content: '';
      width: 100%;
      height: 85%;
      background-image: linear-gradient(
        to top,
        $newsGradient 0%,
        transparent 70%
      );
      position: absolute;
      bottom: 0;
      left: 0;
    }

    &:hover {
      transform: scale(1.1);
    }
  }
}
