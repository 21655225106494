@import "/src/variables.scss";

.footer {
  height: 90px;
  width: 100%;
  background-color: $mainRedColor;

  .divider {
    height: 90px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .dividerLine {
      border-left: 1px solid $whiteColor;
      height: 10px;
    }
  }
}
