@import '/src/variables.scss';

.authWidget {
  border-radius: 10px;
  background: $whiteColor;
  padding: 40px 20px 30px;

  .confirmNotification {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 20px;
    text-align: center;

    .header {
      color: $mainRedColor;
      font-variant-numeric: lining-nums proportional-nums;
      font-family: L_Halvar_Mittelschrift-Bold;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 170%; /* 30.6px */
    }

    .message {
      color: $blackColor;
      text-align: center;
      font-family: Loko_Lato-Regular;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 170%; /* 27.2px */
    }

    .closeButtonWrapper {
      display: flex;
      justify-content: center;

      .closeIcon {
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
    }
  }

  .tabBar {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;

    .refItem {
      color: $greyTextColor;
      font-family: L_Halvar_Mittelschrift-Bold;
      font-size: 35px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      cursor: pointer;
    }

    .active,
    .refItem:active,
    .refItem:focus,
    .refItem:hover {
      color: $blackColor;
      font-family: L_Halvar_Mittelschrift-Bold;
      font-size: 35px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .separator {
      color: $greyTextColor;
      font-family: L_Halvar_Mittelschrift-Bold;
      font-size: 35px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  .text {
    display: none;
  }

  .textShow {
    display: block;
  }

  .wrapper {
    width: 100%;
    margin-top: 30px;
  }
}
