@import '/src/variables.scss';

.open {
  display: block;
  outline: 0;
}

.navbar {
  height: 52px;
  background-color: $darkRedColor;
}

.nav {
  height: 52px;
}

.row {
  position: relative;
}

.scrolled .nav {
  height: 68px;
  background-color: $mainRedColor;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100000;
}

.ul {
  width: 100%;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  vertical-align: middle;
  padding-left: 0;
  margin-bottom: 0;
}

.logoWrapper {
  height: 68px;
  display: flex;
  align-items: center;
}

.logo {
  height: 52px;
}
