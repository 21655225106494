@import '/src/variables.scss';

.wrapper {
  margin-bottom: 30px;
  position: relative;
}

.customSelect {
  width: 120px;
  height: 40px;
  margin: 0;
  padding: 0;
}
